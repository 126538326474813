import React, { useEffect, useRef, useState } from 'react';
import { useGetOne } from 'react-admin';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { PatientDetail } from '@boTypes/patient';
import { FilledFormViewer } from '@components/forms/filledFormViewer';
import { FeedingAndSleep } from '@components/tools/FeedingAndSleep';
import { useNavigateWithParams } from '@hooks/useNavigateWithParams';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Slide,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { Form, FormAnswers } from '@teammay/form-core';

import { DiscussionEditChat } from './discussionChat';
import { FamilyHeader } from './familyHeader';
import { HandoverList } from './handoverList';
import { PatientFile } from './patientFile';
import { ChatHeader } from './patientHeader';
import { SubjectActionButton } from './subjectActionButton';
import { getIdsInfoFromDiscussion } from './utils';
import { logDiscussionView } from '../../analytics/events';
import { DiscussionContext } from '../../common';
import { useDiscussionDetail } from '../../hooks/discussion';
import { DiscussionContextProvider } from '../../hooks/useDiscussionContext';
import { usePatientDetail } from '../../hooks/usePatientDetail';
import { setReduxSearch } from '../../store/librarySearch';
import { setMacroSuggestion } from '../../store/macroSuggestion';
import { COLORS } from '../../themes';
import { Discussion } from '../../types';
import { useGateway } from '../../utils/gateway';
import { AppUserWeightChart } from '../Charts/AppUserWeightChart';
import { ChildCharts } from '../Charts/ChildCharts';

const border = `1px solid ${COLORS.GREY_LAYOUT}`;

const RightPart = React.forwardRef<
  HTMLDivElement,
  {
    discussion: Discussion;
    setDisplaySlider?: (display: boolean) => void;
    onFormSelected;
  }
>(({ discussion, setDisplaySlider, onFormSelected }, ref) => {
  const familyId =
    discussion?.appUsers?.length &&
    discussion.appUsers[0] &&
    typeof discussion.appUsers[0] === 'object'
      ? discussion.appUsers[0].familyId
      : discussion?.appUser && typeof discussion.appUser === 'object'
        ? discussion.appUser?.familyId
        : undefined;

  const patientDetail = usePatientDetail(discussion);
  const [showWeightCurves, setShowWeightCurves] = useState<boolean>(false);
  const [showSleepData, setShowSleepData] = useState<boolean>(false);
  const contentRef = useRef<HTMLElement>(null);
  const { data: parentDetail } = useGetOne<PatientDetail>(
    'patients',
    {
      id: Number(patientDetail.data?.id),
    },
    {
      enabled: Boolean(
        patientDetail.type === 'adult' && patientDetail.data?.id,
      ),
    },
  );

  return (
    <Paper
      ref={ref}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: setDisplaySlider ? 'absolute' : undefined,
        width: setDisplaySlider ? '100%' : undefined,
        top: '0',
        zIndex: 20,
        borderRadius: 0,
      }}
      elevation={0}
    >
      {setDisplaySlider ? (
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <FamilyHeader
            familyId={familyId}
            callback={() => setDisplaySlider(false)}
          />
          <IconButton onClick={() => setDisplaySlider(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      ) : (
        <FamilyHeader
          familyId={familyId}
          excludeIds={{
            appUsers: [discussion?.appUser?.id],
            children: [discussion?.kidId],
          }}
        />
      )}
      <Box
        ref={contentRef}
        sx={{
          position: 'relative',
          height: 'calc(100% - 50px)',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <PatientFile
          sx={{
            minHeight: 'calc(min(180px,25%))', // 20% just to leave some space to handovers if reducing the height of the browser
            maxHeight: '40%',
            borderBottom: border,
          }}
          onChartPress={() => setShowWeightCurves(true)}
          onSleepPress={
            patientDetail.type === 'kid'
              ? () => setShowSleepData(true)
              : undefined
          }
          onFormSelected={onFormSelected}
        />
        <HandoverList sx={{ flex: 1 }} />
        {patientDetail.type === 'adult' && (
          <Slide
            style={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              zIndex: 3,
              top: 0,
            }}
            direction="left"
            in={showWeightCurves}
            mountOnEnter
            unmountOnExit
            container={contentRef.current}
          >
            <Paper sx={{ height: '100%', width: '100%' }} elevation={4}>
              <AppUserWeightChart
                id={patientDetail.data?.id}
                expectedEnd={parentDetail?.pregnancy?.expectedEnd ?? new Date()}
                onClose={() => setShowWeightCurves(false)}
              />
            </Paper>
          </Slide>
        )}
        {patientDetail.type === 'kid' && patientDetail.data && (
          <Slide
            style={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              zIndex: 3,
              top: 0,
            }}
            direction="left"
            in={showWeightCurves}
            mountOnEnter
            unmountOnExit
            container={contentRef.current}
          >
            <Paper sx={{ height: '100%', width: '100%' }} elevation={4}>
              <ChildCharts
                kid={patientDetail.data}
                onClose={() => setShowWeightCurves(false)}
              />
            </Paper>
          </Slide>
        )}
        {patientDetail.type === 'kid' && patientDetail.data && (
          <Slide
            style={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              zIndex: 3,
              top: 0,
            }}
            direction="left"
            in={showSleepData}
            mountOnEnter
            unmountOnExit
            container={contentRef.current}
          >
            <Paper sx={{ height: '100%', width: '100%' }} elevation={4}>
              <FeedingAndSleep
                child={patientDetail.data}
                onClose={() => setShowSleepData(false)}
              />
            </Paper>
          </Slide>
        )}
      </Box>
    </Paper>
  );
});

export const DiscussionDetail = () => {
  const socket = useGateway();
  const dispatch = useDispatch();
  const discussion = useDiscussionDetail();
  const bigScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));
  const [displaySlider, setDisplaySlider] = useState(false);

  const discussionContext = discussion?.kidId
    ? DiscussionContext.NURSE
    : DiscussionContext.MIDWIFE;

  const { familyId, childId, appUserId } = getIdsInfoFromDiscussion(discussion);

  useEffect(() => {
    // Reset search when changing discussion
    if (discussion?.id && discussion?.lastSubjectId) {
      logDiscussionView(discussion.id, discussion.lastSubjectId);
      dispatch(setReduxSearch({ search: '' }));
      dispatch(
        setMacroSuggestion({
          macroId: undefined,
          macroSuggestionId: undefined,
        }),
      );
    }
  }, [discussion?.id, dispatch, discussion?.lastSubjectId]);

  const navigate = useNavigateWithParams();
  const location = useLocation();
  const basePath = location.pathname.split('/')[1];

  const contentRef = useRef<HTMLDivElement>(null);
  const [displayedForm, setDisplayedForm] = useState<
    | {
        form: Pick<Form, 'id'>;
        formAnswers: Pick<FormAnswers, 'id'>;
      }
    | undefined
  >(undefined);
  const [viewableForm, setViewableForm] = useState(false);

  return (
    <Grid container sx={{ height: '100%', position: 'relative' }}>
      <Grid
        item
        ref={contentRef}
        xs={12}
        md={8}
        sx={{
          borderRight: `1px solid ${COLORS.GREY_LAYOUT}`,
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
        }}
      >
        <ChatHeader
          familyId={familyId}
          childId={childId}
          appUserId={appUserId}
          onSliderOpen={bigScreen ? undefined : () => setDisplaySlider(true)}
          backAction={() => navigate(`/${basePath}`)}
        />
        <DiscussionEditChat
          discussion={discussion}
          socket={socket}
          onFormPress={({ form, formAnswers }) => {
            setDisplayedForm({
              form,
              // trust me this cast is fine
              formAnswers: formAnswers as unknown as Pick<FormAnswers, 'id'>,
            });
            setViewableForm(true);
          }}
        />
        <DiscussionContextProvider discussionContext={discussionContext}>
          <SubjectActionButton />
        </DiscussionContextProvider>
      </Grid>
      {bigScreen ? (
        <>
          <Grid
            item
            xs={12}
            md={4}
            zeroMinWidth
            sx={{
              flexDirection: 'column',
              width: '100%',
              alignItems: 'stretch',
              height: '100%',
              display: 'flex',
            }}
          >
            <RightPart
              discussion={discussion}
              onFormSelected={({ form, formAnswers }) => {
                setDisplayedForm({
                  form,
                  formAnswers,
                });
                setViewableForm(true);
              }}
            />
          </Grid>
        </>
      ) : (
        <Slide
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
          }}
          direction="left"
          in={displaySlider}
          container={contentRef.current}
        >
          <RightPart
            discussion={discussion}
            setDisplaySlider={setDisplaySlider}
            onFormSelected={({ form, formAnswers }) => {
              setDisplayedForm({
                form,
                formAnswers,
              });
              setViewableForm(true);
            }}
          />
        </Slide>
      )}
      <Slide
        style={{
          height: '100%',
          width: '100%',
          position: 'absolute',
          zIndex: 20,
        }}
        direction="left"
        in={!!viewableForm}
        container={contentRef.current}
        unmountOnExit
        mountOnEnter
      >
        <Paper sx={{ height: '100%', width: '100%' }} elevation={4}>
          <FilledFormViewer
            onClose={() => setViewableForm(false)}
            {...displayedForm}
          />
        </Paper>
      </Slide>
    </Grid>
  );
};
