import {
  SimpleShowLayout,
  BooleanField,
  Labeled,
  RecordContextProvider,
  useTranslate,
} from 'react-admin';

import { Box } from '@mui/material';

import { HandoverList } from '../../entities/handovers/list';
import { SubjectExplorer } from '../../types/subject';
import { CategoriesChipField, RelatedDiscussionURLField } from '../fields';
import { FamilyHeader } from '../subjects/familyHeader';

export const SubjectSummary = ({ subject }: { subject?: SubjectExplorer }) => {
  const translate = useTranslate();

  return subject ? (
    <Box sx={{ height: 'calc(100vh - 98px)', overflowY: 'auto' }}>
      <FamilyHeader familyId={subject?.appUsers?.[0]?.familyId} />
      <SimpleShowLayout record={subject}>
        <Labeled label="Catégorie">
          <RecordContextProvider value={subject.category}>
            <CategoriesChipField />
          </RecordContextProvider>
        </Labeled>
        <BooleanField
          source="withDoctorIntervention"
          label="👩🏻‍⚕️ Avis médical demandé ?"
        />
        <BooleanField
          source="withNurseIntervention"
          label="Intervention IPDE ?"
        />
        <BooleanField source="isPriority" label="Prioritaire ?" />
      </SimpleShowLayout>
      <HandoverList subjectId={subject.id} />
      <RelatedDiscussionURLField
        discussion={subject.discussion}
        label={translate('subjectExplorer.goToDiscussion')}
        midwife={Boolean(subject.discussion?.appUser?.id)}
        sx={{ m: 1 }}
      />
    </Box>
  ) : null;
};
