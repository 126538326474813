import { AxiosRequestConfig, AxiosResponse } from 'axios';

import {
  useInfiniteQuery as useInfiniteQueryRaw,
  useQuery as useQueryRaw,
  useMutation as useMutationRaw,
  useQueryClient as useQueryClientRaw,
  QueryKey as QueryKeyRaw,
  MutationKey,
  QueryFunctionContext,
  UseQueryOptions,
  UseMutationOptions,
  UseInfiniteQueryOptions,
  InfiniteData,
  Updater,
} from '@tanstack/react-query';

import {
  apiRequest,
  cmsRequest,
  fullApiRequest,
  s3Request,
  searchRequest,
} from '../utils/httpClients';

export type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
} from '@tanstack/react-query';

export { QueryClient } from '@tanstack/react-query';

type AxiosRequestClient<TData> = (arg: AxiosRequestConfig) => Promise<TData>;

export type QueryKey = QueryKeyRaw;

type RequestGenerator<
  TQueryKey extends QueryKey,
  RequestClient extends AxiosRequestClient<any>,
> =
  | ((context: QueryFunctionContext<TQueryKey>) => Parameters<RequestClient>[0])
  | Parameters<RequestClient>[0];

type MutationRequestGenerator<
  RequestClient extends AxiosRequestClient<any>,
  TVariables = any,
> =
  | ((arg: TVariables) => Parameters<RequestClient>[0])
  | Parameters<RequestClient>[0];

export const useMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  key: MutationKey,
  func: MutationRequestGenerator<typeof apiRequest<TData>, TVariables>,
  options?: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'queryKey' | 'queryFn'
  >,
): ReturnType<typeof useMutationRaw<TData, TError, TVariables, TContext>> => {
  return useMutationRaw({
    mutationKey: key,
    mutationFn: (data: TVariables) =>
      apiRequest(typeof func === 'function' ? func(data) : { data, ...func }),
    ...options,
  });
};

export const useQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  key: TQueryKey,
  func: RequestGenerator<
    TQueryKey,
    (arg: AxiosRequestConfig) => Promise<TQueryFnData>
  >,
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn'
  >,
  requestClient: (
    arg: AxiosRequestConfig,
  ) => Promise<TQueryFnData> = apiRequest,
) => {
  return useQueryRaw({
    queryKey: key,
    queryFn: (context) =>
      requestClient(typeof func === 'function' ? func(context) : func),
    ...options,
  });
};

export const useCMSQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  key: TQueryKey,
  func: Parameters<typeof useQuery>[1],
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery<TQueryFnData, TError, TData, TQueryKey>(
    key,
    func,
    options,
    cmsRequest,
  );

export const useCMSMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  key: MutationKey,
  func: MutationRequestGenerator<typeof cmsRequest<TData>, TVariables>,
  options?: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): ReturnType<typeof useMutationRaw<TData, TError, TVariables, TContext>> => {
  return useMutationRaw({
    mutationKey: key,
    mutationFn: (arg: TVariables) =>
      cmsRequest(typeof func === 'function' ? func(arg) : func),
    ...options,
  });
};

export const useQueryClient = useQueryClientRaw;

export const useQueryWithUpdater = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn:
    | ((
        context: QueryFunctionContext<TQueryKey>,
      ) => Parameters<typeof apiRequest<TData>>[0])
    | Parameters<typeof apiRequest<TData>>[0],
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn'
  >,
) => {
  const queryClient = useQueryClient();
  const setCache = (data: Updater<TQueryFnData | undefined, TQueryFnData>) =>
    queryClient.setQueryData<TQueryFnData>(queryKey, data);

  const queryRes = useQuery<TQueryFnData, TError, TData, TQueryKey>(
    queryKey,
    queryFn,
    options,
  );
  return { ...queryRes, setCache };
};

export const useSearchQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  key: TQueryKey,
  func: Parameters<typeof useQuery>[1],
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery<TQueryFnData, TError, TData, TQueryKey>(
    key,
    func,
    options,
    searchRequest,
  );

export const useInfiniteSearchQuery = <
  TQueryFnData = unknown,
  TError = any,
  TData = InfiniteData<TQueryFnData>,
  TQueryKey extends QueryKey = QueryKey,
  TPageParam = unknown,
>(
  options: Omit<
    UseInfiniteQueryOptions<
      TQueryFnData,
      TError,
      TData,
      TQueryFnData,
      TQueryKey,
      TPageParam
    >,
    'queryFn'
  > & {
    queryFn: (
      context: QueryFunctionContext<TQueryKey, TPageParam>,
    ) => AxiosRequestConfig;
  },
) => {
  return useInfiniteQueryRaw<
    TQueryFnData,
    TError,
    TData,
    TQueryKey,
    TPageParam
  >({
    ...options,
    queryFn: (context) =>
      searchRequest(
        typeof options.queryFn === 'function'
          ? options.queryFn(context)
          : options.queryFn,
      ),
  });
};

export const useInfiniteQuery = <
  TQueryFnData = unknown,
  TError = any,
  TData = InfiniteData<AxiosResponse<TQueryFnData>>,
  TQueryKey extends QueryKey = QueryKey,
  TPageParam = unknown,
>(
  options: Omit<
    UseInfiniteQueryOptions<
      AxiosResponse<TQueryFnData>,
      TError,
      TData,
      AxiosResponse<TQueryFnData>,
      TQueryKey,
      TPageParam
    >,
    'queryFn'
  > & {
    queryFn: (
      context: QueryFunctionContext<TQueryKey, TPageParam>,
    ) => AxiosRequestConfig;
  },
) => {
  return useInfiniteQueryRaw<
    AxiosResponse<TQueryFnData>,
    TError,
    TData,
    TQueryKey,
    TPageParam
  >({
    ...options,
    queryFn: (context) => fullApiRequest(options.queryFn(context)),
  });
};

export const useS3Mutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  key: MutationKey,
  func: MutationRequestGenerator<typeof s3Request<TData>, TVariables>,
  options: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'queryKey' | 'queryFn'
  >,
): ReturnType<typeof useMutationRaw<TData, TError, TVariables, TContext>> => {
  return useMutationRaw({
    mutationKey: key,
    mutationFn: (data: TVariables) =>
      s3Request(typeof func === 'function' ? func(data) : { data, ...func }),
    ...options,
  });
};
