import { useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { StyleSheet, View } from 'react-native';

import {
  Box,
  Button,
  ButtonGroup,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import {
  Form,
  getScoringNumber,
  getScoringTags,
  Question,
  StepScreen,
  Template,
} from '@teammay/form-core';
import {
  FormMobileProvider,
  FormRunner,
  StepScreenRenderer,
} from '@teammay/form-mobile';

import { PrimaryButton } from './mobile-components/buttons';
import { BackButton } from './mobile-components/buttons/BackButton';
import { PressableNative } from './mobile-components/buttons/PressableNative';
import { COLORS, MobileColors } from '../../../themes';

const rnStyles = StyleSheet.create({
  mobileContainer: {
    width: 300,
    height: 600,
    borderRadius: 50,
    borderColor: 'black',
    borderWidth: 10,
    borderStyle: 'solid',
    overflow: 'hidden',
    alignSelf: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
});

const styles = {
  buttonGroup: {
    color: COLORS.GREY_TEXT,
    borderColor: COLORS.GREY_LAYOUT,
  },
  active: {
    color: COLORS.GREEN['500'],
    backgroundColor: COLORS.GREEN['100'],
  },
};

const FallBackComponent = (props: FallbackProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        alignItems: 'center',
      }}
    >
      <Typography
        variant="h5"
        color="error"
      >{`Impossible to run formRunner`}</Typography>
      <Typography color="error">{`${props.error}`}</Typography>
      <Button onClick={props.resetErrorBoundary} variant="contained">
        Try again
      </Button>
    </Box>
  );
};

export const MobileView = ({
  sx,
  selected,
  form,
  templates,
  questions,
}: {
  sx: SxProps<Theme>;
  selected: StepScreen | null;
  form: Form;
  templates: Template[];
  questions: Question[];
}) => {
  const [selectedMode, setSelectedMode] = useState<'viewer' | 'runner'>(
    'viewer',
  );
  const notify = useNotify();
  const translate = useTranslate();
  const [hideBack, setHideBack] = useState(false);

  return (
    <Box sx={sx}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ErrorBoundary FallbackComponent={FallBackComponent}>
          <FormMobileProvider
            theme={{
              title: {
                fontSize: 17,
                fontFamily: 'Poppins-Semibold',
                color: MobileColors.duck800,
                textAlign: 'center',
                marginTop: 12,
                marginBottom: 12,
              },
              text: {
                fontSize: 14,
                fontFamily: 'Poppins-Regular',
                color: MobileColors.duck800,
                textAlign: 'center',
              },
            }}
            components={{
              BackButton,
              PrimaryButton,
              Button: PressableNative,
            }}
          >
            <View style={rnStyles.mobileContainer}>
              {selectedMode === 'viewer' && (
                <StepScreenRenderer
                  stepScreen={selected}
                  submit={(answer) => {
                    notify(
                      `${translate('forms.runner.submitClicked')}: ${answer}`,
                      { type: 'success' },
                    );
                  }}
                />
              )}
              {selectedMode === 'runner' && (
                <FormRunner
                  form={form}
                  templates={templates}
                  questions={questions}
                  totalQuestions={form.questionCount ?? 0}
                  onFormFinished={(answers) => {
                    const score = getScoringNumber(answers, questions);
                    const tags = getScoringTags(answers, questions);
                    notify('forms.runner.completedForm', {
                      type: 'success',
                      messageArgs: { score, tags },
                    });
                  }}
                  onCancelForm={() => {
                    notify('forms.runner.canceledForm', {
                      type: 'warning',
                    });
                  }}
                  hideBack={hideBack}
                />
              )}
            </View>
          </FormMobileProvider>
        </ErrorBoundary>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          py: 2,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            value={form?.questionCount ?? 0}
            label={translate('forms.runner.questionCount')}
            variant="standard"
            disabled={true}
          />
          <Button
            style={{
              ...styles.buttonGroup,
              ...(hideBack ? {} : styles.active),
            }}
            onClick={() => setHideBack((prev) => !prev)}
          >
            {hideBack ? 'cannot back' : 'can back'}
          </Button>
        </Box>
        <ButtonGroup variant="outlined" aria-label="Basic button group">
          <Button
            style={{
              ...styles.buttonGroup,
              ...(selectedMode === 'viewer' ? styles.active : {}),
            }}
            onClick={() => setSelectedMode('viewer')}
          >
            Viewer
          </Button>
          <Button
            style={{
              ...styles.buttonGroup,
              ...(selectedMode === 'runner' ? styles.active : {}),
            }}
            onClick={() => setSelectedMode('runner')}
          >
            Runner
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};
