import { useState } from 'react';
import { useInput } from 'react-admin';
import ReactMde from 'react-mde';
import * as Showdown from 'showdown';

import { FormControl, InputLabel } from '@mui/material';

import 'react-mde/lib/styles/css/react-mde-all.css';

export const MarkdownInput = ({
  source,
  label,
}: {
  source: string;
  label: string;
}) => {
  const { field } = useInput({ source });
  const [tab, setTab] = useState<'write' | 'preview'>('write');

  const converter = new Showdown.Converter();

  const handleTabChange = (newTab: 'write' | 'preview') => {
    setTab(newTab);
  };

  return (
    <>
      <InputLabel>{label}</InputLabel>
      <FormControl fullWidth className="ra-input-mde">
        <ReactMde
          onChange={(e) => field.onChange(e)}
          onTabChange={handleTabChange}
          value={field.value ? field.value.toString() : ''}
          generateMarkdownPreview={(markdown) =>
            Promise.resolve(converter.makeHtml(markdown))
          }
          selectedTab={tab}
        />
      </FormControl>
    </>
  );
};
