import mean from 'lodash/mean';
import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';
import { useTranslate } from 'react-admin';

import { Box, Typography } from '@mui/material';
import { dayjsTz } from '@utils/date';

import { CalendarAggregatedDayData } from '../WeekCalendar/types';

const useStatistics = (data: CalendarAggregatedDayData) => {
  return useMemo(() => {
    const ordered = orderBy(Object.entries(data), ([day, _]) => day, 'desc');
    const _sleepData = ordered.flatMap(([day, { sleep }]) => {
      if (!sleep) {
        return [];
      }
      return [
        {
          day: dayjsTz(day).format('DD MMM'),
          sleep: Math.round(sleep),
        },
      ];
    });

    const _feedingData = ordered.flatMap(([day, { bottle, breast }]) => {
      if (!bottle && !breast) {
        return [];
      }
      return [
        {
          day: dayjsTz(day).format('DD MMM'),
          bottle: bottle > 0 ? bottle : undefined,
          breast: breast > 0 ? Math.round(breast) : undefined,
        },
      ];
    });

    const _averagePerDay = {
      sleep: mean(
        Object.values(data)
          .map((d) => d.sleep)
          .filter(Boolean),
      ),
      bottle: mean(
        Object.values(data)
          .map((d) => d.bottle)
          .filter(Boolean),
      ),
      breast: mean(
        Object.values(data)
          .map((d) => d.breast)
          .filter(Boolean),
      ),
    };

    return {
      sleepData: _sleepData,
      feedingData: _feedingData,
      averagePerDay: _averagePerDay,
    };
  }, [data]);
};

export const Statistics = ({ data }: { data: CalendarAggregatedDayData }) => {
  const translate = useTranslate();
  const { sleepData, feedingData, averagePerDay } = useStatistics(data);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: 2,
      }}
    >
      <Typography variant="h6" color="textPrimary">
        {translate('tools.feedingAndSleep.statistics')}
      </Typography>
      <Box>
        <Typography variant="caption" color="textPrimary">
          {translate('common.averageLast7Days')}
        </Typography>
        <Typography variant="body2" color="textPrimary">
          😴 : {averagePerDay.sleep ? Math.round(averagePerDay.sleep) : 'N/A'}{' '}
          min
          <br />
          🍼 : {averagePerDay.bottle
            ? Math.round(averagePerDay.bottle)
            : 'N/A'}{' '}
          ml
          <br />
          💧 : {averagePerDay.breast
            ? Math.round(averagePerDay.breast)
            : 'N/A'}{' '}
          min
        </Typography>
      </Box>
      {(sleepData.length > 0 || feedingData.length > 0) && (
        <Box sx={{ mt: 1 }}>
          <Typography variant="caption" color="textPrimary">
            {translate('common.totalPerDay')}
          </Typography>
          <Box>
            {sleepData.length > 0 && (
              <>
                <Typography variant="body2" color="primary">
                  😴 {translate('common.sleep')}
                </Typography>
                {sleepData.map(({ day, sleep }) => {
                  return (
                    <Box
                      key={`sleep-${day}`}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="caption" sx={{ mr: 1 }}>
                        {day}
                      </Typography>
                      <Typography variant="body2">{sleep} min</Typography>
                    </Box>
                  );
                })}
              </>
            )}
            {feedingData.length > 0 && (
              <>
                <Typography variant="body2" color="primary">
                  🍼💧 {translate('common.feeding')}
                </Typography>
                {feedingData.map(({ day, bottle, breast }) => {
                  return (
                    <Box
                      key={`feeding-${day}`}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="caption" sx={{ mr: 1 }}>
                        {day}
                      </Typography>
                      <Typography variant="body2">
                        {bottle > 0 && `🍼 ${bottle} ml`}
                        {bottle > 0 && breast > 0 && ' '}
                        {breast > 0 && `💧 ${breast} min`}
                      </Typography>
                    </Box>
                  );
                })}
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
