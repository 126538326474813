import { BooleanField, Datagrid, SearchInput, TextField } from 'react-admin';

import { CompanyPlanField } from '../../components/fields';
import { List } from '../../components/generic/List';

const CompanyFilters = [<SearchInput source="q" alwaysOn variant="standard" />];

export const CompanyList = (props) => (
  <List {...props} title="Entreprises" filters={CompanyFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="name" label="Nom" />
      <BooleanField source="active" label="Active" />
      <CompanyPlanField label="Plans actifs" />
    </Datagrid>
  </List>
);
