import { useMemo, useState } from 'react';
import { useTranslate } from 'react-admin';
import { useForm } from 'react-hook-form';

import { PlanningPreferences } from '@boTypes/planning';
import { ConfirmDialog } from '@components/ConfirmDialog/ConfirmDialog';
import { CheckboxInput } from '@components/forms/checkBoxInput';
import { TextInput } from '@components/forms/textInput';
import { Button } from '@components/generic/Button';
import { Title } from '@components/title';
import { Box } from '@mui/material';

import { logAvailabilityPreferencesSaved } from '../../../analytics/events';
import { useSetPlanningPreferences } from '../../../hooks/plannings';
import { usePrompt } from '../../../hooks/usePrompt';

export const PreferencesInput = ({
  planningId,
  preferences,
  onClose,
}: {
  planningId: string;
  preferences?: PlanningPreferences;
  onClose: () => void;
}) => {
  const translate = useTranslate();
  const { handleSubmit, control, formState, getValues } =
    useForm<PlanningPreferences>({
      defaultValues: preferences ?? {
        maximumPerPlanning: 30,
        minimumSlotsWanted: 0,
        workWholeWeekend: true,
      },
    });
  const { isDirty, isSubmitting } = formState;
  const [open, setOpen] = useState(false);

  const { mutateAsync: savePreferences } =
    useSetPlanningPreferences(planningId);

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (data) => {
        try {
          await savePreferences(
            Object.entries(data).reduce(
              (acc, [key, value]) => ({
                ...acc,
                [key]:
                  key === 'workWholeWeekend' ? Boolean(value) : Number(value),
              }),
              {} as PlanningPreferences,
            ),
          );
          logAvailabilityPreferencesSaved();
          onClose();
        } catch (_e) {}
      }),
    [handleSubmit, onClose, savePreferences],
  );

  usePrompt(translate('planning.preferences.unsaved'), isDirty);

  const protectedOnClose = () => {
    if (isDirty) {
      setOpen(true);
      return;
    }
    onClose();
  };

  return (
    <form onSubmit={onSubmit}>
      <ConfirmDialog
        title={translate('planning.preferences.confirmClose')}
        text={translate('planning.preferences.confirmCloseText')}
        onClick={onClose}
        onClose={() => setOpen(false)}
        open={open}
      />
      <Title
        title={translate('planning.preferences.title')}
        onClose={protectedOnClose}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          p: '1rem',
        }}
      >
        <TextInput
          control={control}
          label={translate('planning.preferences.maximumPerPlanning')}
          name="maximumPerPlanning"
          sx={{ minWidth: 250 }}
          rules={{ required: true, min: 1, max: 2000 }}
          type="number"
        />
        <TextInput
          control={control}
          label={translate('planning.preferences.maximumSlotsOnWeekends')}
          name="maximumSlotsOnWeekends"
          sx={{ minWidth: 250 }}
          rules={{ required: true, min: 1, max: 2000 }}
          type="number"
        />
        <TextInput
          control={control}
          label={translate('planning.preferences.maximumPerWeek')}
          name="maximumPerWeek"
          sx={{ minWidth: 250 }}
          rules={{ required: true, min: 1, max: 2000 }}
          type="number"
        />
        <TextInput
          control={control}
          label={translate('planning.preferences.maximumNightsPerWeek')}
          name="maximumNightsPerWeek"
          sx={{ minWidth: 250 }}
          rules={{ required: true, min: 1, max: 2000 }}
          type="number"
        />
        <TextInput
          control={control}
          label={translate('planning.preferences.minimumSlotsWanted')}
          name="minimumSlotsWanted"
          sx={{ minWidth: 250 }}
          rules={{
            required: true,
            min: 0,
            max: 2000,
            validate: (value) => {
              return (
                Number(value) <= Number(getValues('maximumPerPlanning')) ||
                translate('planning.preferences.minimumSlotsWantedError')
              );
            },
          }}
          type="number"
        />
        <CheckboxInput
          control={control}
          label={translate('planning.preferences.workWholeWeekend')}
          name="workWholeWeekend"
        />
        <Button
          sx={{ width: '100%', marginTop: '1.5rem' }}
          variant="contained"
          type="submit"
          color="primary"
          loading={isSubmitting}
          value={translate('planning.preferences.save')}
          fullWidth
        />
      </Box>
    </form>
  );
};
