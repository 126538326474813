import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const reviveSummarySlice = createSlice({
  name: 'reviveSummary',
  initialState: { displayReviveSummary: false },
  reducers: {
    setDisplayReviveSummary(
      state,
      { payload: displayReviveSummary }: PayloadAction<boolean>,
    ) {
      return { displayReviveSummary };
    },
  },
});

export const { setDisplayReviveSummary } = reviveSummarySlice.actions;

export default reviveSummarySlice.reducer;
