import { useMemo } from 'react';
import {
  MenuItemLink,
  MenuProps,
  ResourceDefinition,
  useResourceDefinitions,
  useSidebarState,
  useUserMenu,
} from 'react-admin';
import { useLocation } from 'react-router-dom';

import { Help } from '@mui/icons-material';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { logMenuSelect } from '../analytics/events';
import { LogoutButton } from '../auth';
import { customResources } from '../resources';
import { useSelector } from '../store';
import { COLORS } from '../themes';
import { menuFilterFunction } from '../utils/accessControl';

const MyMenu = ({ dense = false }: MenuProps) => {
  const email = useSelector((state) => state.user.email);
  const roles = useSelector((state) => state.user.roles);

  const resourcesDefinitions = useResourceDefinitions();

  const [open] = useSidebarState();
  const { onClose } = useUserMenu() ?? { onClose: () => null };
  const location = useLocation();

  const filteredResources = useMemo(() => {
    return customResources
      .slice(0, 2)
      .concat(Object.values<ResourceDefinition>(resourcesDefinitions))
      .concat(customResources.slice(2))
      .filter(menuFilterFunction(roles, email));
  }, [email, resourcesDefinitions, roles]);

  const globTheme = useTheme();
  const isSmall = useMediaQuery(globTheme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: open ? 200 : 50,
        transition: (theme) =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      {filteredResources.map(({ icon: Icon, name, options }) => (
        // @ts-ignore
        <MenuItemLink
          key={name}
          to={`/${name}${
            options?.urlParams
              ? `?${Object.keys(options.urlParams)
                  .map(
                    (paramKey) =>
                      `${paramKey}=${encodeURIComponent(
                        options.urlParams[paramKey],
                      )}`,
                  )
                  .join('&')}`
              : ''
          }`}
          sx={{
            ...(location.pathname.split('/')[1] === name && {
              backgroundColor: COLORS.GREEN['300'],
            }),
            // Strange as it seems but icon svg are 24x24, Sidebar is 50px wide
            paddingLeft: '13px',
            paddingRight: '13px',
          }}
          primaryText={(options && options.label) || name}
          leftIcon={<Icon />}
          onClick={() => {
            logMenuSelect(name);
            return onClose();
          }}
          dense={dense}
        />
      ))}
      {/* @ts-ignore */}
      <MenuItemLink
        to="/help"
        primaryText="Aide"
        leftIcon={<Help />}
        dense={dense}
        sx={{
          paddingLeft: '13px',
          paddingRight: '13px',
        }}
      />
      {isSmall && <LogoutButton />}
    </Box>
  );
};

export default MyMenu;
