import { useTranslate } from 'react-admin';

import { DiscussionEventType } from '@boTypes/discussionEvent';
import { Typography } from '@mui/material';

import { MasterclassItem, HEIGHT, WIDTH } from './MasterclassItem';
import { Masterclass } from '../../types';
import { FullWidthList, useWrappedItemWithSend } from '../fullWidthList';

export const MasterclassList = ({
  list = [],
  onSend,
}: {
  list: Masterclass[];
  onSend?: (
    { type, content }: { type: DiscussionEventType; content: string },
    contentType: string,
    contentId: number,
  ) => void;
}) => {
  const translate = useTranslate();

  const Item = useWrappedItemWithSend(MasterclassItem, onSend);

  if (!list || list.length === 0) {
    return null;
  }

  return (
    <>
      <Typography variant="subtitle2" color="primary">
        {translate('library.masterclasses')}
      </Typography>
      <FullWidthList
        itemCount={list.length}
        itemSize={WIDTH + 16}
        height={HEIGHT + 20 + (onSend ? 18 : 0)}
        layout="horizontal"
        itemData={list}
      >
        {Item}
      </FullWidthList>
    </>
  );
};
