import {
  Identifier,
  usePermissions,
  useRecordContext,
  useTranslate,
} from 'react-admin';

import { Roles } from '@boTypes/user';
import { DialogButton } from '@components/DialogButton/DialogButton';
import { useMutation, useQuery, useQueryClient } from '@hooks/queryWrappers';
import DeleteIcon from '@mui/icons-material/Delete';
import { CircularProgress, Typography } from '@mui/material';
import { addDays, formatTZ } from '@utils/date';

import { COLORS } from '../../../themes';

export interface AppUserDeletion {
  id: string;
  appUserId: number;
  after: Date;
  completedAt: Date | null;
  paymentGatewayDeleted: Date | null;
  marketingAutomationDeleted: Date | null;
  usageTrackerDeleted: Date | null;
  usageTrackerDeletionToken: Date | null;
  discussionDeleted: Date | null;
  journalingDeleted: Date | null;
  marketingDeleted: Date | null;
  familyDeleted: Date | null;
  appUserDeleted: Date | null;
}

export interface AppUserDeletionRaw {
  id: string;
  appUserId: number;
  after: string;
  completedAt: string | null;
  paymentGatewayDeleted: string | null;
  marketingAutomationDeleted: string | null;
  usageTrackerDeleted: string | null;
  usageTrackerDeletionToken: string | null;
  discussionDeleted: string | null;
  journalingDeleted: string | null;
  marketingDeleted: string | null;
  familyDeleted: string | null;
  appUserDeleted: string | null;
}

const useActiveDeletionQuery = (id: Identifier) => {
  return useQuery<AppUserDeletionRaw | {}, any, AppUserDeletion>(
    ['appUser', id, 'deletion'],
    {
      method: 'GET',
      url: `/api/patients/${id}/gdpr`,
    },
    {
      select: (rawData) => {
        if (!('id' in rawData)) {
          return undefined;
        }
        const data = rawData as AppUserDeletionRaw;
        return {
          ...data,
          after: new Date(data.after),
          completedAt: data.completedAt ? new Date(data.completedAt) : null,
          paymentGatewayDeleted: data.paymentGatewayDeleted
            ? new Date(data.paymentGatewayDeleted)
            : null,
          marketingAutomationDeleted: data.marketingAutomationDeleted
            ? new Date(data.marketingAutomationDeleted)
            : null,
          usageTrackerDeleted: data.usageTrackerDeleted
            ? new Date(data.usageTrackerDeleted)
            : null,
          usageTrackerDeletionToken: data.usageTrackerDeletionToken
            ? new Date(data.usageTrackerDeletionToken)
            : null,
          discussionDeleted: data.discussionDeleted
            ? new Date(data.discussionDeleted)
            : null,
          journalingDeleted: data.journalingDeleted
            ? new Date(data.journalingDeleted)
            : null,
          marketingDeleted: data.marketingDeleted
            ? new Date(data.marketingDeleted)
            : null,
          familyDeleted: data.familyDeleted
            ? new Date(data.familyDeleted)
            : null,
          appUserDeleted: data.appUserDeleted
            ? new Date(data.appUserDeleted)
            : null,
        };
      },
    },
  );
};

const useRequestDeletionMutation = (id: Identifier) => {
  const queryClient = useQueryClient();

  return useMutation<AppUserDeletionRaw, any, void>(
    ['appUser', id, 'deletion'],
    {
      method: 'DELETE',
      url: `/api/patients/${id}/gdpr`,
    },
    {
      onSuccess(data) {
        queryClient.setQueryData<AppUserDeletionRaw>(
          ['appUser', id, 'deletion'],
          data,
        );
      },
    },
  );
};

const InnerForm = ({ id }: { id: Identifier }) => {
  const { data: appUserDeletion } = useActiveDeletionQuery(id);
  const { mutate, isPending } = useRequestDeletionMutation(id);
  const translate = useTranslate();

  if (appUserDeletion) {
    return (
      <>
        <Typography fontSize={'0.75rem'} color={COLORS.GREY_TEXT}>
          {translate('appUsers.deletionDate')}
          {formatTZ(
            addDays(appUserDeletion.after, 1),
            'DD MMM YYYY',
            undefined,
          )}
        </Typography>
        {!!appUserDeletion.completedAt && (
          <Typography fontSize={'0.75rem'}>
            {translate('appUsers.deletionCompletedAt')}
            {formatTZ(appUserDeletion.completedAt, 'DD MMM YYYY HH:mm')}
          </Typography>
        )}
      </>
    );
  }

  return (
    <>
      <DialogButton
        disabled={isPending}
        startIcon={isPending ? <CircularProgress /> : <DeleteIcon />}
        onClick={() => mutate()}
        text={translate('appUsers.deletionConfirmation')}
        color="error"
      >
        {translate('appUsers.requestDeletion')}
      </DialogButton>
    </>
  );
};

export const DeletionForm = () => {
  const { permissions } = usePermissions();
  const isAdmin = permissions?.includes(Roles.ADMIN);
  const record = useRecordContext();
  if (!isAdmin || !record) {
    return null;
  }

  return <InnerForm id={record.id} />;
};
