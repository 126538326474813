import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';

import { DiscussionEventType } from '@boTypes/discussionEvent';
import { HandoverReviveCreate } from '@boTypes/handover';
import { Button as CustomButton } from '@components/generic/Button';
import { usePostAndCloseRevive } from '@hooks/discussionEvents';
import {
  useCloseHandoverRevive,
  useCreateHandoverRevive,
  useUpdateHandoverRevive,
} from '@hooks/handover';
import { useGetVaryingMany } from '@hooks/useGetVaryingMany';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import EditIcon from '@mui/icons-material/Edit';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import { Typography, IconButton, Tooltip } from '@mui/material';
import { Box } from '@mui/system';

import { getReviveColor } from './handover.utils';
import { StaffUserSignature } from './staffUserSignature';
import { LastMaySender } from './subjectCard';
import {
  logAddedReviveToHandover,
  logClosedRevive,
  logHandoverEditButton,
  logUpdatedReviveToHandover,
} from '../../analytics/events';
import { COLORS } from '../../themes';
import { Handover, User } from '../../types';
import { DialogButton } from '../DialogButton/DialogButton';
import { ReviveDialog } from '../discussions/reviveDialog';
import { ShyText } from '../fields';

const border = `1px solid ${COLORS.GREY_LAYOUT}`;

export const HandoverCard = ({
  handover,
  canEdit = false,
  onEditClick = () => {},
  onCardClick = () => {},
}: {
  handover: Handover;
  canEdit?: boolean;
  onEditClick?: (handover: Handover) => void;
  onCardClick?: () => void;
}) => {
  // Here we only retrieve data fetched in handoverList component hence the "enabled: false"
  const { data } = useGetVaryingMany('users', [handover.userId], {
    enabled: false,
  });

  const canCreateRevive =
    handover.createdAt && !handover.revive
      ? new Date(handover.createdAt).getTime() > Date.now() - 24 * 3600 * 1000 // can revive only in the day where the handover was created
      : false;

  const { mutateAsync: createRevive } = useCreateHandoverRevive();
  const { mutateAsync: updateRevive } = useUpdateHandoverRevive(
    handover?.revive?.id,
  );
  const { mutateAsync: closeRevive } = useCloseHandoverRevive();
  const [openRevive, setOpenReviveDialog] = useState(false);
  const notify = useNotify();
  const postAndCloseRevive = usePostAndCloseRevive();

  const handleSubmitRevive = useCallback(
    async (args: Omit<HandoverReviveCreate, 'handoverId'>) => {
      const mutation = handover.revive ? updateRevive : createRevive;
      try {
        await mutation({ handoverId: Number(handover.id), ...args });
        handover.revive
          ? logUpdatedReviveToHandover(handover.subject?.id)
          : logAddedReviveToHandover(handover.subject?.id);
        setOpenReviveDialog(false);
        notify('revives.successCreate', { type: 'success' });
      } catch {
        notify('revives.errorCreate', { type: 'error' });
      }
    },
    [
      createRevive,
      handover.id,
      handover.revive,
      handover.subject?.id,
      notify,
      updateRevive,
    ],
  );

  const handleEdit = useCallback(
    (ev: React.MouseEvent) => {
      logHandoverEditButton(handover.subject?.id);
      onEditClick(handover);
      ev.stopPropagation();
    },
    [onEditClick, handover],
  );

  const handleEditRevive = useCallback(
    (ev: React.MouseEvent) => {
      setOpenReviveDialog(true);
      ev.stopPropagation();
    },
    [setOpenReviveDialog],
  );

  const translate = useTranslate();

  return (
    <Box
      sx={{
        marginBottom: '0.25rem',
        padding: '0.25rem 0.5rem 0 0.5rem',
        border,
        borderRadius: 2,
        position: 'relative',
      }}
      onClick={onCardClick}
    >
      <ShyText record={handover} oneline source="problem" label="" />
      <ShyText
        component="pre"
        record={handover}
        oneline
        source="observations"
        label={translate('handover.observations')}
      />
      <ShyText
        component="pre"
        record={handover}
        oneline
        source="advices"
        label={translate('handover.advice')}
      />
      {handover.revive && (
        <>
          <Box sx={{ display: 'flex' }}>
            <Typography variant="caption" component="span" color="primary">
              {handover.revive.performedAt
                ? translate('revives.performedAt')
                : translate('revives.performAt')}
            </Typography>
            <Typography
              variant="caption"
              sx={{ fontSize: '0.75rem', marginLeft: '0.25rem' }}
              color={getReviveColor(handover.revive)}
            >
              {dayjs(
                handover.revive.performedAt || handover.revive.reviveAt,
              ).format('DD/MM/YYYY HH:mm')}
            </Typography>
            {handover.revive.attributedStaffUserId && (
              <>
                <Typography
                  variant="caption"
                  component="span"
                  color="primary"
                  sx={{ paddingLeft: 0.5, paddingRight: 0.5 }}
                >
                  {translate('revives.by')}
                </Typography>
                <LastMaySender userId={handover.revive.attributedStaffUserId} />
              </>
            )}
          </Box>
          <ShyText
            component="pre"
            record={handover.revive}
            oneline
            source="plannedText"
            label={translate('revives.plannedText')}
          />
        </>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: canEdit ? 'space-between' : 'flex-end',
          alignItems: 'center',
          marginBottom: '0.25rem',
        }}
      >
        <Box sx={{ display: 'flex' }}>
          {canEdit && (
            <Tooltip title={translate('handover.editTooltip')} placement="top">
              <IconButton size="small" onClick={handleEdit}>
                <EditIcon
                  color="primary"
                  fontSize="small"
                  sx={{ fontSize: '1rem' }}
                />
              </IconButton>
            </Tooltip>
          )}
          {canCreateRevive && (
            <Tooltip title={translate('revives.createOne')} placement="top">
              <IconButton
                onClick={(ev) => {
                  setOpenReviveDialog(true);
                  ev.stopPropagation();
                }}
                size="small"
              >
                <AccessAlarmIcon
                  color="primary"
                  fontSize="small"
                  sx={{ fontSize: '1rem' }}
                />
              </IconButton>
            </Tooltip>
          )}
          {canEdit &&
            !canCreateRevive &&
            Boolean(handover.revive?.id && !handover.revive.performedAt) && (
              <Tooltip title={translate('revives.close')} placement="top">
                <div>
                  <DialogButton
                    onClick={() =>
                      closeRevive(handover.revive?.id, {
                        onSuccess: (_, reviveId) => {
                          logClosedRevive(reviveId);
                        },
                      })
                    }
                    size="small"
                    title={translate('revives.close')}
                    text={translate('revives.closeQuestion')}
                    component={IconButton}
                  >
                    <AlarmOffIcon
                      color="primary"
                      fontSize="small"
                      sx={{ fontSize: '1rem' }}
                    />
                  </DialogButton>
                </div>
              </Tooltip>
            )}
          {canEdit &&
            !canCreateRevive &&
            Boolean(handover.revive?.id && !handover.revive.performedAt) && (
              <Tooltip title={translate('revives.edit')} placement="top">
                <IconButton onClick={handleEditRevive} size="small">
                  <EditNotificationsIcon
                    color="primary"
                    fontSize="small"
                    sx={{ fontSize: '1rem' }}
                  />
                </IconButton>
              </Tooltip>
            )}
          {canEdit &&
            Boolean(handover?.revive?.plannedText) &&
            !handover.revive.performedAt && (
              <Tooltip title={translate('revives.sendText')} placement="top">
                <div>
                  <DialogButton
                    onClick={() =>
                      postAndCloseRevive(
                        {
                          content: handover.revive.plannedText,
                          type: DiscussionEventType.TEXT,
                          discussionId: handover.revive.discussionId,
                        },
                        handover?.revive,
                      )
                    }
                    size="small"
                    title={translate('revives.title')}
                    text={translate('revives.sendRevive')}
                    component={IconButton}
                  >
                    <ScheduleSendIcon
                      color="primary"
                      fontSize="small"
                      sx={{ fontSize: '1rem' }}
                    />
                  </DialogButton>
                </div>
              </Tooltip>
            )}

          <ReviveDialog
            open={openRevive}
            handoverRevive={handover.revive}
            onClose={() => setOpenReviveDialog(false)}
            onSubmit={handleSubmitRevive}
          />
        </Box>
        <StaffUserSignature
          staffUser={data?.[0]}
          createdAt={handover.createdAt}
        />
      </Box>
    </Box>
  );
};

export const ReportCard = ({
  id,
  report,
  staffUser,
  start,
}: {
  id: number;
  report: string;
  staffUser: User;
  start: Date;
}) => {
  const t = useTranslate();
  return (
    <Box
      sx={{
        marginBottom: '0.25rem',
        padding: '0.25rem 0.5rem',
        border,
        borderRadius: 2,
        position: 'relative',
      }}
    >
      <Typography variant="caption" component="span" color="primary">
        {t('consultations.report')}
      </Typography>
      <Typography
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
          fontSize: '0.875rem',
        }}
      >
        {report}
      </Typography>
      <CustomButton
        to={`/consultations/${id}`}
        variant="text"
        size="small"
        sx={{ padding: 0 }}
      >
        {t('common.seeMore')}
      </CustomButton>
      <StaffUserSignature staffUser={staffUser} createdAt={start} />
    </Box>
  );
};
