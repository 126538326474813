import { Fragment } from 'react';
import {
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  List,
  useListContext,
  useRefresh,
  useTranslate,
  useResourceContext,
  usePermissions,
} from 'react-admin';
import { useParams } from 'react-router-dom';

import { Roles } from '@boTypes/user';
import { MayTopToolbar } from '@components/mayTopToolbar';
import { useMutation } from '@hooks/queryWrappers';
import {
  Redeem as RedeemIcon,
  Close as CloseIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';
import { Box, Button, CircularProgress, Typography } from '@mui/material';

import { Subscription } from '../../../../types';

const CreateBonusButton = () => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const resource = useResourceContext();
  const { id } = useParams<'id'>();
  const { mutate: createBonus, isPending: loading } = useMutation<
    any,
    any,
    void
  >(
    ['createBonus', resource, id],
    {
      url: `/api/${resource}/bonus/${id}`,
      method: 'POST',
    },
    { onSuccess: refresh },
  );
  const { permissions } = usePermissions();

  if (!permissions?.includes(Roles.ADMIN)) {
    return null;
  }

  return (
    <Button
      disabled={loading}
      onClick={() => createBonus()}
      color="primary"
      startIcon={
        loading ? <CircularProgress size={16} thickness={1} /> : <RedeemIcon />
      }
    >
      {translate('subscription.activateBonus')}
    </Button>
  );
};

const DeactivateBonusButton = () => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const resource = useResourceContext();
  const { id } = useParams<'id'>();
  const { permissions } = usePermissions();

  const { mutate: removeBonus, isPending: loading } = useMutation<
    any,
    any,
    void
  >(
    ['removeBonus', resource, id],
    {
      url: `/api/${resource}/remove-bonus/${id}`,
      method: 'POST',
    },
    { onSuccess: refresh },
  );

  if (!permissions?.includes(Roles.ADMIN)) {
    return null;
  }

  return (
    <Button
      onClick={() => removeBonus()}
      color="primary"
      startIcon={
        loading ? <CircularProgress size={16} thickness={1} /> : <CloseIcon />
      }
    >
      {translate('subscription.deactivateBonus')}
    </Button>
  );
};

const RefreshButton = () => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const resource = useResourceContext();
  const { id } = useParams<'id'>();
  const { permissions } = usePermissions();
  const { mutate: refreshBonus, isPending: loading } = useMutation<
    any,
    any,
    void
  >(
    ['refreshBonus', resource, id],
    {
      url: `/api/${resource}/refresh/${id}`,
      method: 'POST',
    },
    { onSuccess: refresh },
  );

  if (!permissions?.includes(Roles.ADMIN)) {
    return null;
  }

  return (
    <Button
      onClick={() => refreshBonus()}
      color="primary"
      startIcon={
        loading ? <CircularProgress size={16} thickness={1} /> : <RefreshIcon />
      }
    >
      {translate('subscription.refresh')}
    </Button>
  );
};

const Toolbar = () => {
  const { data } = useListContext<Subscription>();
  const hasBonus = data?.some((sub) => sub.active && sub.status === 'bonus');

  return (
    <MayTopToolbar>
      <Typography variant="subtitle2">Abonnements</Typography>
      <Box>
        {hasBonus ? <DeactivateBonusButton /> : <CreateBonusButton />}
        <RefreshButton />
      </Box>
    </MayTopToolbar>
  );
};

const EmptyList = () => {
  const translate = useTranslate();

  return (
    <Box sx={{ paddingTop: 1, width: '100%' }}>
      <Toolbar />
      <Typography variant="body2" sx={{ ml: 0.5 }}>
        {translate('subscription.empty')}
      </Typography>
    </Box>
  );
};

export const InlineSubscriptionsList = () => {
  const t = useTranslate();
  const { id } = useParams<'id'>();
  return (
    <List
      resource="subscriptions"
      exporter={false}
      pagination={null}
      filter={{ appUser: Number(id) }}
      empty={<EmptyList />}
      actions={<Toolbar />}
      title={<Fragment />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <DateField
          locales="fr-FR"
          showTime
          source="createdAt"
          label={t('common.createdAt')}
        />
        <DateField
          locales="fr-FR"
          showTime
          source="updatedAt"
          label={t('common.updatedAt')}
        />
        <BooleanField source="active" label={t('common.active')} />
        <TextField source="status" label={t('common.status')} />
      </Datagrid>
    </List>
  );
};
