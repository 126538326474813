import { useState } from 'react';

import { Roles } from '@boTypes/user';
import { ActivityPopup } from '@components/activityPopup';
import { SessionEvaluationDialog } from '@components/SessionEvaluationDialog/SessionEvaluationDialog';
import { useSessionsData } from '@hooks/useActiveSessions';

import {
  EndSessionButton,
  StartSessionButton,
  StartSessionPunctualHelperButton,
} from './components';
import { ReviveSummaryDialog } from '../../components/reviveSummary';
import { useSelector } from '../../store';

export const SessionButton = () => {
  const [displayEvaluation, setDisplayEvaluation] = useState(false);
  const isBoHelper = useSelector((state) =>
    state.user.roles.includes(Roles.BO_PUNCTUAL_HELPERS),
  );
  const { active, isLoading, stop } = useSessionsData();

  return (
    <>
      <ReviveSummaryDialog />
      <SessionEvaluationDialog
        open={displayEvaluation}
        onClose={() => {
          setDisplayEvaluation(false);
        }}
      />
      <ActivityPopup active={active} />
      {active ? (
        <EndSessionButton
          onStopSession={() => setDisplayEvaluation(true)}
          isLoading={isLoading}
          stop={stop}
        />
      ) : isBoHelper ? (
        <StartSessionPunctualHelperButton />
      ) : (
        <StartSessionButton />
      )}
    </>
  );
};
