import { useNotify } from 'ra-core';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useMutation } from '@hooks/queryWrappers';
import { Link } from '@mui/material';

import { TextInput } from '../../components/forms/textInput';
import { Button } from '../../components/generic/Button';
import { AuthFormContainer } from '../components/styled';

interface FormValues {
  new_password?: string;
}

interface Props {
  successCallback: (arg0: FormValues) => void;
}

export const ResetPasswordForm = (props: Props) => {
  const notify = useNotify();
  const { mutateAsync } = useMutation<any, any, { token: string; id: string }>(
    ['send_sms_reset'],
    (data) => ({
      method: 'POST',
      url: `/api/auth/send_sms_reset`,
      data,
    }),
  );
  const { token, id } = useParams();
  const sendSMS = async (data: FormValues) => {
    try {
      await mutateAsync({ token, id });
      props.successCallback(data);
    } catch {
      notify('auth.forms.errors.invalid_credentials');
    }
  };

  const { handleSubmit, control, formState } = useForm({
    defaultValues: { new_password: '' },
  });
  const { isSubmitting } = formState;

  return (
    <>
      <form onSubmit={handleSubmit(sendSMS)}>
        <AuthFormContainer>
          <TextInput
            autoFocus
            control={control}
            name="new_password"
            type="password"
            label="Nouveau mot de passe"
            helperText="Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule & un chiffre ou caractère spécial."
            rules={{
              required: true,
              minLength: 8,
              pattern: /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
            }}
          />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            loading={isSubmitting}
            value="Valider mon nouveau mot de passe"
            fullWidth
            style={{ marginTop: '2rem' }}
          />
        </AuthFormContainer>
      </form>
      <p style={{ textAlign: 'center', margin: '0.25rem' }}>
        <Link
          href="/#/login"
          style={{
            fontSize: '0.75rem',
            cursor: 'pointer',
          }}
          underline="hover"
        >
          {"Revenir à l'écran de connexion"}
        </Link>
      </p>
    </>
  );
};
