import { LibraryFilterContainer } from '@components/libraryFilterContainer';
import { Chip } from '@mui/material';
import { stringToPastelColor } from '@utils/color';

import { Categories } from '../../types/cms';

export const CategoriesList = ({
  list = [],
  selected,
  setSelected,
}: {
  list: Categories[];
  selected: number;
  setSelected: (category?: number | null) => void;
}) => {
  return (
    <LibraryFilterContainer>
      {(list || []).map(({ name, id }) => (
        <Chip
          key={id}
          variant="outlined"
          size="small"
          label={name}
          color={selected === id ? 'primary' : 'default'}
          onClick={
            selected === id ? () => setSelected(null) : () => setSelected(id)
          }
          sx={{
            backgroundColor: stringToPastelColor(name),
          }}
        />
      ))}
    </LibraryFilterContainer>
  );
};
