import React, { useEffect, useMemo } from 'react';
import { Error, Loading, useTranslate } from 'react-admin';
import { useForm } from 'react-hook-form';

import { Button } from '@components/generic/Button';
import { useFormDetail } from '@hooks/form-builder';
import { useMutation, useQuery } from '@hooks/queryWrappers';
import { Close as CloseIcon } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { Form, FormAnswers, orderFormAnswers } from '@teammay/form-core';

import { TextInput } from './textInput';

export const useFormAnswersDetail = (id: FormAnswers['id']) => {
  return useQuery<FormAnswers & { proAnalysis: string }>(
    ['form', id],
    {
      method: 'GET',
      url: `/api/forms/answers/${id}`,
    },
    {
      enabled: !!id,
    },
  );
};

type FormAnswerEdit = {
  proAnalysis: string;
};

export const FilledFormViewer = React.forwardRef<
  HTMLDivElement,
  {
    onClose: () => void;
    form?: Pick<Form, 'id'>;
    formAnswers?: Pick<FormAnswers, 'id'>;
  }
>(({ onClose, form, formAnswers }, ref) => {
  const t = useTranslate();
  const {
    data: formDetail,
    isPending: isFormDetailLoading,
    isError: isFormDetailError,
    error: formDetailError,
    refetch: refetchFormDetail,
  } = useFormDetail(form?.id);

  const {
    data: formAnswersDetail,
    isPending: isFormAnswersDetailLoading,
    isError: isFormAnswersDetailError,
    error: formAnswersDetailError,
    refetch: refetchFormAnswersDetail,
  } = useFormAnswersDetail(formAnswers?.id);

  const { control, setValue, handleSubmit, formState } =
    useForm<FormAnswerEdit>({
      defaultValues: {
        proAnalysis: formAnswersDetail?.proAnalysis,
      },
    });

  const { isSubmitting, isValid } = formState;

  const { mutateAsync: updateFormAnswers } = useMutation<
    FormAnswerEdit,
    Error,
    FormAnswerEdit
  >(['updateFormAnswers'], {
    method: 'PUT',
    url: `/api/forms/answers/${formAnswers?.id}`,
  });

  useEffect(() => {
    if (formAnswersDetail) {
      setValue('proAnalysis', formAnswersDetail.proAnalysis);
    }
  }, [setValue, formAnswersDetail]);

  const onSubmit = handleSubmit(async (data: FormAnswerEdit) => {
    await updateFormAnswers({ proAnalysis: data.proAnalysis });
  });

  const orderedAnswers = useMemo(() => {
    if (!formAnswersDetail || !formDetail) {
      return [];
    }
    return orderFormAnswers(
      formDetail.form,
      formDetail.questions,
      formAnswersDetail,
    );
  }, [formAnswersDetail, formDetail]);

  const FormattedAnswers = useMemo<React.ReactNode[]>(() => {
    const result = [];

    if (
      !formDetail ||
      !formAnswersDetail ||
      formAnswersDetail.answers.length === 0
    ) {
      return [
        <Typography variant="caption" color="error">
          {t('forms.runner.noAnswers')}
        </Typography>,
      ];
    }

    const questionIdToQuestion = formDetail.questions?.reduce(
      (acc, question) => {
        acc[question.id] = question;
        return acc;
      },
      {},
    );

    let currentStep = '';
    for (const answer of orderedAnswers) {
      if (questionIdToQuestion[answer.questionId].stepId !== currentStep) {
        currentStep = questionIdToQuestion[answer.questionId].stepId;
        const step = formDetail.form.steps.find((s) => s.id === currentStep);
        result.push(
          <Typography key={step.id} variant="h6" color="primary" sx={{ mt: 2 }}>
            {step.title}
          </Typography>,
        );
      }

      result.push(
        <Box key={answer.id}>
          <Typography variant="body2" color="primary">
            {answer.questionLabel}
            {t('common.colon')}
          </Typography>
          <Typography variant="body2" component={'span'}>
            {answer.answerLabel}
          </Typography>
          {(answer.score || !!answer.tags?.length) && (
            <Typography variant="caption" component={'span'}>
              {' - '}
              {answer.score && `Score : ${answer.score}`}
              {answer.score && !!answer.tags?.length && ', '}
              {!!answer.tags?.length && `Tags : ${answer.tags.join(', ')}`}
            </Typography>
          )}
        </Box>,
      );
    }
    return result;
  }, [formAnswersDetail, formDetail, orderedAnswers, t]);

  if (isFormDetailError || isFormAnswersDetailError) {
    return (
      <Error
        error={formDetailError || formAnswersDetailError}
        resetErrorBoundary={() => {
          refetchFormDetail();
          refetchFormAnswersDetail();
        }}
      />
    );
  }
  if (
    isFormDetailLoading ||
    isFormAnswersDetailLoading ||
    !formDetail ||
    !formAnswersDetail
  ) {
    return <Loading />;
  }

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        p: 2,
      }}
      ref={ref}
    >
      <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
        <IconButton onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ flex: 0 }}>
        <Typography variant="h5" sx={{ fontWeight: 500 }} color="primary">
          {formDetail.form.title}
        </Typography>
        {!formAnswersDetail?.locked && (
          <Typography variant="caption" color="error">
            {t('forms.runner.unlockedForm')}
          </Typography>
        )}
        {formAnswersDetail?.score && (
          <Typography>
            <Typography
              variant="body2"
              sx={{ fontWeight: 500 }}
              component={'span'}
            >
              {t('forms.runner.score')}
              {t('common.colon')}
            </Typography>
            <Typography variant="body2" component={'span'}>
              {formAnswersDetail.score}
            </Typography>
          </Typography>
        )}
        {formAnswersDetail.tags?.length > 0 && (
          <Typography>
            <Typography
              variant="body2"
              sx={{ fontWeight: 500 }}
              component={'span'}
            >
              {t('forms.runner.tags')}
              {t('common.colon')}
            </Typography>
            <Typography variant="body2" component={'span'}>
              {formAnswersDetail.tags.join(', ')}
            </Typography>
          </Typography>
        )}
      </Box>
      <Box sx={{ overflowY: 'auto', flex: 1 }}>{FormattedAnswers}</Box>
      <Box>
        <TextInput
          control={control}
          name="proAnalysis"
          label={t('forms.runner.proAnalysis')}
          multiline
          variant="outlined"
          fullWidth
          maxRows={8}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            loading={isSubmitting}
            disabled={!isValid}
          >
            {t('common.save')}
          </Button>
          <Button
            variant="text"
            color="inherit"
            onClick={onClose}
            loading={isSubmitting}
          >
            {t('common.close')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
});
