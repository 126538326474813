import {
  BooleanInput,
  SimpleForm,
  TextInput,
  required,
  useTranslate,
} from 'react-admin';

import type { FormProps } from '../../types';

export const CategoriesForm = (props: FormProps) => {
  const translate = useTranslate();

  return (
    <SimpleForm {...props}>
      <TextInput
        label={translate('categories.name')}
        source="name"
        validate={required()}
        variant="standard"
      />
      <TextInput
        label={translate('categories.slug')}
        source="slug"
        validate={required()}
        helperText={translate('categories.slugDoNotModify')}
        variant="standard"
      />
      <BooleanInput
        label={translate('categories.pregnancy')}
        source="pregnancy"
        variant="standard"
      />
      <BooleanInput
        label={translate('categories.excludeFromTriage')}
        source="excludeFromTriage"
        variant="standard"
        defaultValue={false}
      />
    </SimpleForm>
  );
};
