import { useMemo, useState } from 'react';
import { useTranslate } from 'react-admin';

import { Close as CloseIcon } from '@mui/icons-material';
import { Box, IconButton, Tab, Tabs, Typography } from '@mui/material';

import NormalizedCharts from './NormalizedCharts';
import { OMSDataTypes } from './types';
import { useChildHeights, useChildWeights } from '../../hooks/childCharts';
import { Kid } from '../../types';
import { getAgeInMonths } from '../../utils/date';
import { Loader } from '../Loader';

function a11yProps(index: number) {
  return {
    id: `tool-tab-${index}`,
    'aria-controls': `tool-tabpanel-${index}`,
  };
}

export const ChildCharts = ({
  kid,
  onClose,
}: {
  kid: Kid;
  onClose: () => void;
}) => {
  const translate = useTranslate();
  const [selectedMeasureIndex, setSelectedMeasureIndex] = useState<number>(0);
  const [displayedData, setDisplayedData] = useState<'weight' | 'height'>(
    'weight',
  );
  const { data: childHeightList, isLoading: isLoadingHeight } = useChildHeights(
    kid.id,
  );
  const { data: childWeightList, isLoading: isLoadingWeight } = useChildWeights(
    kid.id,
  );

  const dataType = useMemo(() => {
    if (displayedData === 'weight') {
      return kid.sex === 'm'
        ? OMSDataTypes.BOY_WEIGHT
        : OMSDataTypes.GIRL_WEIGHT;
    }
    return kid.sex === 'm' ? OMSDataTypes.BOY_HEIGHT : OMSDataTypes.GIRL_HEIGHT;
  }, [displayedData, kid.sex]);

  const initialDate = useMemo(() => {
    return new Date(kid.birthDay);
  }, [kid.birthDay]);

  const chartMaximumMonths = useMemo(
    () =>
      Math.min(60, Math.max(Math.ceil(getAgeInMonths(kid.birthDay)) + 1, 3)),
    [kid],
  );
  const [tabValue, setTabValue] = useState(0);

  const onTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue === 0) {
      setDisplayedData('weight');
    } else {
      setDisplayedData('height');
    }
    setTabValue(newValue);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          m: 1,
        }}
      >
        <Typography sx={{ fontWeight: 500 }} color="textPrimary">
          {translate('common.charts')}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Tabs
        variant="fullWidth"
        value={tabValue}
        onChange={onTabChange}
        aria-label="basic tabs example"
      >
        <Tab label={translate('common.weight')} {...a11yProps(0)} />
        <Tab label={translate('common.height')} {...a11yProps(1)} />
      </Tabs>
      {isLoadingHeight || isLoadingWeight ? (
        <Loader />
      ) : (
        <NormalizedCharts
          key={`${dataType}-${kid.id}`}
          height={306}
          data={displayedData === 'weight' ? childWeightList : childHeightList}
          dataType={dataType}
          initialDate={initialDate}
          onChangeSelected={setSelectedMeasureIndex}
          selected={selectedMeasureIndex}
          maximumMonths={chartMaximumMonths}
        />
      )}
    </Box>
  );
};
