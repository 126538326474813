import { useEffect } from 'react';

import { SlotEvaluationFeedback } from '@boTypes/slot';
import { useMutation, useQuery, useQueryClient } from '@hooks/queryWrappers';

import { useSelector } from '../store';
import { Socket, SocketEvents } from '../types/socket';
import { Roles } from '../types/user';

export const ACTIVE_SESSION_NOTIFICATIONS = 'activeSessionNotifRoom';

export const useActiveSessions = (socket?: Socket, enabledQuery = false) => {
  const enabled = useSelector(
    (state) =>
      state.user.roles?.includes(Roles.ADMIN) ||
      state.user.roles?.includes(Roles.HEALTH_PRO),
  );
  const sessions = useQuery<number[], any>(
    ['activeSessions'],
    () => ({
      method: 'get',
      url: `/api/chatAttribution/active`,
    }),
    {
      enabled: enabledQuery && enabled,
      staleTime: 1000 * 60 * 5,
      gcTime: 1000 * 60 * 10,
    },
  );
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!socket) {
      return;
    }

    const callback = ({ users: _users }: { users: number[] }) => {
      queryClient.setQueryData(['activeSessions'], _users);
    };

    socket.on(SocketEvents.ACTIVE_SESSIONS_LIST_CHANGED, callback);
    return () => {
      socket.off(SocketEvents.ACTIVE_SESSIONS_LIST_CHANGED, callback);
    };
  }, [queryClient, socket]);

  return sessions;
};

export const useStartSession = () => {
  const userId = useSelector((state) => state.user.userId);
  const queryClient = useQueryClient();
  return useMutation<any, any, { noAttribution?: boolean }>(
    ['sessionStart'],
    (input) => ({
      method: 'put',
      url: `/api/chatAttribution/start`,
      params: input,
    }),
    {
      onSuccess: () => {
        queryClient.setQueryData<number[]>(
          ['activeSessions'],
          (previous?: number[]) => {
            if (!previous) {
              return [Number(userId)];
            }
            return previous
              .filter((u) => String(u) !== String(userId))
              .concat([Number(userId)]);
          },
        );
      },
    },
  );
};

export const useStopSession = () => {
  const userId = useSelector((state) => state.user.userId);
  const queryClient = useQueryClient();
  return useMutation<any, any, {}>(
    ['sessionStop'],
    (input) => ({
      method: 'put',
      url: `/api/chatAttribution/stop`,
      data: input,
    }),
    {
      onSuccess: () => {
        queryClient.setQueryData<number[]>(
          ['activeSessions'],
          (previous?: number[]) => {
            if (!previous) {
              return previous;
            }
            return previous.filter((u) => String(u) !== String(userId));
          },
        );
      },
    },
  );
};

export const useEvaluateSession = () => {
  return useMutation<
    {
      success: boolean;
      participatedSubjects?: number;
      closedSubjects?: number;
    },
    any,
    { feedback: SlotEvaluationFeedback }
  >(['sessionStop'], (input) => ({
    method: 'put',
    url: `/api/chatAttribution/evaluate`,
    data: input,
  }));
};

export const useSessionsData = () => {
  const { data: sessions, isLoading: isLoadingSessions } = useActiveSessions();
  const { mutate: start, isPending: isLoadingStart } = useStartSession();
  const { mutate: stop, isPending: isLoadingStop } = useStopSession();
  const userId = useSelector((state) => state.user.userId);
  const active = Boolean(sessions?.some((s) => String(s) === String(userId)));
  const isLoading = isLoadingSessions || isLoadingStart || isLoadingStop;
  return { isLoading, start, stop, active, isLoadingSessions };
};
