import { Form, FormAnswers } from '@teammay/form-core';

export enum DiscussionEventType {
  TEXT = 'text',
  IMAGE = 'image',
  VIDEO = 'video',
  INTRO = 'intro',
  SYSTEM = 'system',
  GUIDE = 'guide',
  POST = 'post',
  MASTERCLASS = 'masterclass',
  DAILY_TIP = 'dailyTip',
  PARTNER = 'partner',
  DATE_SEPARATOR = 'date', // does not exist in backend
  TYPING = 'typing', // does not exist in backend
  FOLDER = 'folder',
  WEEKLY_TIP = 'weeklyTip',
  FORM = 'form',
}

export const CMS_CONTENT_TYPES = [
  DiscussionEventType.GUIDE,
  DiscussionEventType.POST,
  DiscussionEventType.MASTERCLASS,
  DiscussionEventType.DAILY_TIP,
  DiscussionEventType.FOLDER,
];

export interface SeenBy {
  id: number;
  discussionEventId: number;
  appUserId: number;
  date: string;
}

export interface RawDiscussionEvent {
  id: string;
  authorEmail?: string;
  authorName?: string;
  authorAvatar?: string;
  content: string;
  isMay: boolean;
  createdAt: string;
  firstOfUser?: string;
  type: DiscussionEventType;
  discussionId?: number;
  subjectId?: number;
  active?: boolean;
  seenBy?: SeenBy[];
  formId?: Form['id'] | null;
  form?: Form | null;
  formAnswers?: FormAnswers;
}

export type DiscussionEvent = RawDiscussionEvent & {
  createdAt: Date | string;
  firstOfUser?: Date | string;
  seenBy?: { appUserId: number; date: string | Date }[];
};

export interface ChatDiscussionEvent extends DiscussionEvent {
  isFirst: boolean;
  isLast: boolean;
  isCurrentUser: boolean;
  isRead: boolean;
}

export interface LookupDTO {
  content: string;
  type: DiscussionEventType;
}
