import { Fragment } from 'react';
import {
  Datagrid,
  List,
  NumberField,
  Pagination,
  ReferenceManyCount,
  SearchInput,
  TextField,
  TopToolbar,
  usePermissions,
  useTranslate,
} from 'react-admin';

import { Roles } from '@boTypes/user';
import { Button } from '@components/generic/Button';
import { CreateButton } from '@components/generic/CreateButton';
import { EditButton } from '@components/generic/EditButton';
import { FileDownload } from '@mui/icons-material';
import BusinessIcon from '@mui/icons-material/Business';
import WalletIcon from '@mui/icons-material/Wallet';
import { Box, Button as MuiButton, Paper, Typography } from '@mui/material';
import { openNewSecureTab } from '@utils/urls';

import { baseURL } from '../common';
import { AffiliationChartField } from '../components/fields/AffiliationChartField';

const CompanyPlanCodeFilters = [
  <SearchInput source="q" alwaysOn variant="standard" />,
];
const CompanyPlanFilters = [
  <SearchInput source="q" alwaysOn variant="standard" />,
];

const CompanyPlanCodeToolbar = () => (
  <TopToolbar>
    <CreateButton
      ctaText="companyPlanCode.create"
      redirect="/code-affiliations"
    />
  </TopToolbar>
);

export const CodeAffiliation = () => {
  const { permissions } = usePermissions();
  const translate = useTranslate();

  return (
    <Paper sx={{ padding: '1rem', minHeight: '100%' }} elevation={0}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between ',
          flexDirection: 'row',
        }}
      >
        <Box>
          <Button startIcon={<BusinessIcon />} to={'/companies'}>
            {translate('affiliations.managePartners')}
          </Button>
          <Button startIcon={<WalletIcon />} to={'/plans'}>
            {translate('affiliations.managePlans')}
          </Button>
        </Box>
        <Box>
          <MuiButton
            startIcon={<FileDownload />}
            onClick={() =>
              openNewSecureTab(
                `${baseURL}/api/company-plan-codes/export/proCommunity.csv`,
              )
            }
          >
            {translate('affiliations.exportProCommunity')}
          </MuiButton>
        </Box>
      </Box>

      <Typography variant="h5" sx={{ mt: 1 }}>
        {translate('affiliations.titleCode')}
      </Typography>

      <List
        resource="company-plan-codes"
        filters={CompanyPlanCodeFilters}
        disableSyncWithLocation
        exporter={false}
        perPage={3}
        pagination={<Pagination rowsPerPageOptions={[3, 6]} />}
        empty={null}
        actions={<CompanyPlanCodeToolbar />}
        title={<Fragment />}
      >
        <Datagrid bulkActionButtons={false} rowClick={false}>
          <TextField source="name" />
          <TextField source="code" />
          <ReferenceManyCount
            label="Affiliations"
            reference="affiliations"
            filter={{ modeNot: 'renew' }}
            target="companyPlanCodeId"
          />
          <NumberField source="maxUsage" textAlign="left" />
          <AffiliationChartField lookupField="companyPlanCodeId" />
          {permissions?.includes(Roles.ADMIN) && (
            <EditButton redirect="/code-affiliations" />
          )}
        </Datagrid>
      </List>

      <Typography variant="h5" sx={{ mt: 1 }}>
        Plan d'entreprise
      </Typography>

      <List
        resource="company-plans"
        exporter={false}
        filters={CompanyPlanFilters}
        perPage={3}
        pagination={<Pagination rowsPerPageOptions={[3, 6]} />}
        empty={null}
        disableSyncWithLocation
        actions={false}
        title={<Fragment />}
      >
        <Datagrid bulkActionButtons={false} rowClick={false}>
          <TextField source="alias" label="alias" />
          <ReferenceManyCount
            label="Affiliations"
            filter={{ modeNot: 'renew' }}
            reference="affiliations"
            target="companyPlanId"
          />
          <NumberField source="maxUsage" textAlign="left" />
          <AffiliationChartField lookupField="companyPlanId" />
          {permissions?.includes('admin') && (
            <EditButton redirect="/code-affiliations" />
          )}
        </Datagrid>
      </List>
    </Paper>
  );
};
