import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { produce } from 'immer';
import { useEffect, useMemo } from 'react';

import { CategoryInConsultationHistory } from '@boTypes/category';
import { MinimalSubject } from '@boTypes/discussion';
import { HandoverRevive } from '@boTypes/handover';
import { QueryClient, QueryKey, useInfiniteQuery } from '@hooks/queryWrappers';
import { InfiniteData } from '@tanstack/react-query';

import { Consultation, Discussion, Handover } from '../types';

export type HistoryItem =
  | ({ type?: 'Question' } & Omit<MinimalSubject, 'category'> & {
        categoryId: number;
        date: string;
      })
  | ({ type: 'Consultation' } & Omit<Consultation, 'category'> & {
        category: CategoryInConsultationHistory;
        date: string;
      });

const HISTORY_PAGE_LENGTH = 20;
/**
 *
 * @param discussion : current discussion detail. Should be real time managed so that the output of this function is also realtime
 * @returns list of history items (Subjects, Consultations)
 */
export const usePatientHistory = (discussion?: Discussion) => {
  const appUserId =
    discussion?.appUser && typeof discussion.appUser === 'object'
      ? discussion.appUser.id
      : discussion.appUser;
  const url = discussion?.kidId
    ? `/api/subjects/history/${discussion.kidId}/${discussion.id}`
    : `/api/patients/history/${appUserId}`;

  const history = useInfiniteQuery<HistoryItem[]>(
    // update history when changing subject requires discussion?.lastSubject?.id as key
    {
      queryKey: ['history', url, discussion?.lastSubject?.id],
      queryFn: ({ pageParam = `limit=${HISTORY_PAGE_LENGTH}` }) => ({
        method: 'get',
        url: `${url}?${pageParam}`,
      }),
      enabled: Boolean(discussion?.kidId || appUserId),
      getNextPageParam: (lastPage) =>
        lastPage?.data.length === HISTORY_PAGE_LENGTH
          ? `from=${new Date(
              lastPage.data[lastPage?.data.length - 1].start,
            ).getTime()}&limit=${HISTORY_PAGE_LENGTH}`
          : undefined,
      initialPageParam: undefined,
    },
  );

  const ids = useMemo(
    () =>
      history.data?.pages?.[0]?.data
        ?.filter((item) => !item.type || item.type === 'Question')
        .map((sub) => sub.id) ?? [],
    [history.data],
  );
  const {
    data: handoverPages,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isSuccess,
  } = useInfiniteQuery<
    Handover[],
    any,
    InfiniteData<AxiosResponse<Handover[]>>,
    QueryKey,
    { subjectIds?: number[] }
  >({
    queryKey: ['handovers', discussion?.lastSubject?.id, ids],
    queryFn: ({ pageParam }) => ({
      method: 'get',
      url: `/api/handovers?filter=${JSON.stringify({
        subjectIds: pageParam.subjectIds,
      })}`,
    }),
    enabled: Boolean(history.data?.pages.length),
    getNextPageParam: (_, allPages) => {
      if (allPages.length >= history.data?.pages.length) {
        return undefined;
      }
      return {
        subjectIds:
          history.data?.pages[allPages.length].data
            .filter((item) => !item.type || item.type === 'Question')
            .map((sub) => sub.id) ?? [],
      };
    },
    initialPageParam: { subjectIds: ids },
  });

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  const handovers = useMemo(() => {
    return handoverPages?.pages.flatMap((page) => page.data) ?? [];
  }, [handoverPages?.pages]);
  return useMemo(() => {
    if (history.isSuccess && (ids?.length === 0 || isSuccess)) {
      const items = history.data?.pages
        .slice(0, handoverPages?.pages.length)
        .flatMap((page) => page.data)
        .map((subject) => {
          if (subject.type === 'Consultation') {
            return subject;
          }
          subject.handovers = handovers.filter(
            (handover) => handover.subject?.id === subject.id,
          );
          return subject;
        });

      // last subject is not in history : prepend it if a discussion or handovers re attached to it
      const spliceLength = items[0]?.id === discussion.lastSubject.id ? 1 : 0;
      if (
        !discussion.lastSubject.empty ||
        discussion.lastSubject.handovers?.length
      ) {
        items.splice(0, spliceLength, {
          ...discussion.lastSubject,
          categoryId: discussion.lastSubject?.categoryId,
          type: 'Question',
          date: dayjs(
            discussion.lastSubject.firstMessageAt ||
              discussion.lastSubject.start,
          ).format('DD/MM/YYYY'),
        });
      }

      return {
        revives: items
          .flatMap((item) =>
            // negate type consultation as "type" may not be defined
            item.type !== 'Consultation' && item.handovers
              ? item.handovers
              : [],
          )
          .filter((item) => item.revive && !item.revive.performedAt),
        history: items,
        fetchNextPage: history.fetchNextPage,
        hasNextPage: history.hasNextPage,
      };
    } else {
      return {
        revives: [] as Handover[],
        history: [] as HistoryItem[],
        hasNextPage: false,
        fetchNextPage: () => {},
      };
    }
  }, [
    history.isSuccess,
    history.data?.pages,
    history.fetchNextPage,
    history.hasNextPage,
    ids?.length,
    isSuccess,
    handoverPages?.pages.length,
    discussion.lastSubject,
    handovers,
  ]);
};

export const updateHandoverReviveInHistory = produce(
  (
    handovers: InfiniteData<AxiosResponse<Handover[]>>,
    handoverRevive: HandoverRevive,
  ) => {
    const handover = handovers.pages
      ?.flatMap(({ data }) => data)
      .find((h) => String(h.id) === String(handoverRevive.handoverId));
    if (handover) {
      handover.revive = handoverRevive;
    }
  },
);

export const historyQueryReviveUpdate = (
  queryClient: QueryClient,
  handoverRevive: HandoverRevive,
) => {
  queryClient.setQueriesData<InfiniteData<AxiosResponse<Handover[]>>>(
    {
      queryKey: ['handovers'],
      predicate: (query) => {
        const res = (
          query.state?.data as InfiniteData<AxiosResponse<Handover[]>>
        )?.pages
          ?.flatMap(({ data }) => data)
          ?.some(
            (handover) =>
              String(handoverRevive.handoverId) === String(handover.id),
          );
        return res;
      },
    },
    (handovers) =>
      handovers
        ? updateHandoverReviveInHistory(handovers, handoverRevive)
        : undefined,
  );
};
