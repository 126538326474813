import { useState } from 'react';
import {
  ImageField,
  ImageInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useRecordContext,
  BooleanInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  useTranslate,
  usePermissions,
} from 'react-admin';

import { MarkdownInput } from '@components/forms/markdownInput';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';

import { FormProps, User } from '../../types';
import { JobTitle, Roles } from '../../types/user';
import { formatImage } from '../../utils';
import { titleChoices } from '../common';

const timezones = Intl.supportedValuesOf('timeZone').map((tz) => ({
  id: tz,
  name: tz,
}));

export const UsersForm = (props: FormProps) => {
  const record = useRecordContext<User>();
  const { permissions } = usePermissions();
  const isAdmin = permissions?.includes(Roles.ADMIN);
  const translate = useTranslate();
  const [comfortAreaIds, setComfortAreaIds] = useState<number[] | undefined>(
    record?.comfortAreaIds ?? [],
  );
  const [expertiseAreaIds, setExpertiseAreaIds] = useState<
    number[] | undefined
  >(record?.expertiseAreaIds ?? []);
  return (
    <SimpleForm {...props}>
      <Grid container>
        <Grid item xs={6}>
          <TextInput
            label={translate('staffUser.email')}
            source="email"
            validate={required()}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          {!record?.id ? (
            <TextInput
              label={translate('staffUser.password')}
              type="password"
              source="password"
              validate={required()}
              variant="standard"
              helperText={translate('staffUser.passwordHelperText')}
            />
          ) : (
            <BooleanInput
              disabled
              label={translate('common.active')}
              source="active"
            />
          )}
        </Grid>
      </Grid>
      <ImageInput
        format={formatImage}
        source="avatar"
        label={translate('staffUser.avatar')}
        labelSingle="field.image.placeholder"
        variant="standard"
      >
        <ImageField source="src" />
      </ImageInput>
      <MarkdownInput
        label={translate('common.description')}
        source="description"
      />
      <Grid container>
        <Grid item xs={6}>
          <TextInput
            label={translate('staffUser.firstName')}
            source="firstName"
            validate={required()}
            variant="standard"
          />
          <TextInput
            label={translate('staffUser.lastName')}
            source="lastName"
            validate={required()}
            variant="standard"
          />
          <TextInput
            label={translate('staffUser.commercialName')}
            source="commercialName"
            variant="standard"
          />
          <TextInput
            label={translate('staffUser.phoneWithFormat')}
            source="phone"
            validate={required()}
            variant="standard"
          />
          <Box>
            <SelectInput
              label={translate('staffUser.jobTitle')}
              source="jobTitle"
              choices={titleChoices}
              validate={required()}
              variant="standard"
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            label={translate('staffUser.consultationPrice')}
            source="consultationPrice"
            validate={required()}
            defaultValue={0}
            variant="standard"
          />
          <TextInput
            label={translate('staffUser.iban')}
            source="iban"
            variant="standard"
          />
          <TextInput
            label={translate('staffUser.bic')}
            source="bic"
            variant="standard"
          />
          {isAdmin && (
            <>
              <NumberInput
                label={translate('planning.bias')}
                source="planningBias"
                defaultValue={0}
                variant="standard"
              />
              <NumberInput
                label={translate('planning.priorityBias')}
                source="planningPriorityBias"
                defaultValue={0}
                variant="standard"
              />
            </>
          )}
        </Grid>
        {record?.jobTitle && record.jobTitle !== JobTitle.ADMIN && (
          <>
            <Grid item xs={6} pr={2}>
              <ReferenceArrayInput
                source="expertiseAreaIds"
                reference="categories"
                filter={{
                  pregnancy: record.jobTitle === JobTitle.MIDWIFE,
                  notId: comfortAreaIds,
                }}
                alwaysOn
              >
                <AutocompleteArrayInput
                  label={translate('staffUser.expertiseArea')}
                  variant="standard"
                  optionText={(rec) => rec?.name || '---'}
                  onChange={setExpertiseAreaIds}
                />
              </ReferenceArrayInput>
            </Grid>
            <Grid item xs={6} pr={2}>
              <ReferenceArrayInput
                source="comfortAreaIds"
                reference="categories"
                filter={{
                  pregnancy: record.jobTitle === JobTitle.MIDWIFE,
                  notId: expertiseAreaIds,
                }}
                alwaysOn
              >
                <AutocompleteArrayInput
                  label={translate('staffUser.comfortArea')}
                  variant="standard"
                  optionText={(rec) => rec?.name || '---'}
                  onChange={setComfortAreaIds}
                />
              </ReferenceArrayInput>
            </Grid>
            {isAdmin && (
              <Grid item xs={6} pr={2}>
                <SelectInput
                  label={translate('staffUser.timezone')}
                  source="timezone"
                  choices={timezones}
                  variant="standard"
                />
              </Grid>
            )}
            <Grid item xs={6} pr={2}>
              <SelectInput
                label={translate('staffUser.language')}
                source="language"
                choices={[
                  { id: 'fr', name: 'Français' },
                  { id: 'en', name: 'English' },
                  { id: 'es', name: 'Español' },
                ]}
                variant="standard"
              />
            </Grid>
          </>
        )}
      </Grid>
    </SimpleForm>
  );
};
