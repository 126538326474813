import {
  ArrayField,
  BooleanField,
  ChipField,
  Datagrid,
  Filter,
  FilterProps,
  ListProps,
  ReferenceArrayField,
  ReferenceField,
  SingleFieldList,
  TextField,
  TextInput,
  usePermissions,
  useTranslate,
} from 'react-admin';

import { OpenIdProfileChipField } from '@components/fields/OpenIdProfileChip';
import { GoToDiscussionField } from '@components/fields/RelatedDiscussionURLField';
import { Phone, Search } from '@mui/icons-material';

import { PregnancyDateChipField } from '../../components/fields/PregnancyDateChip';
import { List } from '../../components/generic/List';
import { COLORS } from '../../themes';
import { Roles } from '../../types/user';

type FilterWrapperProps = Omit<FilterProps, 'children'>;

const PatientsFilter = (props: FilterWrapperProps) => {
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const isAdmin = permissions?.includes(Roles.ADMIN);

  return (
    <Filter {...props}>
      <TextInput
        label={translate('common.search')}
        source="mail"
        alwaysOn
        autoFocus
        InputProps={{
          startAdornment: <Search sx={{ color: COLORS.GREY_TEXT }} />,
        }}
        variant="standard"
        placeholder="mich@may.com"
      />
      {isAdmin && (
        <TextInput
          label={translate('common.phone')}
          source="phone"
          InputProps={{
            startAdornment: <Phone sx={{ color: COLORS.GREY_TEXT }} />,
          }}
          placeholder='Ex: "+33612345678"'
          alwaysOn
          variant="standard"
        />
      )}
    </Filter>
  );
};

export const PatientsList = (props: ListProps) => {
  const { permissions } = usePermissions();
  const isAdmin = permissions?.includes(Roles.ADMIN);
  return (
    <List
      {...props}
      sx={{ minHeight: '100%', padding: '0 1rem' }}
      perPage={25}
      filters={<PatientsFilter />}
    >
      <Datagrid bulkActionButtons={false} rowClick={isAdmin ? 'edit' : false}>
        <TextField source="id" />
        <TextField source="name" label="Nom" />
        {isAdmin && <TextField source="email" label="Email" />}
        <ReferenceField label="VIP" source="family.id" reference="family">
          <BooleanField source="vip" label="VIP" />
        </ReferenceField>
        <ReferenceArrayField
          label="Enfants"
          reference="children"
          source="children"
        >
          <SingleFieldList linkType="edit">
            <ChipField source="firstName" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField
          label="Grossesse"
          reference="pregnancies"
          source="pregnancies"
        >
          <SingleFieldList linkType="edit">
            <PregnancyDateChipField />
          </SingleFieldList>
        </ReferenceArrayField>
        <ArrayField source="openIdProfiles" label="Log with">
          <SingleFieldList
            sx={{ display: 'flex', justifyContent: 'space-around' }}
          >
            <OpenIdProfileChipField />
          </SingleFieldList>
        </ArrayField>
        <BooleanField label="Actif" source="active" />
        {!isAdmin && <GoToDiscussionField keyId="id" />}
      </Datagrid>
    </List>
  );
};
