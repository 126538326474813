import { BOTTLE_COLOR, BREAST_COLOR, SLEEP_COLOR } from './constants';
import { i18nProvider } from '../../../App';

export const legend = () => [
  {
    label: i18nProvider.translate('tools.feedingAndSleep.legend.babyBottle'),
    color: BOTTLE_COLOR,
  },
  {
    label: i18nProvider.translate('tools.feedingAndSleep.legend.breastfeed'),
    color: BREAST_COLOR,
  },
  {
    label: i18nProvider.translate('tools.feedingAndSleep.legend.sleep'),
    color: SLEEP_COLOR,
  },
];
