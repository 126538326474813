import { HandoverRevive } from '@boTypes/handover';
import styled from '@emotion/styled';
import { AccordionSummary } from '@mui/material';

import { COLORS } from '../../themes';

export const getReviveColor = (revive?: HandoverRevive) => {
  if (!revive || revive.performedAt || !revive.active) {
    return undefined;
  }
  const reviveAt = new Date(revive.reviveAt).getTime();
  if (reviveAt < Date.now()) {
    return COLORS.PINK[500];
  }
  if (reviveAt < Date.now() + 2 * 3600 * 1000) {
    return COLORS.ORANGE;
  }
  return undefined;
};

export const AccordionSummaryWrapper = styled(AccordionSummary)({
  alignItems: 'center',
  flex: 1,
  padding: 0,
  minHeight: 0,
  height: '1.5rem',
  '&:focus::after': {
    backgroundColor: 'transparent',
  },
  '&:active:hover::after': {
    backgroundColor: 'transparent',
  },
  '& .Mui-expanded': {
    padding: 0,
    minHeight: 0,
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: 0,
    minHeight: 0,
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
});
