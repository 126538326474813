import { remove as removeDiacritics } from 'diacritics';
import { intersection } from 'lodash';
import { ChangeEvent, useState } from 'react';
import { useTranslate } from 'react-admin';
import {
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import { pdfjs } from 'react-pdf';
import { createWorker } from 'tesseract.js';

import { useMutation, useQueryClient } from '@hooks/queryWrappers';

import type { InvoiceFormSetValue } from './invoices.form';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const readFileAsync = async (file: File): Promise<ArrayBuffer | string> => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
};

const extractText = async (fileRead: ArrayBuffer | string) => {
  // This poor text extraction is the best we can do for now
  // The best solution would be to have the invoice in a structured format
  // OR extract info from an AI model
  const pdf = await pdfjs.getDocument(fileRead).promise;
  const totalPageCount = pdf.numPages;
  let text = [];

  const images = [];
  for (let currentPage = 1; currentPage <= totalPageCount; currentPage++) {
    const page = await pdf.getPage(currentPage);
    const textContent = await page.getTextContent();

    const viewport = page.getViewport({ scale: 4 });
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    const renderContext = {
      canvasContext: ctx,
      viewport: viewport,
    };
    const renderTask = page.render(renderContext);
    await renderTask.promise;
    images.push(canvas);

    // Sometimes the text is grouped by line, sometimes it's not
    // Sometimes... the same text is split in different cells for no reason
    // e.g. "480 Euros" might be split in "48" "0" " " "Euros"
    // We try to create two representations, one keeping the pdf extraction
    // and one grouping the text by line more or less
    // We keep both because sometimes the pdf extraction is better
    const groupText = textContent.items
      .reduce((acc, item) => {
        if (acc.length === 0) {
          return [[item]];
        } else if (
          // @ts-ignore
          item.transform[5] ===
          acc[acc.length - 1][acc[acc.length - 1].length - 1].transform[5]
        ) {
          // @ts-ignore
          acc[acc.length - 1].push(item);
          return acc;
        } else {
          // @ts-ignore
          return [...acc, [item]];
        }
      }, [])
      .map((group) => {
        return group.reduce((acc, item) => {
          return acc + item.str;
        }, '');
      });

    // @ts-ignore
    const _text = textContent.items.map((s) => s.str);
    const mergeText = _text
      .concat(groupText)
      .map((s) =>
        // @ts-ignore
        s
          .toLowerCase()
          .replace(/([^\s\d,.])/g, '')
          .replace(/[^\S]/g, ' ') // smart trick to also replace unicode non-breaking spaces
          .trim(),
      )
      .filter((el) => el.length > 1 && el.length < 10);

    text = text.concat(...mergeText);
  }
  return [[...new Set(text)], images];
};

const getTotalVariations = (total: number) => {
  // Total is an amount in flat currency
  // We want to allow for some variations in the invoice in its text form

  const variations = [];
  variations.push(String(total));
  variations.push(String(total).replace('.', ','));
  variations.push(String(total).replace('.', ' '));
  variations.push(String(total).replace('.', '  '));
  variations.push(
    new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
    }).format(total),
  );
  variations.push(new Intl.NumberFormat('fr-FR', {}).format(total));
  if (Number.isInteger(total)) {
    variations.push(new Intl.NumberFormat('fr-FR', {}).format(total) + '.00');
    variations.push(new Intl.NumberFormat('fr-FR', {}).format(total) + ',00');
  }
  variations.push(
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'EUR',
    }).format(total),
  );
  variations.push(new Intl.NumberFormat('en-US', {}).format(total));
  variations.push(
    new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
    }).format(total),
  );
  variations.push(new Intl.NumberFormat('de-DE', {}).format(total));

  if (Number.isInteger(total)) {
    variations.push(String(total) + '.00');
    variations.push(String(total) + '.0');
    variations.push(String(total) + ',00');
    variations.push(String(total) + ',0');
    variations.push(String(total) + ' 00');
    variations.push(String(total) + ' 0');
  } else {
    // try to get XXX.5 € instead of XXX.50 €, remove trailing 0
    variations.push(String(total).replace(/0+(?![.,])$/, ''));
    // try to get XXX.50 € instead of XXX.5 €, add trailing 0
    const otherVariations = variations.map((v) =>
      v
        .replace('€', '')
        .trim()
        .replace(/(?![.,])$/, '0'),
    );
    variations.push(...otherVariations);
  }

  // add variations without the currency symbol and with normal spaces instead of unicode non-breaking spaces
  variations.push(...variations.map((v) => v.replace(/\s*€\s*/, '')));
  variations.push(...variations.map((v) => v.replace(/[^\S]/g, ' ')));

  // makes sure we have unique values
  return [...new Set(variations)];
};

/** Total can be on a line formatted following these examples:
Montant total à payer 540,00 €
Total 615
Total : 525 €
TOTAL EUR TTC 1 400.00
Total TTC 975,00 €
Montant total des honoraires
TOTAL : 590E
TOTAL 300,00 €
Taux de pénalité à compter de la date de règlement
TTC
Reste à payer
TOTAL VERSE A L'ARTISTE AUTEUR
Mentanttotaldeshonoraies
 */

const urssafMatch = /(?:urssaf|DIFFUSEUR)/i;
const totalLineRegex = /(?:total|reglement|montant\s*a|net\s*a\s*payer)(.+)/i;
const valueWithSpaceRegex = /(\d{1,3}[^\S]*\d{1,3}[.,]*\d{0,2})/;
const valueRegex = /(\d{0,3}[^\S]*\d{1,3}[.,]*\d{0,2})/;

const identifyTotal = (text: string[]) => {
  for (const line of text.toReversed()) {
    const match = removeDiacritics(line).match(totalLineRegex);
    if (match && !urssafMatch.test(line)) {
      // we need a two step match as valueRegex matches 1 100.00 as 1 and not 1 100.00
      const longFloatMatch = match[1].match(valueWithSpaceRegex); // use match[1] to keep only what is after total
      if (longFloatMatch) {
        return parseFloat(
          longFloatMatch[1].replace(',', '.').replace(/[^\S]/g, ''),
        );
      }
      const floatMatch = line.match(valueRegex);
      if (floatMatch) {
        return parseFloat(
          floatMatch[1].replace(',', '.').replace(/[^\S]/g, ''),
        );
      }
    }
  }
};

export const useInvoiceUpload = () => {
  const translate = useTranslate();
  const [showAmountError, setShowAmountError] = useState(false);
  const [showAmountSuccess, setShowAmountSuccess] = useState(false);
  const [fileName, setFileName] = useState<string | null>(null);
  const { mutateAsync: S3Fetch } = useMutation<
    { url: string; Key: string; readUrl: string },
    any,
    { type: string; name: string }
  >(['s3'], (file: { type: string; name: string }) => ({
    method: 'POST',
    url: `/api/users-invoices/s3-uploader`,
    data: {
      ContentType: file.type,
      filename: file.name,
    },
  }));

  const { mutateAsync: uploadFile } = useMutation<
    any,
    any,
    { url: string; body: File }
  >(['s3 upload'], ({ url, body }) => ({
    method: 'PUT',
    url,
    data: body,
    withCredentials: false,
    headers: {
      'Content-Type': body.type,
    },
  }));

  const checkFileAndUpload = async (
    evt: ChangeEvent<HTMLInputElement>,
    total: number,
    setValue: UseFormSetValue<InvoiceFormSetValue>,
    setError: UseFormSetError<InvoiceFormSetValue>,
    clearErrors: UseFormClearErrors<InvoiceFormSetValue>,
  ) => {
    // @ts-ignore
    setShowAmountError(false);
    setShowAmountSuccess(false);
    setValue('amountValidated', false);
    clearErrors('comments');

    const invoice = evt.target.files?.[0];
    setFileName(invoice?.name ?? null);
    if (!invoice || !total) {
      return invoice;
    }

    const pdfArrayBuffer = await readFileAsync(invoice);
    const [text, images] = await extractText(pdfArrayBuffer);

    if (intersection(text, getTotalVariations(total)).length === 0) {
      let totalFound: number | undefined;
      const worker = await createWorker('fra');
      for (let i = images.length - 1; i >= 0; i--) {
        const ret = await worker.recognize(images[i]);
        totalFound = identifyTotal(ret.data.text.split('\n'));
        if (totalFound === total) {
          break;
        }
      }
      await worker.terminate();
      if (totalFound === total) {
        setShowAmountSuccess(true);
        setValue('amountValidated', true);
      } else {
        setShowAmountError(true);
        setValue('amountValidated', false, { shouldValidate: true });
        setError('comments', {
          message: translate('invoice.dialog.commentsHelpTextWhenError'),
        });
      }
    } else {
      setShowAmountSuccess(true);
      setValue('amountValidated', true);
    }

    const { url, readUrl } = await S3Fetch(invoice);

    await uploadFile({ url, body: invoice });
    setValue('invoiceURL', readUrl);

    return {
      invoice: readUrl,
    };
  };

  return { checkFileAndUpload, showAmountError, showAmountSuccess, fileName };
};

export const useCreateInvoice = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: createInvoice } = useMutation<
    any,
    any,
    InvoiceFormSetValue
  >(
    ['create invoice'],
    (data) => ({
      method: 'POST',
      url: '/api/users-invoices/',
      data,
    }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['invoices'] });
      },
    },
  );

  return { createInvoice };
};
