import { useAuthProvider } from 'ra-core';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

type LoginParams = {
  email?: string;
  password?: string;
  two_factor_code?: string;
  trustedDevice?: boolean;
};

type Login = (params: LoginParams) => Promise<any>;

export const useLogin = (): Login => {
  const authProvider = useAuthProvider();
  const navigate = useNavigate();

  return useCallback(
    (params: LoginParams = {}) =>
      authProvider
        ? authProvider.login(params).then((ret) => {
            navigate('/');
            return ret;
          })
        : Promise.reject(new Error('No authProvider defined')),
    [authProvider, navigate],
  );
};
