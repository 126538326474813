import { useCallback, useRef } from 'react';

const longPressDelay = 500;

export const useHandleLongPress = (
  onPress?: () => void,
  onLongPress?: () => void,
) => {
  const startClick = useRef(0);

  const handlePress = useCallback(() => {
    if (onLongPress && Date.now() - startClick.current > longPressDelay) {
      onLongPress();
    } else if (onPress) {
      onPress();
    }
  }, [onPress, onLongPress]);

  return [handlePress, () => (startClick.current = Date.now())];
};
