import { useState, MouseEvent, useCallback } from 'react';
import { useTranslate } from 'react-admin';
import { useLocation } from 'react-router';

import { DiscussionEvent, DiscussionEventType } from '@boTypes/discussionEvent';
import { FamilyPopulated } from '@boTypes/family';
import { SubjectExplorer } from '@boTypes/subject';
import { Avatar } from '@components/fields/AvatarField';
import { CategoryChip } from '@components/fields/CategoriesChipField';
import { DateTimeAgo } from '@components/fields/DateTimeAgoField';
import { PatientName } from '@components/patient/patientName';
import { HandoverCard } from '@components/subjects/handoverCard';
import {
  DiscussionContextProvider,
  useDiscussionContext,
} from '@hooks/useDiscussionContext';
import { useNavigateWithParams } from '@hooks/useNavigateWithParams';
import ArticleIcon from '@mui/icons-material/Article';
import { Box, Popover, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import { i18nProvider } from '../../App';
import { DiscussionContext } from '../../common';
import { COLORS } from '../../themes';

const MayUserAvatar = ({ subject }: { subject: SubjectExplorer }) => {
  const lastMaySenderEvent = subject.lastMayEvent;
  if (!lastMaySenderEvent) {
    return null;
  }
  const lastMaySender = {
    avatar: lastMaySenderEvent?.authorAvatar,
    firstName: lastMaySenderEvent?.authorName,
    email: lastMaySenderEvent?.authorEmail,
  };

  return <Avatar user={lastMaySender} size={'tiny'} />;
};

const BottomLeftFooter = ({ subject }: { subject: SubjectExplorer }) => {
  const t = useTranslate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ alignItems: 'center', display: 'flex' }}>
      <MayUserAvatar subject={subject} />
      {subject?.handovers?.length ? (
        <>
          <Typography
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            sx={{
              lineHeight: 0,
            }}
          >
            <ArticleIcon
              sx={{
                color: COLORS.TEXT,
                fontSize: '1.25rem',
              }}
            />
          </Typography>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Box sx={{ padding: '0.5rem 0.5rem 0.25rem 0.5rem' }}>
              {subject.handovers.map((handover) => (
                <HandoverCard handover={handover} key={handover.id} />
              ))}
            </Box>
          </Popover>
        </>
      ) : (
        <Tooltip title={t('handover.noneAvailable')}>
          <ArticleIcon
            sx={{ fontSize: '1.125rem', color: COLORS.GREY_TEXT_LIGHT }}
          />
        </Tooltip>
      )}
    </Box>
  );
};

const getLastMessage = (message?: DiscussionEvent) => {
  if (!message) {
    return i18nProvider.translate('subjectExplorer.noMessage');
  }
  if (!message.active) {
    return '[Message supprimé]';
  }
  switch (message.type) {
    case DiscussionEventType.IMAGE:
      return 'Image';
    case DiscussionEventType.VIDEO:
      return 'Vidéo';
    case DiscussionEventType.POST:
      return 'Article';
    case DiscussionEventType.GUIDE:
      return 'Fiche';
    case DiscussionEventType.MASTERCLASS:
      return 'Masterclass';
    case DiscussionEventType.DAILY_TIP:
      return 'Daily tip';
    case DiscussionEventType.PARTNER:
      return 'Livi';
  }
  return (
    message?.content || i18nProvider.translate('subjectExplorer.noMessage')
  );
};

export const useNavigateForExplorer = () => {
  const navigate = useNavigateWithParams();
  const location = useLocation();
  const basePath = location.pathname.split('/')[1];

  return useCallback(
    (subject: SubjectExplorer) => {
      navigate(`/${basePath}/${subject.id}/discussion/${subject.discussionId}`);
    },
    [navigate, basePath],
  );
};

export const SubjectExplorerCard = ({
  subject,
  family,
  selected,
  onCardClick,
}: {
  subject: SubjectExplorer;
  family: FamilyPopulated;
  selected: boolean;
  onCardClick: (subject: SubjectExplorer) => void;
}) => {
  const discussionContextRaw = useDiscussionContext();

  const discussionContext = subject.discussion?.kidId
    ? DiscussionContext.NURSE
    : DiscussionContext.MIDWIFE;

  const lastMessage = getLastMessage(subject.lastEvent);

  const appUserId =
    typeof subject.discussion.appUser?.id !== 'undefined'
      ? subject.discussion.appUser?.id
      : Number(subject.discussion.appUser) || subject.discussion.appUserId;
  const patient = subject.discussion.kidId
    ? family?.children?.find(
        (k) => String(k.id) === String(subject.discussion.kidId),
      )
    : family?.appUsers?.find((u) => String(u.id) === String(appUserId));

  return (
    <DiscussionContextProvider discussionContext={discussionContext}>
      <Card
        onClick={() => onCardClick(subject)}
        sx={{
          p: '8px 4px 0 8px',
          m: '0',
          cursor: 'pointer',
          borderRadius: 0,
          backgroundColor: selected ? COLORS.GREEN['100'] : 'white',
          maxWidth: '100%',
        }}
        elevation={0}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <PatientName patient={patient} family={family} />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <DateTimeAgo
                date={subject?.lastEvent?.firstOfUser}
                sx={{ fontSize: 10 }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              height: '16px',
              paddingBottom: '4px',
              alignItems: 'center',
              '&::after': {
                width:
                  subject?.withDoctorIntervention ||
                  subject?.withNurseIntervention
                    ? '0.5rem'
                    : 0,
                height:
                  subject?.withDoctorIntervention ||
                  subject?.withNurseIntervention
                    ? '0.5rem'
                    : 0,
                content: '""',
                display: 'inline-block',
                backgroundColor: subject?.withDoctorIntervention
                  ? COLORS.BLUE_DARK['300']
                  : COLORS.BLUE['300'],
                borderColor: subject?.withDoctorIntervention
                  ? COLORS.BLUE_DARK['400']
                  : COLORS.BLUE['400'],
                borderRadius: '1rem',
                right: '-2px',
                position: 'relative',
              },
            }}
          >
            <Tooltip title={lastMessage} placement="right">
              <Box
                sx={{
                  flexShrink: 1,
                  width: '100%',
                  height: '1.5rem',
                  alignItems: 'center',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: COLORS.GREY_TEXT,
                  whiteSpace: 'nowrap',
                  wordBreak: 'break-word',
                }}
              >
                <Typography
                  component={'span'}
                  sx={{
                    fontSize: '0.875rem',
                    lineHeight: '1.25rem',
                    fontWeight: 200,
                    height: '1.25rem',
                    color: subject?.isPriority
                      ? COLORS.PINK['500']
                      : COLORS.GREY_TEXT,
                  }}
                >
                  {lastMessage}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            height: '29px',
            justifyContent: 'space-between',
            paddingBottom: '4px',
            borderBottom: `1px solid #f0f0f0`,
          }}
        >
          <DiscussionContextProvider discussionContext={discussionContextRaw}>
            <BottomLeftFooter subject={subject} />
          </DiscussionContextProvider>
          <CategoryChip category={subject.category} size="small" />
        </Box>
      </Card>
    </DiscussionContextProvider>
  );
};
