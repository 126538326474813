import dayjs, { Dayjs } from 'dayjs';
import fr from 'dayjs/locale/fr';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import { floor, round } from './math';

// Use stricter thresholds
const thresholds = [
  { l: 's', r: 44, d: 'second' },
  { l: 'm', r: 109 },
  { l: 'mm', r: 59, d: 'minute' },
  { l: 'h', r: 89 },
  { l: 'hh', r: 23, d: 'hour' },
  { l: 'd', r: 35 },
  { l: 'dd', r: 28, d: 'day' },
  { l: 'M', r: 45 },
  { l: 'MM', r: 10, d: 'month' },
  { l: 'y', r: 17 },
  { l: 'yy', d: 'year' },
];

dayjs.extend(relativeTime, { thresholds });
dayjs.locale({
  ...fr,
  weekStart: 1,
});
dayjs.extend(updateLocale);
dayjs.extend(timezone);
dayjs.extend(weekOfYear);

dayjs.extend(utc);
dayjs.updateLocale('fr', {
  relativeTime: {
    future: 'dans %s',
    past: 'il y a %s',
    s: 'qq secs',
    m: '1 min',
    mm: '%d mins',
    h: '1 h',
    hh: '%d h',
    d: '1 j',
    dd: '%d j',
    M: '1 mois',
    MM: '%d mois',
    y: '1 an',
    yy: '%d ans',
  },
});

export const dayjsTz = (
  date?: Date | string | Dayjs | number,
  tz: string = 'Europe/Paris',
) => (tz === 'utc' ? dayjs.utc(date) : dayjs(date).tz(tz));

export const getTimeAgo = (date: string | Date) => {
  return dayjs(date).fromNow(true);
};

export const getTimeTo = (date: string | Date) => {
  return dayjs(date).toNow(true);
};

export const getMinuteDiff = (date: string | Date) => {
  return Math.ceil(dayjs().diff(dayjs(date), 'minute', true));
};

export const isWeekend = (date: string | Date) => {
  return dayjs(date).day() === 0 || dayjs(date).day() === 6;
};

export const isNight = (date: string | Date) => {
  const hour = dayjs.utc(date).tz('Europe/Paris').hour();
  return hour > 20;
};

export const getWeek = (date: string | Date) => {
  return dayjs(date).week();
};

export const getReadableTimeRange = (
  start: string | Date,
  end: string | Date,
): string => {
  const _start = dayjs(start);
  const _end = dayjs(end);

  const isSameMonth = _start.month() === _end.month();
  const isSameYear = _start.year() === _end.year();

  const formatStart = isSameYear ? (isSameMonth ? 'D' : 'D MMM') : 'D MMM YY';
  return `${_start.format(formatStart)}-${_end.format('D MMM YY')}`;
};

export const getReadableDate = (date: string | Date, tz: string): string =>
  dayjsTz(date, tz).format('D MMMM YYYY');

export const getReadableHour = (
  date: string | Date | Dayjs | number,
  tz?: string,
): string => dayjsTz(date, tz).format('H[h]mm').replace(/(00$)/, '');

export const getReadableHourRange = (
  start: string | Date,
  end: string | Date,
  tz?: string,
): string => [start, end].map((d) => getReadableHour(d, tz)).join(' - ');

export const isInInterval = (
  date: string | Date,
  start: string | Date,
  end: string | Date,
  strict: boolean = false,
) => {
  const _date = dayjsTz(date, 'utc');
  const _start = dayjsTz(start, 'utc');
  const _end = dayjsTz(end, 'utc');
  return strict
    ? _date.isAfter(_start) && _date.isBefore(_end)
    : (_date.isSame(_start) || _date.isAfter(_start)) &&
        (_date.isSame(_end) || _date.isBefore(_end));
};

export const formatTZ = (
  date: Date | string | Dayjs,
  format: string,
  tz: string = 'Europe/Paris',
): string => dayjs(date).tz(tz).format(format);

export const addDays = (date: Date | string, days: number) =>
  dayjs(date).add(days, 'day').toDate();

export const computeStartPregnancyDate = (expectedEnd: string | Date) => {
  return dayjs.utc(expectedEnd).subtract(39, 'week').toDate();
};

export const getAgeInMonths = (date: Date | string, tz?: string): number =>
  dayjsTz(new Date(), tz).diff(dayjsTz(date, tz), 'month', true);

export const preciseAmenorrheaWeeks = (expectedEnd: string | Date) => {
  const endDate = dayjs(expectedEnd);
  const startDate = endDate.subtract(41, 'weeks');
  const now = dayjs();
  const weeks = Math.floor(now.diff(startDate, 'weeks'));
  const remainingDays = now.diff(startDate.add(weeks, 'weeks'), 'days');
  return remainingDays > 0 ? `${weeks}+${remainingDays}SA` : `${weeks}SA`;
};

export const isSameDay = (date1: Date | string, date2: Date | string) => {
  return dayjs(date1).isSame(dayjs(date2), 'day');
};

/**
 * Round a date to the nearest value
 * @param date - Date to round
 * @param msValue - Value to round to, in milliseconds
 * @returns
 * Date rounded to the nearest value
 * @example
 * roundToNearestValue(new Date('2021-01-01T12:03:00'), 5 * 60 * 1000) // 2021-01-01T12:05:00
 */
export const roundToNearestValue = (date: Date, msValue: number) =>
  new Date(round(date.getTime(), msValue));

/**
 * Get the number of hours a period spans across
 *
 * [ start; end [ - start is inclusive, end is exclusive
 * @param period
 * @returns
 * Integer number of hours
 * @example
 * getSpannedAcrossHours({
 *  start: new Date('2021-01-01T12:00:00'),
 *  end: new Date('2021-01-01T13:30:00'),
 *  }) // 2
 */
export const getSpannedAcrossHours = (period: {
  start: Date;
  end: Date | null;
}) => {
  const start = dayjs(period.start);
  const end = period.end ? dayjs(period.end) : dayjs();

  const periodStartHour = start.startOf('h');
  const periodEndHour = end.minute() === 0 ? end : end.endOf('h');

  return Math.round(periodEndHour.diff(periodStartHour, 'h', true));
};

/**
 * Floor a date to the nearest value
 * @param date - Date to floor
 * @param msValue - Value to floor to, in milliseconds
 * Date floored to the nearest value
 * @example
 * floorToNearestValue(new Date('2021-01-01T12:03:00'), 5 * 60 * 1000) // 2021-01-01T12:00:00
 */
export const floorToNearestValue = (date: Date, msValue: number) =>
  new Date(floor(date.getTime(), msValue));
