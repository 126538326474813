import dayjs from 'dayjs';
import { utils as xlsUtils, WorkSheet } from 'xlsx';

import { StaffUser } from '@boTypes/staffUser';
import { titleToPlanningJob } from '@boTypes/user';
import { CalendarEvent } from '@components/Calendar';

import { jobColors } from './components/healthProColors';
import { COLORS } from '../../themes';
import { User } from '../../types';
import {
  Planning,
  PlanningAdditionalHours,
  PlanningJob,
  UserInvoiceData,
} from '../../types/planning';
import { Slot, EntitySlotType } from '../../types/slot';
import { stringToColor } from '../../utils/color';
import { formatTZ } from '../../utils/date';

export const getPlanningInterval = ({ slots }: { slots: Slot[] }) => {
  const startDates = slots.map((slot) => new Date(slot.start).getTime());
  const endDates = slots.map((slot) => new Date(slot.end).getTime());
  return [new Date(Math.min(...startDates)), new Date(Math.max(...endDates))];
};

export const isDateInPlanning = (date: Date, planning: Planning) => {
  const [start, end] = getPlanningInterval(planning);
  return date.getTime() >= start.getTime() && date.getTime() <= end.getTime();
};

export const fullName = (user: Pick<User, 'firstName' | 'lastName'>) =>
  user ? `${user.firstName} ${user.lastName}` : 'undefined';

export const additionalHoursAppointment = (
  additionalHours?: PlanningAdditionalHours[],
  staffUsersById?: Record<string, User>,
) =>
  additionalHours?.map((hour) => {
    return {
      id: 'h-' + String(hour.id),
      _id: hour.id,
      planningId: hour.planningId,
      staffUserId: hour.staffUserId,
      start: new Date(hour.start),
      end: new Date(hour.end),
      isPriority: false,
      isTriage: false,
      onCall: false,
      onCallActivated: false,
      color: hour.validatedById
        ? (jobColors[
            titleToPlanningJob[
              staffUsersById?.[String(hour.staffUserId)]?.jobTitle
            ]
          ] ?? COLORS.PINK[300])
        : COLORS.GREY_TEXT_LIGHT,
      title:
        (hour.validatedById ? '⏰ ' : '⏰? ') +
        (staffUsersById?.[String(hour.staffUserId)]
          ? fullName(staffUsersById?.[String(hour.staffUserId)])
          : String(hour.staffUserId)) +
        (hour.validatedById ? '' : ' (non validé)'),
      entityType: EntitySlotType.additionalHour,
      validated: !!hour.validatedById,
      job: '' as PlanningJob | '',
    };
  }) ?? [];

export const computeSlotTitle = (
  slot: Slot,
  staffUsersById?: Record<string, User>,
  staffUserId?: number,
  defaultTitle = '',
  onCallActivated = false,
) => {
  let prefix = '';
  if (slot.onCall) {
    prefix = onCallActivated ? '🚒 ' : '🔥 ';
  } else if (slot.isTriage) {
    prefix = '🔀 ';
  }

  let title = '';
  if (!staffUserId) {
    title = defaultTitle;
  } else if (staffUsersById?.[String(staffUserId)]) {
    title = fullName(staffUsersById?.[String(staffUserId)]);
  } else {
    title = String(staffUserId);
  }
  return `${prefix}${title}`;
};

export const ownSlotTitle = (slot: Slot) => {
  let title = 'Créneau';
  if (slot.onCall) {
    title += ' (astreinte)';
  } else if (slot.isTriage) {
    title += ' IAO';
  }

  const durationHour = dayjs(slot.end).diff(dayjs(slot.start), 'hours');
  const durationMin =
    dayjs(slot.end).diff(dayjs(slot.start), 'minutes') - durationHour * 60;

  return `${title} ${durationHour}h ${
    durationMin ? durationMin + 'min' : ''
  }`.trim();
};

export const slotAppointment = (
  slot: Slot,
  staffUserId?: number,
  onCallActivated = false,
  staffUsersById?: Record<string, User>,
  job?: PlanningJob | '',
  currentUserId?: StaffUser['id'],
) => ({
  id: slot.id as string | number,
  staffUserId,
  start: new Date(slot.start),
  end: new Date(slot.end),
  isPriority: slot.isPriority,
  isTriage: slot.isTriage,
  onCall: slot.onCall,
  onCallActivated,
  title: computeSlotTitle(
    slot,
    staffUsersById,
    staffUserId,
    'Non attribué',
    onCallActivated,
  ),
  entityType: EntitySlotType.slot,
  color: staffUserId
    ? job
      ? // color per user
        stringToColor(staffUsersById?.[staffUserId]?.fullName)
      : // color per job OR red if non attributed
        staffUserId === currentUserId
        ? COLORS.BLUE_DARK[400]
        : jobColors[
            titleToPlanningJob[staffUsersById?.[String(staffUserId)]?.jobTitle]
          ]
    : COLORS.PINK[500],
  job: slot?.planning?.job || job,
});

export const availabilityAppointment = (
  slot: Slot,
  staffUserId: number,
  staffUsersById?: Record<string, User>,
  job?: PlanningJob | '',
) => ({
  id: `${slot.id}-${staffUserId}`,
  staffUserId: staffUserId ? String(staffUserId) : undefined,
  start: new Date(slot.start),
  end: new Date(slot.end),
  isPriority: slot.isPriority,
  isTriage: slot.isTriage,
  onCall: slot.onCall,
  title: computeSlotTitle(
    slot,
    staffUsersById,
    staffUserId,
    'Pas de dispo',
    false,
  ),
  job,
  color:
    staffUserId && staffUsersById?.[staffUserId]?.fullName
      ? stringToColor(staffUsersById?.[staffUserId]?.fullName)
      : COLORS.PINK[500],
});

export const formatDecimal = (value: number, decimal: string) =>
  String(value).replace('.', decimal);

export const getInvoiceRows = (
  invoice: UserInvoiceData,
  planning: Planning,
  format = { separator: ';', decimal: ',' },
) => {
  const rows = [] as string[][];
  rows.push([
    'Facture du',
    formatTZ(planning.begin, 'DD/MM/YYYY'),
    'au',
    formatTZ(planning.end, 'DD/MM/YYYY'),
  ]);
  rows.push([fullName(invoice.staffUser), '', '', '']);
  rows.push(['', '', '', '']);
  rows.push(['Titre', 'Nombre', 'Prix unitaire', 'Total']);
  invoice.hoursList
    .concat(invoice.extraHoursList)
    .concat(invoice.consultationList)
    .forEach((invoiceRow) => {
      rows.push([
        invoiceRow.title,
        formatDecimal(invoiceRow.unit, format.decimal),
        formatDecimal(invoiceRow.price, format.decimal),
        formatDecimal(invoiceRow.total, format.decimal),
      ]);
    });
  rows.push(['', '', '', '']);
  if (invoice.vat) {
    rows.push([
      '',
      '',
      'Total HT',
      formatDecimal(invoice.total, format.decimal),
    ]);
    rows.push([
      '',
      '',
      'TVA 20%',
      formatDecimal(invoice.total * 0.2, format.decimal),
    ]);
    rows.push([
      '',
      '',
      'Total TTC',
      formatDecimal(invoice.total * 1.2, format.decimal),
    ]);
  } else {
    rows.push(['', '', 'Total', formatDecimal(invoice.total, format.decimal)]);
  }

  rows.push(['', '', '', '']);
  rows.push(['Détail par créneau HT', '', '', '']);
  rows.push(['', '', '', '']);

  if (invoice.details?.slotList?.length) {
    rows.push(['Créneaux', '', '', '']);
    invoice.details?.slotList.forEach((slot) => {
      rows.push([
        slot.title,
        '',
        '',
        formatDecimal(slot.price, format.decimal),
      ]);
    });
    rows.push(['', '', '', '']);
  }

  if (invoice.details?.extraHoursList?.length) {
    rows.push(['Heure de renforts', '', '', '']);
    invoice.details?.extraHoursList.forEach((extraHour) => {
      rows.push([
        extraHour.title,
        '',
        '',
        formatDecimal(extraHour.price, format.decimal),
      ]);
    });
    rows.push(['', '', '', '']);
  }

  if (invoice.details?.consultationList?.length) {
    rows.push(['Consultations', '', '', '']);
    invoice.details?.consultationList.forEach((consultation) => {
      rows.push([
        consultation.title,
        '',
        '',
        formatDecimal(consultation.price, format.decimal),
      ]);
    });
    rows.push(['', '', '', '']);
  }

  return rows;
};

export const getXLSXSummaryData = (
  usersTotal: { name: string; total: number; vat: boolean }[],
  planning: Planning,
) => {
  const rows = usersTotal.map((userTotal) => {
    const vat = userTotal.vat ? -userTotal.total * 0.2 : '';
    return [
      'Décaissement honoraires praticiens tchat',
      '', // Date,
      planning.job === PlanningJob.NURSE
        ? 'Honoraires puers'
        : 'Honoraires médicaux',
      userTotal.name,
      -userTotal.total,
      vat, // VAT,
      -userTotal.total + (vat ? vat : 0),
      '', // paid status
      '', // transfer date
      'Virement',
    ];
  });
  const ws = xlsUtils.aoa_to_sheet(rows);
  return reformatWs(ws, rows);
};

export const reformatWs = (ws: WorkSheet, rows: (string | number)[][]) => {
  ws['!cols'] = fitToColumn(rows);
  return ws;
};

export const fitToColumn = (arrayOfArray: (string | number)[][]) => {
  // get maximum character of each column
  return arrayOfArray[0]?.map((a, i) => ({
    wch: Math.max(
      ...arrayOfArray.map((a2) => (a2[i] ? a2[i].toString().length : 0)),
    ),
  }));
};

export const getXLSXFromUserInvoiceData = (
  invoice: UserInvoiceData,
  planning: Planning,
  format = { separator: ';', decimal: ',' },
) => {
  const rows = getInvoiceRows(invoice, planning, format);
  const ws = xlsUtils.aoa_to_sheet(rows);
  return reformatWs(ws, rows);
};

export const isUserSlot = (evt: CalendarEvent, userId) => {
  return evt.staffUserId === userId && evt.entityType === EntitySlotType.slot;
};

export const isOnCallActivated = (evt: CalendarEvent, userId) => {
  return (
    evt.staffUserId === userId &&
    evt.entityType === EntitySlotType.slot &&
    evt.onCall &&
    evt.onCallActivated
  );
};

export const isOnCallNotActivated = (evt: CalendarEvent, userId) => {
  return (
    evt.staffUserId === userId &&
    evt.entityType === EntitySlotType.slot &&
    evt.onCall &&
    !evt.onCallActivated
  );
};

export const isNotAttributedSlot = (evt: CalendarEvent) => {
  return !evt.staffUserId && evt.entityType === EntitySlotType.slot;
};
