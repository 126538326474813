import dayjs from 'dayjs';
import maxBy from 'lodash/maxBy';
import { useCallback, useMemo } from 'react';

import { MinimalSubject } from '@boTypes/subject';
import { useDiscussionDetail } from '@hooks/discussion';
import { useTargetSubject } from '@hooks/discussionEvents';
import { Videocam } from '@mui/icons-material';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Typography, Accordion, AccordionDetails } from '@mui/material';
import { Box } from '@mui/system';
import { getHandoverDate } from '@utils/handovers';

import { CategoryChipSelect } from './CategoryChipSelect';
import { AccordionSummaryWrapper, getReviveColor } from './handover.utils';
import { HandoverCard, ReportCard } from './handoverCard';
import { HistoryItem } from '../../hooks/history';
import { useDiscussionContext } from '../../hooks/useDiscussionContext';
import { COLORS } from '../../themes';
import { Category, Handover } from '../../types';
import { getTimeAgo } from '../../utils/date';
import { CategoryChip } from '../fields/CategoriesChipField';

export const SubjectContent = ({
  subject,
  canEdit,
  onEditClick,
  referenceDate,
  categoryList,
}: {
  subject: HistoryItem;
  canEdit: boolean;
  onEditClick: (handover: Handover) => void;
  referenceDate?: dayjs.Dayjs;
  categoryList: Category[];
}) => {
  const handovers =
    subject.type !== 'Consultation' &&
    subject.handovers
      ?.filter((handover) => handover.active)
      .sort((a, b) => {
        const aTime = new Date(a.createdAt).getTime();
        const bTime = new Date(b.createdAt).getTime();
        return bTime - aTime;
      });

  let expanded = Boolean(canEdit);
  if (
    !canEdit &&
    (subject.type === 'Consultation' ? subject.report : handovers?.length)
  ) {
    // The latest handovers should NOT be collapsable (business rule)
    expanded = undefined;
  }

  const discussion = useDiscussionDetail();
  const discussionContext = useDiscussionContext();
  const subjectDate =
    (subject.type !== 'Consultation' &&
      (subject.firstMessageAt ?? subject.end)) ||
    subject.start;

  const date = useMemo(
    () => getHandoverDate(subject, discussionContext, referenceDate),
    [discussionContext, referenceDate, subject],
  );

  const earliestRevive = useMemo(() => {
    if (!handovers) {
      return undefined;
    }
    return maxBy(
      handovers.flatMap((handover) => handover.revive ?? []),
      (revive) => new Date(revive.reviveAt).getTime(),
    );
  }, [handovers]);

  const reviveColor = getReviveColor(earliestRevive);
  const category =
    //@ts-ignore
    subject.category ?? categoryList.find((c) => c.id === subject.categoryId);

  const focusOnSubject = useTargetSubject(discussion.id);

  const focusSubjectOnClick = useCallback(
    (_subject: HistoryItem) => {
      // midwife subjects do not have a type
      // For nurses, only "questions" can be scrolled up to
      return !_subject.type || _subject.type !== 'Consultation'
        ? () => focusOnSubject(_subject as MinimalSubject)
        : undefined;
    },
    [focusOnSubject],
  );

  return (
    <Box
      sx={{
        marginLeft: '0.125rem',
        paddingLeft: '0.125rem',
        borderLeft: `1px solid ${COLORS.GREEN['300']}`,
        display: 'flex',
        flexDirection: 'row',
        '&::before': {
          left: '-0.575rem',
          top: '0.375rem',
          position: 'relative',
          content: '""',
          display: 'inline-block',
          width: '0.875rem',
          height: '0.875rem',
          borderRadius: '50%',
          backgroundColor: COLORS.GREEN['300'],
        },
      }}
    >
      <Accordion
        disableGutters
        defaultExpanded
        expanded={expanded}
        sx={{
          flex: 1,
          border: `1px solid transparent`,
        }}
        elevation={0}
      >
        <AccordionSummaryWrapper
          expandIcon={
            Boolean(
              subject.type === 'Consultation'
                ? subject?.report
                : handovers?.length,
            ) && <ArrowForwardIosSharpIcon sx={{ fontSize: '0.875rem' }} />
          }
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              flex: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              onClick={focusSubjectOnClick(subject)}
            >
              {handovers &&
                handovers?.some((h) => h.revive && !h.revive.performedAt) && (
                  <>
                    <AccessAlarmIcon
                      sx={{
                        fontSize: '0.625rem',
                        marginRight: '0.25rem',
                        color: reviveColor,
                      }}
                    />
                  </>
                )}
              <Typography
                sx={{
                  fontSize: '0.75rem',
                  fontWeight: 500,
                }}
              >
                {getTimeAgo(subjectDate)}
              </Typography>
              {Boolean(date) && (
                <Typography
                  sx={{
                    fontSize: '0.625rem',
                    color: reviveColor,
                  }}
                >
                  &nbsp;{`${date}`}
                </Typography>
              )}
              {subject.type === 'Consultation' && (
                <Videocam
                  fontSize="small"
                  sx={{ color: COLORS.GREY_TEXT_LIGHT }}
                />
              )}
            </Box>
            {Boolean(category && !canEdit) && (
              <CategoryChip
                size="small"
                category={category}
                sx={{ fontSize: '0.75rem' }}
              />
            )}
            {Boolean(canEdit) && (
              <CategoryChipSelect
                subject={discussion.lastSubject}
                sx={{ fontSize: '0.75rem' }}
              />
            )}
          </Box>
        </AccordionSummaryWrapper>
        {Boolean(handovers.length) && (
          <AccordionDetails sx={{ margin: '0.125rem 0 0 0', padding: 0 }}>
            {handovers.map((handover) => (
              <HandoverCard
                key={handover.id}
                handover={handover}
                canEdit={canEdit}
                onEditClick={onEditClick}
                onCardClick={focusSubjectOnClick(subject)}
              />
            ))}
          </AccordionDetails>
        )}
        {subject.type === 'Consultation' && Boolean(subject?.report) && (
          <AccordionDetails sx={{ margin: '0.125rem 0 0 0', padding: 0 }}>
            <ReportCard
              id={subject.id}
              report={subject.report}
              staffUser={subject.staffUser}
              start={subject.start}
            />
          </AccordionDetails>
        )}
      </Accordion>
    </Box>
  );
};
