import { useMemo } from 'react';

import { HandoverRevive } from '@boTypes/handover';
import { JobTitle, User } from '@boTypes/user';
import { useGetMyHandoverRevive } from '@hooks/handover';
import { useSessionsData } from '@hooks/useActiveSessions';

import { useSelector } from '../../store';

const oneMonth = 30 * 24 * 60 * 60 * 1000;
const fourHours = 4 * 60 * 60 * 1000;

const jobFilters: Record<JobTitle, (h1: HandoverRevive) => boolean> = {
  [JobTitle.NURSERY_NURSE]: (h1) => Boolean(h1?.discussion?.kidId),
  [JobTitle.MIDWIFE]: (h1) => !h1?.discussion?.kidId,
  [JobTitle.ADMIN]: () => true,
  [JobTitle.GENERAL_PRACTITIONER]: () => false,
  [JobTitle.PEDIATRICIAN]: () => false,
  [JobTitle.NUTRITIONIST_DOCTOR]: () => false,
  [JobTitle.CHILD_PSYCHIATRIST]: () => false,
  [JobTitle.PSYCHOLOGIST]: () => false,
};

export const useReviveSummary = ({ enabled }: { enabled: boolean }) => {
  const { active } = useSessionsData();
  const jobTitle = useSelector((state) => state.user.jobTitle);

  const start = useMemo(
    () => (enabled ? new Date(Date.now() - oneMonth) : new Date()),
    [enabled],
  );
  const end = useMemo(
    () => (enabled ? new Date(Date.now() + fourHours) : new Date()),
    [enabled],
  );

  const { data: reviveNames } = useGetMyHandoverRevive(
    { start, end },
    {
      enabled: enabled && active,
      select: (data) =>
        data
          .filter(jobFilters[jobTitle] ?? (() => true))
          .map(
            (r) =>
              r?.discussion?.child?.firstName ??
              (r?.discussion?.appUser as User)?.firstName,
          ),
    },
  );

  return { reviveNames };
};
