import { useTranslate } from 'react-admin';
import { useDispatch } from 'react-redux';

import { Box, Button, Dialog, List, ListItem, Typography } from '@mui/material';

import { useReviveSummary } from './hook';
import { useSelector } from '../../store';
import { setDisplayReviveSummary } from '../../store/reviveSummary';
import { DialogTitle } from '../generic/Dialog';

export const ReviveSummaryDialog = () => {
  const visible = useSelector(
    (state) => state.reviveSummary.displayReviveSummary,
  );
  const { reviveNames } = useReviveSummary({ enabled: visible });
  const translate = useTranslate();
  const dispatch = useDispatch();
  return (
    <Dialog
      open={Boolean(reviveNames?.length) && visible}
      onClose={() => {
        dispatch(setDisplayReviveSummary(false));
      }}
      aria-labelledby={translate('revives.todo')}
      aria-describedby={translate('revives.todo')}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle onClose={() => dispatch(setDisplayReviveSummary(false))}>
        {translate('revives.todo')}
      </DialogTitle>
      <Box sx={{ marginLeft: 3 }}>
        <Typography>
          {translate('revives.planned', {
            smart_count: reviveNames?.length ?? 0,
          })}
        </Typography>
        <List>
          {reviveNames?.map((firstName: string, index: number) => (
            <ListItem key={index}>{firstName}</ListItem>
          ))}
        </List>
      </Box>
      <Button
        sx={{ p: 1, m: 1 }}
        onClick={() => dispatch(setDisplayReviveSummary(false))}
      >
        {translate('common.close')}
      </Button>
    </Dialog>
  );
};
