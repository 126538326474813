import { JobTitle, Roles } from '@boTypes/user';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface User {
  userId: number;
  email: string;
  roles?: Roles[];
  firstName?: string;
  lastName?: string;
  jobTitle?: JobTitle;
  language?: string;
  description?: string;
}

interface Identity {
  firstName: string;
  lastName: string;
  jobTitle: JobTitle;
  language?: string;
  description?: string;
}

interface UserRole {
  roles: Roles[];
}

const initialState = {
  userId: null,
  email: null,
  roles: [],
  firstName: null,
  lastName: null,
  jobTitle: null,
  description: undefined,
  language: undefined,
} as User;

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User>) {
      const { userId, email } = action.payload;
      state.userId = userId;
      state.email = email;
    },
    setRoles(state, action: PayloadAction<UserRole>) {
      state.roles = action.payload.roles;
    },
    setIdentity(state, action: PayloadAction<Identity>) {
      const { firstName, lastName, jobTitle, description, language } =
        action.payload;
      state.firstName = firstName;
      state.lastName = lastName;
      state.jobTitle = jobTitle;
      state.description = description;
      state.language = language;
    },
    reset() {
      return initialState;
    },
  },
});

export const { setUser, setRoles, reset, setIdentity } = userSlice.actions;

export default userSlice.reducer;
