import {
  Changelog,
  ChangelogTracker,
  jobTitleToChangelogAccess,
} from '@boTypes/changelog';
import { Changelog as ChangelogType } from '@boTypes/changelog';
import {
  useCMSMutation,
  useCMSQuery,
  useQueryClient,
} from '@hooks/queryWrappers';
import { keepPreviousData } from '@tanstack/react-query';

import { useSelector } from '../../store';

export const useChangelogs = () => {
  const jobTitle = useSelector((state) => state.user.jobTitle);
  const changelogAccess = jobTitleToChangelogAccess[jobTitle];

  const { data: changelogs, isLoading } = useCMSQuery<Changelog[]>(
    ['changelog'],
    () => ({
      method: 'GET',
      url: '/changelogs',
    }),
    {
      select: (data) =>
        data?.filter((changelog) => changelogAccess.includes(changelog.type)),
      enabled: !!changelogAccess,
      placeholderData: keepPreviousData,
      gcTime: 1000 * 60 * 60 * 1.5,
      staleTime: 1000 * 60 * 60,
    },
  );

  return { changelogs, isLoading };
};

export const useChangelog = (id: string) => {
  const { data: changelog } = useCMSQuery<ChangelogType>(
    ['changelog', id],
    {
      url: `/changelogs/${id}`,
    },
    { enabled: !!id },
  );

  return { changelog };
};

export const useReadChangelog = () => {
  const queryClient = useQueryClient();
  return useCMSMutation(
    ['changelog'],
    (changelog: number) => ({
      method: 'POST',
      url: '/changelog-trackers',
      data: { changelog },
    }),
    {
      onSuccess: (data: ChangelogTracker, variables) => {
        queryClient.setQueryData<Changelog[]>(
          ['changelog'],
          (oldData: Changelog[]) => {
            return oldData?.map((changelog) => {
              if (changelog.id === variables) {
                return { ...changelog, read: true };
              }
              return changelog;
            });
          },
        );
      },
    },
  );
};
