import { useEffect } from 'react';
import {
  CheckForApplicationUpdate,
  LayoutProps,
  Sidebar,
  useAuthState,
  useLogout,
  useSidebarState,
} from 'react-admin';

import { useCommonHooks } from '@hooks/useCommonHooks';
import { styled, useMediaQuery, useTheme } from '@mui/material';

import MyAppBar from './AppBar';
import MyMenu from './Menu';
import { usePageTracking } from '../analytics';
import { useSelector } from '../store';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  zIndex: 1,
  minHeight: '100vh',
  position: 'relative',
  height: '100vh',
  width: '100%',
}));

const AppFrame = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '1',
  // height: '100%',
}));

const ContentWithSidebar = styled('main')(() => ({
  display: 'flex',
  flexGrow: 1,
  height: 'calc(100% - 48px)',
  marginTop: '48px',
}));

const Content = styled('div')(
  ({ open, isSmall }: { open: boolean; isSmall: boolean }) => ({
    backgroundColor: '#fff',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexBasis: 0,
    padding: 0,
    height: '100%',
    width: isSmall ? '100%' : `calc(100% - ${open ? '200px' : '50px'})`,
    transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  }),
);

export const MyLayout = ({ children }: LayoutProps) => {
  useCommonHooks();
  usePageTracking();

  const logout = useLogout();
  const { isLoading, authenticated } = useAuthState();
  const isStoreLogged = useSelector((state) => Boolean(state.user.email));
  const [open] = useSidebarState();
  const globTheme = useTheme();
  const isSmall = useMediaQuery(globTheme.breakpoints.down('xs'));

  useEffect(() => {
    if (!isStoreLogged && !isLoading && authenticated) {
      logout();
    }
  }, [authenticated, isLoading, isStoreLogged, logout]);

  return (
    <Root>
      <AppFrame>
        <MyAppBar />
        <ContentWithSidebar>
          <Sidebar>
            <MyMenu />
          </Sidebar>
          <Content open={open} isSmall={isSmall}>
            {children}
          </Content>
          <CheckForApplicationUpdate />
        </ContentWithSidebar>
      </AppFrame>
    </Root>
  );
};

export default MyLayout;
