import { useGetIdentity, useTranslate } from 'react-admin';
import ReactMarkdown from 'react-markdown';

import { Card, CardContent, Typography } from '@mui/material';

export const DescriptionCard = () => {
  const translate = useTranslate();
  const { identity: user, isLoading } = useGetIdentity();

  if (isLoading || !user?.description) {
    return null;
  }

  return (
    <Card elevation={2}>
      <CardContent>
        <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold' }}>
          {translate('common.description')}
        </Typography>
        <ReactMarkdown>{user.description}</ReactMarkdown>
      </CardContent>
    </Card>
  );
};
