import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SocketEvents } from '@boTypes/socket';
import { Roles } from '@boTypes/user';
import { useMutation } from '@hooks/queryWrappers';

import { useSelector } from '../store';
import { ConfirmDialog } from './ConfirmDialog/ConfirmDialog';
import { useGateway } from '../utils/gateway';

export const ActivityPopup = ({ active }: { active: boolean }) => {
  const dispatch = useDispatch();
  const [displayActivityPopup, setDisplayActivityPopup] = useState(false);
  const { firstName } = useSelector((state) => state.user);

  const { mutate } = useMutation<any, any, {}>(['confirmPresence'], () => ({
    method: 'put',
    url: `/api/chatAttribution/active`,
  }));

  const isHealthPro = useSelector((state) =>
    state.user.roles?.includes(Roles.HEALTH_PRO),
  );

  const socket = useGateway();
  useEffect(() => {
    if (socket && isHealthPro) {
      const handler = () => {
        setDisplayActivityPopup(true);
      };
      socket.on(SocketEvents.SHOW_ACTIVITY_POPUP, handler);
      return () => {
        socket.off(SocketEvents.SHOW_ACTIVITY_POPUP, handler);
      };
    }
  }, [dispatch, isHealthPro, socket]);

  return (
    <ConfirmDialog
      open={displayActivityPopup && active}
      text={`${firstName}, es-tu toujours là ?`}
      confirmText="Continuer ma vacation"
      cancelText="Terminer ma vacation"
      onClick={() =>
        mutate(
          {},
          {
            onSuccess: () => {
              setDisplayActivityPopup(false);
            },
          },
        )
      }
      onClose={() => setDisplayActivityPopup(false)}
    />
  );
};
