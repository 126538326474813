import { useTranslate } from 'react-admin';
import { ActivityIndicator, Image, StyleSheet, Text, View } from 'react-native';

import {
  useDailyTipItem,
  useFolderItem,
  useGuideItem,
  useMasterclassItem,
  usePostItem,
  useWeeklyTipItem,
} from '@hooks/cms';
import { useChatFormList } from '@hooks/form-builder';
import { ContentType } from '@teammay/mayssenger';

import {
  FORM_CARD_HEIGHT,
  FormCard,
} from '../../../../entities/form/components/FormCard';
import { COLORS } from '../../../../themes';
import {
  HEIGHT as DT_HEIGHT,
  DailyTipItem,
} from '../../../DailyTip/DailyTipItem';
import {
  HEIGHT as FOLDER_HEIGHT,
  FolderItem,
} from '../../../Folder/FolderItem';
import { HEIGHT as GUIDE_HEIGHT, GuideItem } from '../../../Guide/GuideItem';
import {
  HEIGHT as MC_HEIGHT,
  MasterclassItem,
} from '../../../Masterclass/MasterclassItem';
import { HEIGHT as POST_HEIGHT, PostItem } from '../../../Post/PostItem';
import {
  HEIGHT as WT_HEIGHT,
  WeeklyTipItem,
} from '../../../WeeklyTip/WeeklyTipItem';
const containerStyles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: COLORS.GREEN['300'],
    height: 96,
    width: 50,
    justifyContent: 'center',
  },
});

const Loader = () => (
  <View style={containerStyles.container}>
    <ActivityIndicator color={COLORS.GREEN['800']} size="large" />
  </View>
);
const contentUIStyles = StyleSheet.create({
  container: { flexDirection: 'row', height: 128 },
  content: { flex: 1, marginLeft: 20 },
  image: StyleSheet.absoluteFillObject,
  imageContainer: { width: 96, borderRadius: 10, overflow: 'hidden' },
  infoRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  highlight: { marginBottom: -10 },
  labelContainer: {
    paddingHorizontal: 8,
    borderRadius: 11,
    borderWidth: 1,
    borderColor: '#365150',
  },
  label: {
    fontFamily: 'Poppins-Medium',
    fontSize: 10,
    color: '#365150',
    lineHeight: 20,
  },
  text: {
    marginTop: 10,
    fontSize: 14,
    fontFamily: 'Poppins-Medium',
    color: '#365150',
  },
});
export function ContentCardUI({
  contentType,
  contentId,
  title,
  thumbnail,
}: {
  contentType: ContentType;
  contentId: string;
  title: string;
  thumbnail: string;
}) {
  const translate = useTranslate();
  return (
    <View
      testID={`ContentCard-${contentType}-${contentId}`}
      style={[contentUIStyles.container]}
    >
      <View style={contentUIStyles.imageContainer}>
        <Image
          style={contentUIStyles.image}
          resizeMethod="resize"
          resizeMode="cover"
          source={{
            uri: thumbnail,
          }}
        />
      </View>
      <View style={contentUIStyles.content}>
        <View style={[contentUIStyles.labelContainer]}>
          <Text style={contentUIStyles.label} numberOfLines={1}>
            {translate(`chat.contentTitle.${contentType}`)}
          </Text>
        </View>
        <Text
          style={contentUIStyles.text}
          numberOfLines={2}
          ellipsizeMode={'tail'}
        >
          {title}
        </Text>
      </View>
    </View>
  );
}

export const DailyTipMessage = ({
  lookup,
  onLongPress,
}: {
  lookup: number | string;
  onLongPress?: () => void;
}) => {
  const { data, isLoading } = useDailyTipItem(lookup);

  if (isLoading || !data) {
    return (
      <View style={{ height: DT_HEIGHT }}>
        <Loader />
      </View>
    );
  }
  return <DailyTipItem item={data} onLongPress={onLongPress} />;
};

export const WeeklyTipMessage = ({
  lookup,
  onLongPress,
}: {
  lookup: number | string;
  onLongPress?: () => void;
}) => {
  const { data, isLoading } = useWeeklyTipItem(lookup);

  if (isLoading || !data) {
    return (
      <View style={{ height: WT_HEIGHT }}>
        <Loader />
      </View>
    );
  }
  return <WeeklyTipItem item={data} onLongPress={onLongPress} />;
};

export const PostMessage = ({
  lookup,
  onLongPress,
}: {
  lookup: string;
  onLongPress?: () => void;
}) => {
  const { data, isLoading } = usePostItem(lookup);

  if (isLoading || !data) {
    return (
      <View style={{ height: POST_HEIGHT }}>
        <Loader />
      </View>
    );
  }
  return <PostItem item={data} onLongPress={onLongPress} />;
};

export const MasterclassMessage = ({
  lookup,
  onLongPress,
}: {
  lookup: number | string;
  onLongPress?: () => void;
}) => {
  const { data, isLoading } = useMasterclassItem(lookup);

  if (isLoading || !data) {
    return (
      <View style={{ height: MC_HEIGHT }}>
        <Loader />
      </View>
    );
  }
  return <MasterclassItem item={data} onLongPress={onLongPress} />;
};

export const GuideMessage = ({
  lookup,
  onLongPress,
}: {
  lookup: string;
  onLongPress?: () => void;
}) => {
  const { data, isLoading } = useGuideItem(lookup);

  if (isLoading || !data) {
    return (
      <View style={{ height: GUIDE_HEIGHT }}>
        <Loader />
      </View>
    );
  }
  return <GuideItem item={data} onLongPress={onLongPress} />;
};

export const FolderMessage = ({
  lookup,
  onLongPress,
}: {
  lookup: string;
  onLongPress?: () => void;
}) => {
  const { data, isLoading } = useFolderItem(lookup);

  if (isLoading || !data) {
    return (
      <View style={{ height: FOLDER_HEIGHT }}>
        <Loader />
      </View>
    );
  }
  return <FolderItem item={data} onLongPress={onLongPress} />;
};

export const ContentMessage = ({
  contentType,
  contentId,
  onLongPress,
}: {
  contentType: ContentType;
  contentId: string;
  onLongPress?: () => void;
}) => {
  switch (contentType) {
    case ContentType.dailyTip:
      return <DailyTipMessage lookup={contentId} onLongPress={onLongPress} />;
    case ContentType.weeklyTip:
      return <WeeklyTipMessage lookup={contentId} onLongPress={onLongPress} />;
    case ContentType.article:
      return <PostMessage lookup={contentId} onLongPress={onLongPress} />;
    case ContentType.masterclass:
      return (
        <MasterclassMessage lookup={contentId} onLongPress={onLongPress} />
      );
    case ContentType.guide:
      return <GuideMessage lookup={contentId} onLongPress={onLongPress} />;
    case ContentType.folder:
      return <FolderMessage lookup={contentId} onLongPress={onLongPress} />;
    default:
      return null;
  }
};

export const FormMessage = ({ formId }: { formId: string }) => {
  const { data } = useChatFormList({ enabled: false });
  const form = data?.find((f) => f.id === formId);
  return form ? (
    <FormCard {...form} style={{ height: FORM_CARD_HEIGHT }} />
  ) : (
    <View style={{ height: FORM_CARD_HEIGHT }}>
      <Loader />
    </View>
  );
};
