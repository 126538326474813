import dayjs from 'dayjs';
import { produce, freeze } from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  IconButton,
  List,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Box } from '@mui/system';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimeField } from '@mui/x-date-pickers/TimeField';

import { Loader } from '../../components/Loader';
import { usePlanning, usePlannings, useSendSlots } from '../../hooks/plannings';
import { COLORS } from '../../themes';
import { PlanningStatus } from '../../types/planning';
import { DayjsSlotCreate, DayOfSlots } from '../../types/slot';
import {
  convertDayOfSlotsToSlotsCreate,
  convertSlotsToDayOfSlots,
  copyPreviousPlanningSlots,
  validateDayOfSlot,
} from '../../utils/slotCreateValidation';

const SlotWidth = 220;

// useState with immutable data management. Immer made easy
function useImmer<T>(initialValue: T) {
  const [value, setValue] = useState(freeze(initialValue));

  const updateValue = useCallback((updater: (draft: T) => void) => {
    setValue((current) => produce(current, updater));
  }, []);

  return [value, updateValue] as [T, (updater: (arg: T) => void) => void];
}

const initialDayOfSlot = {
  isTemplate: true,
  slots: [
    {
      start: dayjs().set('minutes', 0),
      end: dayjs().add(1, 'hour').set('minutes', 0),
    },
  ],
};

const SlotInput = React.memo(
  ({
    slot,
    dayIndex,
    slotIndex,
    onChange,
  }: {
    slot: Partial<DayjsSlotCreate>;
    dayIndex?: number;
    slotIndex: number;
    onChange: (
      s: Partial<DayjsSlotCreate>,
      slotIndex: number,
      dayIndex?: number,
    ) => void;
  }) => {
    return (
      <Box
        sx={{
          border: `1px solid ${COLORS.GREY_LAYOUT}`,
          borderRadius: '4px',
          width: SlotWidth,
          m: 0.5,
          p: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 1,
          }}
        >
          <TimeField
            label={'Début'}
            value={slot.start}
            onChange={(newValue) =>
              onChange({ start: newValue }, slotIndex, dayIndex)
            }
            format="HH:mm"
            minutesStep={15}
            variant="standard"
            sx={{ width: 50, minWidth: 50, marginRight: 2 }}
            inputProps={{
              sx: {
                color: COLORS.TEXT,
                fontFamily: 'Poppins, sans-serif',
              },
            }}
          />
          <TimeField
            label={'Fin'}
            value={slot.end}
            onChange={(newValue) =>
              onChange({ end: newValue }, slotIndex, dayIndex)
            }
            format="HH:mm"
            minutesStep={15}
            variant="standard"
            sx={{ width: 50, minWidth: 50 }}
            inputProps={{
              sx: {
                color: COLORS.TEXT,
                fontFamily: 'Poppins, sans-serif',
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography component={'span'} sx={{ fontSize: '0.75rem' }}>
            Astreinte
          </Typography>
          <Checkbox
            checked={Boolean(slot.onCall)}
            onChange={(_, checked) =>
              onChange({ onCall: checked }, slotIndex, dayIndex)
            }
            size="small"
            sx={{ p: 0.5 }}
          />
          <Typography component={'span'} sx={{ fontSize: '0.75rem' }}>
            Prio
          </Typography>
          <Checkbox
            checked={Boolean(slot.isPriority)}
            onChange={(_, checked) =>
              onChange({ isPriority: checked }, slotIndex, dayIndex)
            }
            size="small"
            sx={{ p: 0.5 }}
          />
          <Typography component={'span'} sx={{ fontSize: '0.75rem' }}>
            IAO
          </Typography>
          <Checkbox
            checked={Boolean(slot.isTriage)}
            onChange={(_, checked) =>
              onChange({ isTriage: checked }, slotIndex, dayIndex)
            }
            size="small"
            sx={{ p: 0.5 }}
          />
        </Box>
      </Box>
    );
  },
);

const DaySlotsInput = React.memo(
  ({
    item,
    onChangeSlot,
    onAddSlot,
    onRemoveSlot,
    dayIndex,
  }: {
    item?: DayOfSlots;
    dayIndex: number;
    onChangeSlot?: (
      slot: Partial<DayjsSlotCreate>,
      slotIndex: number,
      dayIndex: number,
    ) => void;
    onAddSlot?: (dayIndex: number) => void;
    onRemoveSlot?: (slotIndex: number, dayIndex: number) => void;
  }) => {
    const errors = validateDayOfSlot(item);
    return (
      <Box sx={{ width: '100%', paddingLeft: 1, paddingRight: 1 }}>
        {item.isTemplate && (
          <Typography>
            {'Modèle' + (item.day ? item.day.format(' dddd') : '')}
          </Typography>
        )}
        {!item.isTemplate && (
          <Box
            sx={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              width: '100%',
            }}
          >
            <Typography
              sx={{
                textTransform: 'capitalize',
                m: 0.5,
                p: 0.5,
                fontWeight: 'bold',
                color: COLORS.GREY_TEXT,
              }}
            >
              {item.day.format('dddd DD MMM YYYY')}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {item.slots.map((slot, index) => (
            <Box sx={{ position: 'relative', m: 0, P: 0 }} key={index}>
              <IconButton
                sx={{ position: 'absolute', top: 2, right: 2 }}
                onClick={() => onRemoveSlot(index, dayIndex)}
              >
                <DeleteIcon sx={{ color: COLORS.GREY_TEXT_LIGHT }} />
              </IconButton>
              <SlotInput
                slot={slot}
                dayIndex={dayIndex}
                slotIndex={index}
                onChange={onChangeSlot}
              />
            </Box>
          ))}
          <IconButton
            onClick={() => onAddSlot(dayIndex)}
            sx={{ width: 30, height: 30 }}
          >
            <AddIcon />
          </IconButton>
        </Box>
        {errors.map((error) => (
          <Typography color={'error'}>{error}</Typography>
        ))}
      </Box>
    );
  },
);

enum InputMode {
  Template = 'Template',
  TemplateWeek = 'TemplateWeek',
  Slots = 'Slots',
}

const useSlotsCallbacks = (setSlots) => {
  const handleSetSlot = useCallback(
    (slot: Partial<DayjsSlotCreate>, slotIndex: number, dayIndex: number) => {
      setSlots((draft) => {
        if (!draft[dayIndex].slots[slotIndex]) {
          draft[dayIndex].slots[slotIndex] = {};
        }
        draft[dayIndex].slots[slotIndex] = Object.assign(
          draft[dayIndex].slots[slotIndex],
          slot,
        );
      });
    },
    [setSlots],
  );

  const handleAddSlot = useCallback(
    (dayIndex: number) => {
      setSlots((draft) => {
        if (!draft[dayIndex].slots) {
          draft[dayIndex].slots = [];
        }
        draft[dayIndex].slots.push({
          start: dayjs().set('minutes', 0),
          end: dayjs().add(1, 'hour').set('minutes', 0),
        });
      });
    },
    [setSlots],
  );

  const handleRemoveSlot = useCallback(
    (slotIndex: number, dayIndex: number) => {
      setSlots((draft) => {
        if (!draft[dayIndex].slots) {
          draft[dayIndex].slots = [];
        }
        draft[dayIndex].slots.splice(slotIndex, 1);
      });
    },
    [setSlots],
  );

  return [handleAddSlot, handleRemoveSlot, handleSetSlot] as [
    (dayIndex: number) => void,
    (dayIndex: number) => void,
    (
      slot: Partial<DayjsSlotCreate>,
      slotIndex: number,
      dayIndex: number,
    ) => void,
  ];
};

export const SlotCreate = () => {
  const { planningId } = useParams();
  const navigate = useNavigate();
  const { data: planning, isLoading } = usePlanning(planningId);
  const [selectedPlanningCopy, setSelectedPlanningCopy] = useState<string>('');
  const { data: allPlannings = [] } = usePlannings(
    {
      status: PlanningStatus.DONE,
      job: planning?.job,
    },
    {
      enabled: !!planning,
    },
  );
  useEffect(() => {
    if (allPlannings.length > 0) {
      setSelectedPlanningCopy(allPlannings[0].id);
    }
  }, [allPlannings]);
  const { data: planningToCopy } = usePlanning(selectedPlanningCopy, {
    enabled: !!selectedPlanningCopy,
  });
  const [inputMode, setInputMode] = useState<InputMode>(InputMode.Template);
  const [[templateSlots], setTemplate] = useImmer<[DayOfSlots]>([
    cloneDeep(initialDayOfSlot),
  ]);
  const [weekTemplateSlots, setWeekTemplate] = useImmer<DayOfSlots[]>(
    Array(7)
      .fill(0)
      .map((_, index) => ({
        ...cloneDeep(initialDayOfSlot),
        day: dayjs().startOf('week').add(index, 'day'),
      })),
  );
  const [allSlots, setSlots] = useImmer<DayOfSlots[]>([] as DayOfSlots[]);

  useEffect(() => {
    if (planning?.status === PlanningStatus.SLOTS_CREATED) {
      setInputMode(InputMode.Slots);
      setSlots(() => convertSlotsToDayOfSlots(planning.slots));
    }
  }, [planning, setSlots]);

  const onCopyPlanning = useCallback(() => {
    if (!planningToCopy) {
      return;
    }
    setSlots(() => copyPreviousPlanningSlots(planningToCopy, planning));
    setInputMode(InputMode.Slots);
  }, [planning, planningToCopy, setSlots]);

  const { mutate } = useSendSlots(planningId);

  const [handleAddSlot, handleRemoveSlot, handleSetSlot] =
    useSlotsCallbacks(setSlots);
  const [
    handleAddTemplateSlot,
    handleRemoveTemplateSlot,
    handleSetTemplateSlot,
  ] = useSlotsCallbacks(setTemplate);
  const [
    handleAddWeekTemplateSlot,
    handleRemoveWeekTemplateSlot,
    handleSetWeekTemplateSlot,
  ] = useSlotsCallbacks(setWeekTemplate);

  // Generate all slots from template
  const onValidateTemplate = () => {
    setSlots(() => {
      if (inputMode === InputMode.Template) {
        return Array(end.diff(begin, 'day') + 1)
          .fill(0)
          .map((_, i) => ({
            isTemplate: false,
            day: begin.clone().add(i, 'day'),
            slots: cloneDeep(templateSlots.slots),
          }));
      } else {
        const templatePerDay = weekTemplateSlots.reduce(
          (acc, template) => ({
            ...acc,
            [template.day.get('day')]: template,
          }),
          {} as Record<number, DayOfSlots>,
        );
        return Array(end.diff(begin, 'day') + 1)
          .fill(0)
          .map((_, i) => {
            const day = begin.clone().add(i, 'day');
            return {
              isTemplate: false,
              day: begin.clone().add(i, 'day'),
              slots: cloneDeep(templatePerDay[day.get('day')].slots),
            };
          });
      }
    });
    setInputMode(InputMode.Slots);
  };

  const begin = useMemo(() => dayjs(planning?.begin), [planning]);
  const end = useMemo(() => dayjs(planning?.end), [planning]);

  if (isLoading) {
    return <Loader />;
  }
  if (
    ![PlanningStatus.DRAFT, PlanningStatus.SLOTS_CREATED].includes(
      planning?.status,
    )
  ) {
    return (
      <Typography variant="h5">
        {'Impossible : les horaires ont déjà été validés'}
      </Typography>
    );
  }

  return (
    <Box sx={{ overflow: 'auto' }}>
      {/* @ts-ignore */}
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
        <Box sx={{ paddingLeft: '1rem', paddingTop: '1rem' }}>
          <Typography color="primary" variant="h5" sx={{ fontWeight: 'bold' }}>
            {'Choisissez les horaires du planning '}
            <Typography
              color="default"
              variant="h5"
              sx={{ fontWeight: 'bold' }}
              component={'span'}
            >{`${planning?.title ?? ''}`}</Typography>
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            alignItems: 'flex-start',
            padding: 1.5,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {inputMode === InputMode.Template && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setInputMode(InputMode.TemplateWeek)}
                disabled={Boolean(validateDayOfSlot(templateSlots).length)}
                sx={{ mr: 1, mb: 1 }}
              >
                Modèle par semaine
              </Button>
            )}
            {inputMode === InputMode.TemplateWeek && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setInputMode(InputMode.Template)}
                disabled={Boolean(validateDayOfSlot(templateSlots).length)}
                sx={{ mr: 1 }}
              >
                Modèle global
              </Button>
            )}
            {inputMode !== InputMode.Slots && Boolean(allPlannings.length) && (
              <>
                <Button
                  sx={{ ml: 2, mb: 1 }}
                  variant="outlined"
                  onClick={onCopyPlanning}
                  disabled={!planningToCopy}
                >
                  Copier le planning
                  <Select<string>
                    value={selectedPlanningCopy}
                    onMouseDown={(event) => event.stopPropagation()}
                    onClick={(event) => event.stopPropagation()}
                    onChange={(event) =>
                      setSelectedPlanningCopy(event.target.value as string)
                    }
                    size="small"
                    variant="outlined"
                    sx={{ ml: 1, fontSize: '0.75rem' }}
                  >
                    {allPlannings.map((plan) => (
                      <MenuItem key={plan.id} value={plan.id}>
                        {plan.title}
                      </MenuItem>
                    ))}
                  </Select>
                </Button>
              </>
            )}
          </Box>
          {inputMode === InputMode.Template && (
            <DaySlotsInput
              item={templateSlots}
              dayIndex={0}
              onChangeSlot={handleSetTemplateSlot}
              onAddSlot={handleAddTemplateSlot}
              onRemoveSlot={handleRemoveTemplateSlot}
            />
          )}
          {inputMode === InputMode.TemplateWeek && (
            <List sx={{ width: '100%' }}>
              {weekTemplateSlots.map((item, index) => {
                return (
                  <DaySlotsInput
                    key={item.day?.format('YYYY-MM-DD') ?? index}
                    item={item}
                    dayIndex={index}
                    onChangeSlot={handleSetWeekTemplateSlot}
                    onAddSlot={handleAddWeekTemplateSlot}
                    onRemoveSlot={handleRemoveWeekTemplateSlot}
                  />
                );
              })}
            </List>
          )}
          {inputMode === InputMode.Slots && (
            <List sx={{ width: '100%' }}>
              {allSlots.map((item, index) => {
                return (
                  <DaySlotsInput
                    key={item.day?.format('YYYY-MM-DD') ?? index}
                    item={item}
                    dayIndex={index}
                    onChangeSlot={handleSetSlot}
                    onAddSlot={handleAddSlot}
                    onRemoveSlot={handleRemoveSlot}
                  />
                );
              })}
            </List>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              p: 2,
            }}
          >
            <Button
              onClick={() =>
                inputMode !== InputMode.Slots
                  ? navigate(`/planning`)
                  : setInputMode(InputMode.Template)
              }
            >
              Annuler
            </Button>
            {inputMode !== InputMode.Slots ? (
              <Button
                variant="contained"
                color="primary"
                onClick={onValidateTemplate}
                disabled={Boolean(validateDayOfSlot(templateSlots).length)}
              >
                Suivant
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  mutate(convertDayOfSlotsToSlotsCreate(allSlots), {
                    onSuccess: () => navigate(`/planning`),
                  });
                }}
                disabled={allSlots.some((day) =>
                  Boolean(validateDayOfSlot(day).length),
                )}
              >
                Valider
              </Button>
            )}
          </Box>
        </Box>
      </LocalizationProvider>
    </Box>
  );
};
