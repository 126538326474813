import { useTranslate } from 'react-admin';
import { Pressable } from 'react-native';

import { useDiscussionDetail } from '@hooks/discussion';
import { useFormAnswers } from '@hooks/form';
import { Box, Chip, CircularProgress, List, Typography } from '@mui/material';
import { Form, FormAnswers } from '@teammay/form-core';
import { getTimeAgo } from '@utils/date';

import EmptyFollowUpList from '../../assets/empty_follow_up';
import { COLORS } from '../../themes';

const EmptyList = () => {
  const translate = useTranslate();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        color: COLORS.GREY_LAYOUT,
      }}
    >
      <EmptyFollowUpList width="120px" />
      <Typography
        sx={{
          textAlign: 'center',
          color: COLORS.GREY_TEXT_LIGHT,
          fontSize: '0.875rem',
          maxWidth: '40%',
        }}
      >
        {translate('forms.patientFile.empty')}
      </Typography>
    </Box>
  );
};

export const FormList = ({
  onFormSelected,
}: {
  onFormSelected?: (arg: {
    form: Pick<Form, 'id'>;
    formAnswers: Pick<FormAnswers, 'id'>;
  }) => void;
}) => {
  const discussion = useDiscussionDetail();
  const { data: answers, isLoading } = useFormAnswers({
    appUserId: discussion?.appUser?.id,
  });

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <List
          sx={{
            flex: 1,
            overflowY: 'auto',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
          }}
          className="scrollbar"
        >
          {answers?.map((answer) => (
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                paddingBottom: '0.25rem',
                paddingTop: '0.25rem',
                overflow: 'hidden',
                alignItems: 'center',
                '&::before': {
                  position: 'relative',
                  content: '""',
                  display: 'inline-block',
                  width: '0.875rem',
                  height: '0.875rem',
                  borderRadius: '50%',
                  backgroundColor: COLORS.GREEN['300'],
                },
              }}
            >
              <Pressable
                // @ts-expect-error hovered not declared in rn types but exists in rn-web
                style={({ pressed, hovered }) => ({
                  cursor: 'pointer',
                  width: 'fit-content',
                  flexDirection: 'row',
                  alignItems: 'center',
                  opacity: pressed ? 0.5 : 1,
                  backgroundColor: hovered ? COLORS.GREY_LAYOUT : 'transparent',
                  borderRadius: '0.5rem',
                })}
                onPress={() => {
                  onFormSelected?.({
                    form: { id: answer.formId },
                    formAnswers: { id: answer.id },
                  });
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight={'600'}
                  minWidth={'50px'}
                  textAlign={'left'}
                >
                  {getTimeAgo(answer.updatedAt)}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    padding: '0.25rem',
                    borderRadius: '2rem',
                  }}
                  fontWeight={'500'}
                >
                  {answer.form?.title ?? ''}
                </Typography>
              </Pressable>
              {answer.score !== null && (
                <Chip label={`score ${answer.score}`} color="primary" />
              )}
              {answer.tags?.map((tag) => <Chip key={tag} label={tag} />)}
            </Box>
          ))}
          {(answers?.length ?? 0) === 0 && <EmptyList />}
        </List>
      )}
    </>
  );
};
