import { Loading } from 'react-admin';

import { Paper } from '@mui/material';

import { OtherActionsConsultation } from './components/OtherActionsConsultation';
import { ShowConsultation } from './components/ShowConsultation';
import { FormConsultation } from './form';
import { ConsultationHeader } from '../../components/subjects/patientHeader';
import { useConsultation } from '../../hooks/consultation';

export const ConsultationEdit = () => {
  const { consultation, relatedDiscussion, loading } = useConsultation();

  if (loading || !consultation) {
    return <Loading />;
  }

  return (
    <Paper sx={{ padding: '1rem' }} elevation={0}>
      <ConsultationHeader consultation={consultation} />
      <ShowConsultation
        relatedDiscussion={relatedDiscussion}
        consultation={consultation}
      />
      <FormConsultation />
      <OtherActionsConsultation consultation={consultation} />
    </Paper>
  );
};
