import { ChipField, useRecordContext } from 'react-admin';

import { TO_DO, CLOSED, CANCELED } from '../../common/constants';
import { COLORS } from '../../themes';
import { Consultation } from '../../types';

const styles = {
  todo: { backgroundColor: COLORS.GREEN['300'] },
  closed: { backgroundColor: COLORS.GREEN['700'], color: 'white' },
  canceled: { backgroundColor: COLORS.PINK['200'] },
};

interface StatusChipFieldProps {
  label?: string;
}

export const consultationStatusStylesMapping = {
  [TO_DO]: styles.todo,
  [CLOSED]: styles.closed,
  [CANCELED]: styles.canceled,
};

export const StatusChipField = ({}: StatusChipFieldProps) => {
  const record = useRecordContext<Consultation>();

  return (
    <ChipField
      sx={consultationStatusStylesMapping[record.status]}
      label="Status"
      source="status"
    />
  );
};
