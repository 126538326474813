import { StyleSheet, View } from 'react-native';

import { Kid } from '@boTypes/kid';

import Calendar from './Calendar';
import Legend, { LegendElement } from './Legend';
import { CalendarDayMap, WeekTuple } from './types';
import { getLastSevenDays } from './utils/getLastSevenDays';
import WeekSelector from './WeekSelector';

const styles = StyleSheet.create({
  container: {
    gap: 12,
    flex: 1,
  },
  row: {
    gap: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
});

interface WeekCalendarProps {
  style?: View['props']['style'];
  selectedChild: Kid;
  data: CalendarDayMap;
  selectedWeek: WeekTuple;
  setSelectedWeek: (week: WeekTuple) => void;
  legend?: Array<LegendElement>;
}

const WeekCalendar = ({
  style,
  selectedChild,
  data,
  selectedWeek,
  setSelectedWeek,
  legend,
}: WeekCalendarProps) => {
  const setNext = () => {
    let nextDay = new Date(selectedWeek[6]);
    nextDay.setDate(nextDay.getDate() + 1);
    const week = getLastSevenDays(nextDay);
    setSelectedWeek(week);
  };
  const setPrevious = () => {
    let previousDay = new Date(selectedWeek[6]);
    previousDay.setDate(previousDay.getDate() - 1);
    const week = getLastSevenDays(previousDay);
    setSelectedWeek(week);
  };

  return (
    <View style={[styles.container, style]}>
      <View style={styles.row}>
        <WeekSelector
          week={selectedWeek}
          onNext={setNext}
          onPrevious={setPrevious}
          disableNext={selectedWeek[6] >= new Date()}
          disablePrevious={selectedWeek[0] <= new Date(selectedChild.birthDay)}
        />
      </View>
      <Calendar week={selectedWeek} data={data} selectedChild={selectedChild} />
      {legend && <Legend elements={legend} />}
    </View>
  );
};

export default WeekCalendar;
