import { AxiosResponse } from 'axios';

import { Planning } from '@boTypes/planning';
import { StaffUserInvoice } from '@boTypes/staffUserInvoice';
import { User } from '@boTypes/user';
import { useQuery } from '@hooks/queryWrappers';
import { GridPaginationModel } from '@mui/x-data-grid';
import { keepPreviousData } from '@tanstack/react-query';
import { apiRequestWithFullResponse } from '@utils/httpClients';

export const useInvoicesAdminList = (
  filters: URLSearchParams,
  paginationModel: GridPaginationModel,
) => {
  return useQuery<
    AxiosResponse<StaffUserInvoice[]>,
    any,
    { data: StaffUserInvoice[]; total: number }
  >(
    ['invoices-admin-list', paginationModel.page, ...filters],
    () => {
      const filter = {} as Record<string, any>;
      if (filters.get('planning')) {
        const planningIds = JSON.parse(filters.get('planning'));
        if (Array.isArray(planningIds) && planningIds.length) {
          filter.planningId = planningIds;
        }
      }
      if (filters.get('staffUser')) {
        const staffUserIds = JSON.parse(filters.get('staffUser'));
        if (Array.isArray(staffUserIds) && staffUserIds.length) {
          filter.staffUserId = staffUserIds;
        }
      }
      if (filters.get('job')) {
        filter.job = JSON.parse(filters.get('job'));
      }
      if (filters.get('validated')) {
        filter.validated = JSON.parse(filters.get('validated'));
      }
      if (filters.get('amountValidated')) {
        filter.amountValidated = JSON.parse(filters.get('amountValidated'));
      }
      return {
        method: 'GET',
        url: '/api/users-invoices/admin',
        params: {
          range: JSON.stringify([
            paginationModel.page * paginationModel.pageSize,
            (paginationModel.page + 1) * paginationModel.pageSize - 1,
          ]),
          filter: JSON.stringify(filter),
        },
        placeholderData: keepPreviousData,
      };
    },
    {
      select: (res) => {
        const contentRangeCount = Number(
          res?.headers['content-range']?.split('/')?.[1] ?? 0,
        );
        return { data: res.data, total: contentRangeCount };
      },
    },
    apiRequestWithFullResponse<StaffUserInvoice[]>,
  );
};

export const usePlanning = () => {
  return useQuery<Planning[], any, { id: string; label: string }[]>(
    ['plannings'],
    () => ({
      method: 'GET',
      url: '/api/planning',
    }),
    {
      select: (res) =>
        res.map((p) => ({
          id: p.id,
          label: p.title,
        })),
    },
  );
};

export const useStaffUsers = () => {
  return useQuery<User[], any, { id: number; label: string }[]>(
    ['users'],
    () => ({
      method: 'GET',
      url: '/api/users',
    }),
    {
      select: (res) => {
        return res.map((u) => ({
          id: u.id,
          label: `${u.firstName} ${u.lastName}`,
        }));
      },
    },
  );
};
