import { ChipField, RecordContext, useRecordContext } from 'react-admin';

import { Company } from '../../types';

interface CompanyPlanFieldProps {
  label?: string;
}

export const CompanyPlanField = ({}: CompanyPlanFieldProps) => {
  const record = useRecordContext<Company>();
  const plans = record.companyPlans || [];
  return (
    <>
      {plans
        .filter((plan) => plan.active)
        .map((plan) => (
          <RecordContext.Provider value={plan}>
            <ChipField source="alias" key={plan.id} />
          </RecordContext.Provider>
        ))}
    </>
  );
};
