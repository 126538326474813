import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  ReferenceField,
  ReferenceInput,
  TextField,
} from 'react-admin';

import { GoToDiscussionField } from '@components/fields/RelatedDiscussionURLField';

import { List } from '../../components/generic/List';

const PregnanciesFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput source="appUser" reference="appUsers" alwaysOn>
      <AutocompleteInput
        variant="standard"
        label="Parent"
        optionText={(record) =>
          record?.name ? `${record.name} (${record.email})` : '---'
        }
      />
    </ReferenceInput>
  </Filter>
);

export const PregnanciesList = () => {
  return (
    <List
      filters={<PregnanciesFilter />}
      sx={{ minHeight: '100%', padding: '0 1rem' }}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <DateField locales="fr-FR" source="expectedEnd" label="Date de terme" />
        <ReferenceField label="Parent" source="appUserId" reference="appUsers">
          <TextField source="email" />
        </ReferenceField>
        <BooleanField source="active" label="Actif" />
        <BooleanField source="interrupted" label="Interrompue" />
        <BooleanField source="born" label="Né·e" />
        <BooleanField source="multiple" label="Multiple" />
        <GoToDiscussionField />
        <EditButton />
      </Datagrid>
    </List>
  );
};
