import { Title, useTranslate } from 'react-admin';

import { Roles } from '@boTypes/user';
import { Grid, Paper, Typography } from '@mui/material';

import { ChangelogCard } from './changelogs';
import { ConsultationsCard } from './consultationsCard';
import { DescriptionCard } from './descriptionCard';
import { FutureSlotsCard } from './futureSlotsCard';
import { InvoicesCard } from './invoices';
import { LatestContentCard } from './latestContentCard';
import { UserCard } from './userCard';
import { useSelector } from '../../store';

export const Dashboard = () => {
  const translate = useTranslate();
  const roles = useSelector((state) => state.user.roles);
  const isPro = roles.includes(Roles.HEALTH_PRO);

  return (
    <Paper
      sx={{
        padding: '1rem',
        zIndex: 2,
      }}
      elevation={0}
    >
      <Title title={translate('title.dashboard')} />
      <Typography
        variant="h5"
        color="primary"
        sx={{ mb: 1, fontWeight: 'bold' }}
      >
        {translate('title.dashboard')}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <UserCard />
        </Grid>
        {isPro && (
          <Grid item xs={12} md={6}>
            <InvoicesCard />
          </Grid>
        )}
        {isPro && (
          <>
            <Grid item xs={12} md={6}>
              <FutureSlotsCard />
            </Grid>
            <Grid item xs={12} md={6}>
              <ConsultationsCard />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={12}>
          <ChangelogCard />
        </Grid>
        <Grid item xs={12} md={12}>
          <LatestContentCard />
        </Grid>
        <Grid item xs={12} md={12}>
          <DescriptionCard />
        </Grid>
      </Grid>
    </Paper>
  );
};
