import { useSelector as useSelectorRaw } from 'react-redux';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import { configureStore } from '@reduxjs/toolkit';

import attributionFiltersReducer from './attributionFilters';
import chatMemoryReducer from './chatMemory';
import discussionReducer from './discussion';
import discussionFiltersReducer from './discussionFilters';
import discussionUsersReducer from './discussionUsers';
import libraryFiltersReducer from './libraryFilters';
import librarySearchReducer from './librarySearch';
import macroReducer from './macroSuggestion';
import reviveSummaryReducer from './reviveSummary';
import timezoneReducer from './timezone';
import userReducer from './user';

const rootReducer = combineReducers({
  attributionFilters: attributionFiltersReducer,
  discussion: discussionReducer,
  user: userReducer,
  libraryFilters: libraryFiltersReducer,
  librarySearch: librarySearchReducer,
  discussionFilters: discussionFiltersReducer,
  discussionUsers: discussionUsersReducer,
  timezone: timezoneReducer,
  macro: macroReducer,
  chatMemory: chatMemoryReducer,
  reviveSummary: reviveSummaryReducer,
});

const resettableAppReducer = (state, action) => rootReducer(state, action);

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: [
    'user',
    'libraryFilters',
    'attributionFilters',
    'discussionFilters',
    'discussionUsers',
    'subjectExplorer',
    'timezone',
  ],
};

const persistedReducer = persistReducer(persistConfig, resettableAppReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export function useSelector<TSelected>(
  selector: (state: RootState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean,
) {
  return useSelectorRaw(selector, equalityFn);
}

const persistor = persistStore(store);

export { store, persistor };
