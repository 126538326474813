import { Create } from 'react-admin';

import { UsersForm } from './form';
import useTransform from '../../hooks/transform';

export const UsersCreate = (props) => {
  const { transform } = useTransform('users', 'avatar');

  return (
    <Create
      {...props}
      resource="users"
      transform={transform}
      redirect="list"
      sx={{ overflow: 'auto' }}
    >
      <UsersForm />
    </Create>
  );
};
