import { Create, CreateProps, useTranslate } from 'react-admin';

import { CategoriesForm } from './form';

export const CategoriesCreate = (props: Omit<CreateProps, 'children'>) => {
  const translate = useTranslate();
  return (
    <Create
      {...props}
      resource="categories"
      title={translate('categories.add')}
      redirect="list"
    >
      <CategoriesForm />
    </Create>
  );
};
