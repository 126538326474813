type ClampArgs =
  | { min: number; max: number }
  | { min?: number; max: number }
  | { min: number; max?: number };
/**
 * Clamps a value within the inclusive `min` and `max` bounds.
 *
 * Both min and max are optional.
 * @example
 * clamp(5, { min: 0, max: 10 }); // 5
 * clamp(-1, { min: 0, max: 10 }); // 0
 * clamp(11, { min: 0, max: 10 }); // 10
 */
export const clamp = (
  value: number,
  { min = -Infinity, max = Infinity }: ClampArgs,
) => {
  return Math.min(Math.max(value, min), max);
};

/**
 * Rounds to nearest value given a specific step
 *
 * step defaults to 1
 *
 * @example
 * round(3.14); // 3
 * round(3.14, 0.2); // 3.2
 * round(3.14, 5); // 5
 */
export const round = (value: number, step = 1) => {
  if (step <= 0) {
    throw new Error('step needs to be a non-zero positive number');
  }
  return Math.round(value / step) * step;
};

/**
 * Floors to nearest value given a specific step
 *
 * step defaults to 1
 * @example
 * floor(3.14); // 3
 * floor(3.14, 0.2); // 3
 * floor(3.14, 5); // 0
 */
export const floor = (value: number, step = 1) => {
  if (step <= 0) {
    throw new Error('step needs to be a non-zero positive number');
  }
  return Math.floor(value / step) * step;
};

/**
 * Ceils to nearest value given a specific step
 *
 * step defaults to 1
 * @example
 * ceil(3.14); // 4
 * ceil(3.14, 0.1); // 3.2
 * ceil(3.14, 5); // 5
 */
export const ceil = (value: number, step = 1) => {
  if (step <= 0) {
    throw new Error('step needs to be a non-zero positive number');
  }
  return Math.ceil(value / step) * step;
};
