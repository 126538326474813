import { useCallback, useMemo } from 'react';
import { Identifier, useGetList } from 'react-admin';
import { useParams } from 'react-router';

import { PlanningAttribution } from '@boTypes/planning';
import { titleToPlanningJob } from '@boTypes/user';
import { CalendarEvent } from '@components/Calendar';

import { displayType } from './adminPlanning';
import {
  useAllAvailabilities,
  usePlanningAdditionalHours,
  usePlanningAttributions,
  usePlanningDetail,
  usePlanningMutations,
} from '../../../hooks/plannings';
import { useByIds } from '../../../hooks/useByIds';
import { User } from '../../../types';
import { stringToColor } from '../../../utils/color';
import { implicatedUsers } from '../../../utils/planningUtils';
import { useUnattSlots } from '../hooks/useUnattributedSlots';
import {
  additionalHoursAppointment,
  availabilityAppointment,
  fullName,
  slotAppointment,
} from '../utils';

export const useAdminPlanningHook = ({
  display,
  date,
}: {
  display: displayType;
  date: Date;
}) => {
  const { planningId } = useParams();
  const {
    data: planning,
    isLoading,
    setCache: setPlanning,
  } = usePlanningDetail(planningId);

  const {
    data: attributions = [],
    isLoading: isLoadingAttributions,
    setCache: setAttributions,
  } = usePlanningAttributions(planningId, {
    enabled: display === displayType.ATTRIBUTION,
  });

  const {
    data: additionalHours = [],
    isLoading: isLoadingAdditionalHours,
    setCache: setAdditionalHours,
  } = usePlanningAdditionalHours(planningId, {
    enabled: display === displayType.ATTRIBUTION,
  });

  const { data: availabilities, isLoading: isLoadingAvailabilities } =
    useAllAvailabilities(planningId);

  const { data: allStaffUsers, isLoading: isLoadingStaffUsers } =
    useGetList<User>('users', { pagination: { page: 1, perPage: 1000 } });
  const staffUsers = useMemo(
    () =>
      allStaffUsers?.filter(
        (user) =>
          user.active && titleToPlanningJob[user.jobTitle] === planning?.job,
      ),
    [allStaffUsers, planning?.job],
  );

  const _staffUsersById = useByIds(staffUsers, String);
  const staffUsersById = useMemo(
    () =>
      Object.entries(_staffUsersById).reduce((acc, [key, value]) => {
        acc[key] = {
          ...value,
          color: stringToColor(value.fullName),
          hasSeen: Boolean(
            planning?.trackers?.find(
              (tracker) => tracker.staffUserId === value.id,
            ),
          ),
        };
        return acc;
      }, {}),
    [_staffUsersById, planning?.trackers],
  );
  const allStaffUsersById = useByIds(allStaffUsers, String);

  const attributedSlots = useMemo(
    () =>
      attributions?.reduce(
        (acc, { slotId }) => ({ ...acc, [slotId]: true }),
        {} as Record<string, boolean>,
      ) ?? {},
    [attributions],
  );
  const slotsById = useByIds(planning?.slots);
  const unattributedSlots = useUnattSlots(planning?.slots, attributedSlots);

  const participatingUsers = useMemo(
    () =>
      implicatedUsers({
        planning,
        staffUsers,
        attributions,
        availabilities,
      }),
    [attributions, availabilities, planning, staffUsers],
  );

  const slotsWithAvailabilities = useMemo(
    () =>
      availabilities
        ?.flatMap((availability) => availability.slots)
        .reduce(
          (acc, slot) => ({ ...acc, [String(slot)]: true }),
          {} as Record<string, boolean>,
        ) ?? {},
    [availabilities],
  );
  const slotsWithoutAvailabilities = useMemo(() => {
    return (
      planning?.slots?.filter(
        (slot) => !slotsWithAvailabilities[String(slot.id)],
      ) ?? []
    );
  }, [planning?.slots, slotsWithAvailabilities]);

  // @ts-ignore
  const agendaData = useMemo<CalendarEvent[]>(() => {
    if (!allStaffUsersById || Object.keys(allStaffUsersById).length === 0) {
      return [];
    }
    return display === displayType.ATTRIBUTION
      ? attributions
          .filter(({ slotId }) => slotsById[slotId])
          .map(({ staffUserId, slotId, onCallActivated }) => ({
            ...slotAppointment(
              slotsById[slotId],
              staffUserId,
              onCallActivated,
              allStaffUsersById,
              planning.job,
            ),
            otherAvailable:
              availabilities
                ?.filter(
                  ({ slots, values }) =>
                    slots.filter(
                      (slot, index) => slot === slotId && values[index] > 0.1,
                    ).length,
                )
                .map(({ staffUserId: attStaff }) =>
                  fullName(staffUsersById[attStaff]),
                ) ?? [],
          }))
          .concat(
            unattributedSlots.map((slot) => ({
              ...slotAppointment(
                slot,
                undefined,
                false,
                staffUsersById,
                planning.job,
              ),
              otherAvailable:
                availabilities
                  ?.filter(
                    ({ slots, values }) =>
                      slots.filter(
                        (sloti, index) =>
                          sloti === slot.id && values[index] > 0.1,
                      ).length,
                  )
                  .map(({ staffUserId: attStaff }) =>
                    fullName(staffUsersById[attStaff]),
                  ) ?? [],
            })),
          )
          .concat(
            additionalHoursAppointment(additionalHours, allStaffUsersById).map(
              (appointment) => ({ ...appointment, otherAvailable: [] }),
            ),
          )
      : (availabilities
          ?.flatMap(({ staffUserId, slots }) =>
            slots.map((slotId) =>
              availabilityAppointment(
                slotsById[slotId],
                staffUserId,
                staffUsersById,
                planning.job,
              ),
            ),
          )
          .concat(
            slotsWithoutAvailabilities.map((slot) =>
              availabilityAppointment(
                slot,
                undefined,
                staffUsersById,
                planning.job,
              ),
            ),
          ) ?? []);
  }, [
    additionalHours,
    attributions,
    availabilities,
    display,
    slotsById,
    slotsWithoutAvailabilities,
    staffUsersById,
    unattributedSlots,
    planning,
    allStaffUsersById,
  ]);

  const {
    deleteSlot,
    createSlot,
    updateSlot,
    createAttribution,
    deleteAttribution,
    deleteAdditionalHour,
    updateAdditionalHour,
  } = usePlanningMutations(planningId);

  const handleSlotDeleteAttribution = useCallback(
    (id: number) => {
      deleteSlot(String(id), {
        onSuccess(_, slotId) {
          setPlanning((previousPlanning) =>
            previousPlanning
              ? {
                  ...previousPlanning,
                  slots: previousPlanning.slots.filter(
                    (slot) => String(slot.id) !== String(slotId),
                  ),
                }
              : previousPlanning,
          );
        },
      });
    },
    [deleteSlot, setPlanning],
  );

  const handleSlotDeleteAdditionalHours = useCallback(
    (id: number) => {
      deleteAdditionalHour(id, {
        onSuccess() {
          setAdditionalHours((previousAdditionalHours) =>
            previousAdditionalHours?.filter(
              (hour) => String(hour.id) !== String(id),
            ),
          );
        },
      });
    },
    [setAdditionalHours, deleteAdditionalHour],
  );

  const handleSlotDelete = useCallback(
    (id: number | string) => {
      if (typeof id === 'string' && id.startsWith('h-')) {
        const hourId = Number(id.slice(2));
        handleSlotDeleteAdditionalHours(hourId);
      } else {
        handleSlotDeleteAttribution(Number(id));
      }
    },
    [handleSlotDeleteAttribution, handleSlotDeleteAdditionalHours],
  );

  const attributionsBySlotId = useMemo(
    () =>
      attributions?.reduce(
        (acc, attr) => ({ ...acc, [attr.slotId]: attr }),
        {} as Record<string, PlanningAttribution>,
      ) ?? {},
    [attributions],
  );

  const handleDeleteAttribution = useCallback(
    (slotId: Identifier) => {
      const attr = attributionsBySlotId[slotId];

      deleteAttribution(
        {
          slotId: Number(slotId),
          staffUserId: attr?.staffUserId,
        },
        {
          onSuccess() {
            setAttributions((previousAttributions) =>
              previousAttributions?.filter(
                (att) => String(att.slotId) !== String(slotId),
              ),
            );
          },
        },
      );
    },
    [deleteAttribution, setAttributions, attributionsBySlotId],
  );

  const handleCreateAttribution = useCallback(
    (attr: {
      slotId: number;
      staffUserId: number;
      onCallActivated?: boolean;
    }) => {
      createAttribution(attr, {
        onSuccess(attribution) {
          setAttributions((previousAttributions) => {
            const next =
              previousAttributions?.filter(
                (att) => att.slotId !== attr.slotId,
              ) ?? [];
            next.push(attribution);
            return next;
          });
        },
      });
    },
    [createAttribution, setAttributions],
  );

  const handleSlotUpdateAttribution = useCallback(
    (_slot: CalendarEvent) => {
      const slotUpdate = {
        slotId: Number(_slot.id),
        start: _slot.start,
        end: _slot.end,
        onCall: _slot.onCall,
        isTriage: _slot.isTriage,
      };

      updateSlot(slotUpdate, {
        onSuccess(updatedSlot) {
          setPlanning((previousPlanning) =>
            previousPlanning
              ? {
                  ...previousPlanning,
                  slots: previousPlanning.slots.map((s) =>
                    String(_slot.id) === String(s.id) ? updatedSlot : s,
                  ),
                }
              : previousPlanning,
          );
          if (
            _slot.staffUserId ||
            typeof _slot.onCallActivated !== 'undefined'
          ) {
            const onCallActivated = _slot.onCall && _slot.onCallActivated;
            handleCreateAttribution({
              slotId: Number(_slot.id),
              staffUserId: _slot.staffUserId,
              onCallActivated,
            });
          }
        },
      });
    },
    [updateSlot, handleCreateAttribution, setPlanning],
  );

  const handleSlotUpdateAdditionalHours = useCallback(
    (slot: CalendarEvent) => {
      const hourId = Number((slot.id as string).slice(2));
      updateAdditionalHour(
        {
          id: hourId,
          start: slot.start,
          end: slot.end,
          staffUserId: slot.staffUserId,
        },
        {
          onSuccess(updatedHour) {
            setAdditionalHours((previousAdditionalHours) =>
              previousAdditionalHours
                ? previousAdditionalHours.map((hour) =>
                    hour.id === hourId ? updatedHour : hour,
                  )
                : previousAdditionalHours,
            );
          },
        },
      );
    },
    [setAdditionalHours, updateAdditionalHour],
  );

  const handleSlotUpdate = useCallback(
    (slot: CalendarEvent) => {
      if (typeof slot.id === 'string' && slot.id.startsWith('h-')) {
        handleSlotUpdateAdditionalHours(slot);
      } else {
        handleSlotUpdateAttribution(slot);
      }
    },
    [handleSlotUpdateAdditionalHours, handleSlotUpdateAttribution],
  );

  const callbackActions = useMemo(() => {
    return {
      create: (_slot: CalendarEvent) => {
        const slotCreate = {
          start: _slot.start,
          end: _slot.end,
          onCall: Boolean(_slot.onCall),
          isTriage: Boolean(_slot.isTriage),
        };
        createSlot(slotCreate, {
          onSuccess(slot) {
            setPlanning((previousPlanning) =>
              previousPlanning
                ? {
                    ...previousPlanning,
                    slots: [...previousPlanning.slots, slot],
                  }
                : previousPlanning,
            );
            if (_slot.staffUserId) {
              handleCreateAttribution({
                slotId: slot.id,
                staffUserId: _slot.staffUserId,
                onCallActivated: slot.onCall && _slot.onCallActivated,
              });
            }
          },
        });
      },
      update: (slot: CalendarEvent) => {
        handleSlotUpdate(slot);
      },
      remove: (slot: CalendarEvent) => {
        handleSlotDelete(slot.id);
      },
    };
  }, [
    createSlot,
    handleCreateAttribution,
    handleSlotDelete,
    handleSlotUpdate,
    setPlanning,
  ]);

  const returnTrue = useCallback(() => true, []);

  return {
    agendaData,
    staffUsersById,
    participatingUsers,
    callbackActions,
    displayActions: {
      update: returnTrue,
      delete: returnTrue,
      create: returnTrue,
    },
    allLoading:
      isLoading ||
      isLoadingAttributions ||
      isLoadingAvailabilities ||
      isLoadingStaffUsers ||
      isLoadingAdditionalHours ||
      !planning ||
      !staffUsers ||
      !date,
    job: planning?.job,
    callbackExtraActions: {
      removeAttribution: handleDeleteAttribution,
    },
  };
};
