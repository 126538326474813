import { useTranslate } from 'react-admin';
import { StyleSheet, View } from 'react-native';

import { FEED_TYPE } from '@components/tools/FeedingAndSleep/dto';
import { Box, Tooltip } from '@mui/material';

import { TILE_HEIGHT } from './constants';
import { COLORS } from '../../../../themes';
import { Entry } from '../types';

const styles = StyleSheet.create({
  outer: {
    backgroundColor: COLORS.GREEN[300],
    borderRadius: 4,
    height: TILE_HEIGHT,
    overflow: 'hidden',
  },
  inner: { position: 'absolute', left: 0, right: 0 },
});

const ConditionalTooltip = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactElement;
}) => {
  if (title) {
    return (
      <Tooltip title={<Box sx={{ whiteSpace: 'pre-line' }}>{title}</Box>}>
        <Box>{children}</Box>
      </Tooltip>
    );
  } else {
    return children;
  }
};

interface TileProps {
  entries: Array<Entry> | undefined;
  width: number;
}
const Tile = ({ width, entries }: TileProps) => {
  const translate = useTranslate();
  return (
    <View style={[styles.outer, { width }]}>
      {Boolean(entries?.length) &&
        entries!.map((entry, index) => {
          // check if entry.type is in FEED_TYPE
          let tooltip = '';
          if (entry.type in FEED_TYPE) {
            if (entry.type === 'BREAST') {
              tooltip = translate('tools.feedingAndSleep.tooltip.duration', {
                duration: entry.duration,
              });
            } else if (entry.type === 'BOTTLE') {
              tooltip = translate('tools.feedingAndSleep.tooltip.quantity', {
                quantity: entry.quantity,
              });
            }
          }

          if (entry.comment) {
            tooltip += tooltip ? `\n${entry.comment}` : entry.comment;
          }

          return (
            <ConditionalTooltip title={tooltip} key={index}>
              <View
                key={index}
                style={[
                  styles.inner,
                  {
                    backgroundColor:
                      entry.type === 'BREAST' || entry.type === 'BOTTLE'
                        ? undefined
                        : (entry.color ?? COLORS.GREEN[800]),
                    top: entry.topPercentage * TILE_HEIGHT,
                    bottom: entry.bottomPercentage * TILE_HEIGHT,
                    // Create a striped background for breast/bottle feeds
                    // @ts-ignore
                    background:
                      entry.type === 'BREAST' || entry.type === 'BOTTLE'
                        ? `repeating-linear-gradient(
                45deg,
                ${entry.color ?? COLORS.GREEN[800]} 0px,
                ${entry.color ?? COLORS.GREEN[800]} 3px,
                transparent 3px,
                transparent 6px
              )`
                        : undefined,
                  },
                ]}
              />
            </ConditionalTooltip>
          );
        })}
    </View>
  );
};

export default Tile;
