import { produce } from 'immer';
import _clone from 'lodash/clone';
import _isEqual from 'lodash/isEqual';
import _merge from 'lodash/merge';
import merge from 'lodash/merge';
import React, { createContext, useContext, useMemo } from 'react';
import { Identifier } from 'react-admin';

import { HandoverRevive } from '@boTypes/handover';
import { QueryClient, useQuery, useQueryClient } from '@hooks/queryWrappers';

import { historyQueryReviveUpdate } from './history';
import { useSocketHandlers } from './useSocketHandlers';
import { Discussion, DiscussionEvent, Handover, Subject } from '../types';
import { Socket, SocketEvents, SocketHandlerGenerator } from '../types/socket';
import {
  addHandoverInDiscussion,
  addHandoverReviveInDiscussion,
  deleteHandoverInDiscussion,
  deleteHandoverReviveInDiscussion,
  mergeHandoverInDiscussion,
} from '../utils/handovers';

const DiscussionDetailContext = createContext<
  Discussion & {
    updateSubject: (arg0: { subject: Subject }) => void;
    createHandover: (arg0: {
      handover: Handover;
      subjectId: Identifier;
    }) => void;
    updateHandover: (handover: Handover) => void;
    deleteHandover: (handover: Handover) => void;
    updateHandoverRevive: (arg: { revive: HandoverRevive }) => void;
    addDiscussionEvent: (arg: { event: DiscussionEvent }) => void;
  }
>(null);

export const discussionKey = (discussionId: Identifier) => [
  'discussionDetail',
  `${discussionId}`,
];

const updateSubjectInDiscussion = produce(
  (discussion: Discussion, newSubject: Subject) => {
    if (newSubject.id !== discussion?.lastSubject.id) {
      return discussion;
    }
    _merge(discussion.lastSubject, newSubject);
  },
);

const updateDiscussion = produce(
  (discussion: Discussion, newDiscussion: Discussion) => {
    if (discussion) {
      merge(discussion, newDiscussion);
    } else {
      return newDiscussion;
    }
  },
);

const updateSubjectGenerator =
  (queryClient: QueryClient, discussionId: Identifier) =>
  ({ subject: newSubject }: { subject: Subject }) => {
    queryClient.setQueryData<Discussion>(
      discussionKey(discussionId),
      (discussion?: Discussion) =>
        discussion
          ? updateSubjectInDiscussion(discussion, newSubject)
          : undefined,
    );
  };

const updateHandoverGenerator =
  (queryClient: QueryClient, discussionId: Identifier) =>
  (handover: Handover) => {
    queryClient.setQueryData<Discussion>(
      discussionKey(discussionId),
      (discussion?: Discussion) =>
        discussion
          ? mergeHandoverInDiscussion(discussion, handover)
          : undefined,
    );
  };

const deleteHandoverGenerator =
  (queryClient: QueryClient, discussionId: Identifier) =>
  (handover: Handover) => {
    queryClient.setQueryData<Discussion>(
      discussionKey(discussionId),
      (discussion?: Discussion) =>
        discussion
          ? deleteHandoverInDiscussion(discussion, handover)
          : undefined,
    );
  };

const addOrUpdateHandoverReviveGenerator =
  (queryClient: QueryClient, discussionId: Identifier) =>
  ({ revive }: { revive: HandoverRevive }) => {
    queryClient.setQueriesData<Discussion>(
      { queryKey: discussionKey(discussionId) },
      (discussion?: Discussion) =>
        discussion
          ? addHandoverReviveInDiscussion(discussion, revive)
          : undefined,
    );
    historyQueryReviveUpdate(queryClient, revive);
  };

const createHandoverGenerator =
  (queryClient: QueryClient, discussionId: Identifier) =>
  ({ handover, subjectId }: { handover: Handover; subjectId: Identifier }) => {
    queryClient.setQueryData<Discussion>(
      discussionKey(discussionId),
      (discussion?: Discussion) =>
        discussion
          ? addHandoverInDiscussion(discussion, handover, subjectId)
          : undefined,
    );
  };

const deleteHandoverReviveGenerator =
  (queryClient: QueryClient, discussionId: Identifier) =>
  ({
    reviveId,
    discussionId: reviveDiscussionId,
  }: {
    reviveId: HandoverRevive['id'];
    discussionId: Identifier;
  }) => {
    if (discussionId !== reviveDiscussionId) {
      return;
    }
    queryClient.setQueriesData<Discussion>(
      { queryKey: discussionKey(discussionId) },
      (discussion?: Discussion) =>
        discussion
          ? deleteHandoverReviveInDiscussion(discussion, reviveId)
          : undefined,
    );
  };

const addDiscussionEventGenerator =
  (queryClient: QueryClient, discussionId: Identifier) =>
  ({
    event,
    discussion,
  }: {
    event: DiscussionEvent;
    discussion?: Discussion;
  }) => {
    if (String(discussionId) === String(discussion?.id)) {
      queryClient.setQueryData<Discussion>(
        discussionKey(discussionId),
        (previousDiscussion?: Discussion) =>
          updateDiscussion(previousDiscussion, discussion),
      );
    } else if (String(event.discussionId) === String(discussionId)) {
      queryClient.setQueriesData<Discussion>(
        { queryKey: discussionKey(discussionId) },
        (previousDiscussion?: Discussion) => {
          if (previousDiscussion) {
            return updateDiscussion(previousDiscussion, {
              // @ts-ignore
              lastSubject: { empty: false },
              lastEvent: event,
            });
          }
        },
      );
    }
  };

export const useDiscussionDetail = () => {
  return useContext(DiscussionDetailContext);
};

const socketMessageHandlerGenerator: SocketHandlerGenerator<
  [QueryClient, Identifier]
> = {
  [SocketEvents.CLOSED_SUBJECT]:
    (queryClient: QueryClient, discussionId: Identifier) =>
    (discussion: Discussion) => {
      if (discussion.id !== discussionId) {
        return;
      }
      queryClient.setQueryData<Discussion>(
        discussionKey(discussionId),
        (previousDiscussion?: Discussion) =>
          updateDiscussion(previousDiscussion, discussion),
      );
    },
  [SocketEvents.UPDATE_SUBJECT]: updateSubjectGenerator,
  [SocketEvents.NEW_HANDOVER]: createHandoverGenerator,
  [SocketEvents.UPDATE_HANDOVER]: updateHandoverGenerator,
  [SocketEvents.NEW_HANDOVER_REVIVE]: addOrUpdateHandoverReviveGenerator,
  [SocketEvents.UPDATE_HANDOVER_REVIVE]: addOrUpdateHandoverReviveGenerator,
  [SocketEvents.CLOSE_HANDOVER_REVIVE]: addOrUpdateHandoverReviveGenerator,
  [SocketEvents.DELETE_HANDOVER_REVIVE]: deleteHandoverReviveGenerator,
  [SocketEvents.NEW_DISCUSSION_EVENT]: addDiscussionEventGenerator,
};

const useDiscussionDetailQuery = (
  socket: Socket,
  discussionId: Identifier,
  queryClient: QueryClient,
) => {
  useSocketHandlers(
    socket,
    socketMessageHandlerGenerator,
    queryClient,
    discussionId,
  );
  return useQuery<Discussion>(
    discussionKey(discussionId),
    () => ({
      url: `/api/discussions/${discussionId}`,
      method: 'get',
    }),
    { enabled: Boolean(discussionId) },
  );
};

export const DiscussionDetailProvider = ({
  children,
  socket,
  discussionId,
}: {
  children: React.ReactNode;
  discussionId: Identifier;
  socket: Socket;
}) => {
  const queryClient = useQueryClient();
  const { data } = useDiscussionDetailQuery(socket, discussionId, queryClient);
  const handlers = useMemo(
    () => ({
      updateSubject: updateSubjectGenerator(queryClient, discussionId),
      createHandover: createHandoverGenerator(queryClient, discussionId),
      updateHandover: updateHandoverGenerator(queryClient, discussionId),
      deleteHandover: deleteHandoverGenerator(queryClient, discussionId),
      updateHandoverRevive: addOrUpdateHandoverReviveGenerator(
        queryClient,
        discussionId,
      ),
      addDiscussionEvent: addDiscussionEventGenerator(
        queryClient,
        discussionId,
      ),
    }),
    [discussionId, queryClient],
  );
  const discussionDetail = useMemo(() => {
    return {
      ...data,
      ...handlers,
    };
  }, [data, handlers]);
  return (
    <DiscussionDetailContext.Provider value={discussionDetail}>
      {children}
    </DiscussionDetailContext.Provider>
  );
};
