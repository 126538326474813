import { AxiosError } from 'axios';

import { Consultation } from '@boTypes/consultation';
import { DailyTip } from '@boTypes/dailyTip';
import Folder from '@boTypes/folder';
import { Guide } from '@boTypes/guide';
import { Macro } from '@boTypes/macroType';
import { Masterclass } from '@boTypes/masterclass';
import {
  Planning,
  PlanningAttributionSlot,
  UserInvoiceData,
} from '@boTypes/planning';
import { Post } from '@boTypes/post';
import { StaffUser } from '@boTypes/staffUser';
import { StaffUserInvoice } from '@boTypes/staffUserInvoice';
import {
  UseQueryOptions,
  useCMSQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@hooks/queryWrappers';

import { MINE } from '../../common/constants';

export const useConsultations = () => {
  const { data: consultations, isLoading } = useQuery<Consultation[]>(
    ['consultations'],
    () => ({
      method: 'GET',
      url: '/api/consultations',
      params: {
        sort: JSON.stringify(['start', 'ASC']),
        filter: JSON.stringify({
          status: MINE,
          futureOnly: true,
        }),
      },
    }),
  );

  return { consultations, isLoading };
};

export const useFutureSlots = () => {
  const { data: slots, isLoading } = useQuery<PlanningAttributionSlot[]>(
    ['futureSlots'],
    () => ({
      method: 'GET',
      url: '/api/planning/attributions/future',
    }),
  );
  return { slots, isLoading };
};

export const useLatestContent = () =>
  useCMSQuery<{
    posts: Post[];
    guides: Guide[];
    programs: Folder[];
    dailyTips: DailyTip[];
    masterclasses: Masterclass[];
    macros: Macro[];
  }>(['latestContent'], () => ({
    method: 'GET',
    url: '/latest-content',
  }));
export const useInvoices = (
  options: { enabled: boolean } = { enabled: true },
) => {
  const { data: invoices, isLoading } = useQuery<StaffUserInvoice[]>(
    ['invoices'],
    () => ({
      method: 'GET',
      url: '/api/users-invoices',
      ...options,
    }),
  );
  return { invoices, isLoading };
};

export const useLatestPlannings = () => {
  const { data: plannings, isLoading } = useQuery<
    (Planning & { withActivity: boolean })[]
  >(['latestPlannings'], () => ({
    method: 'GET',
    url: '/api/planning/forInvoices',
  }));
  return { plannings, isLoading };
};

export const useDeleteInvoice = () => {
  const queryClient = useQueryClient();

  const { mutate: deleteInvoice, isPending } = useMutation<
    StaffUserInvoice,
    unknown,
    StaffUserInvoice['id']
  >(
    ['invoices'],
    (id: string) => ({
      method: 'DELETE',
      url: `/api/users-invoices/${id}`,
    }),
    {
      onSuccess: (_: StaffUserInvoice, variables) => {
        queryClient.setQueryData<StaffUserInvoice[]>(
          ['invoices'],
          (oldData: StaffUserInvoice[]) => {
            return oldData?.filter((invoice) => {
              return invoice.id !== variables;
            });
          },
        );
      },
    },
  );
  return { deleteInvoice, isDeleting: isPending };
};

export const usePlanningSummary = (
  planningId?: Planning['id'],
  staffUserId?: StaffUser['id'],
  options: Omit<
    UseQueryOptions<
      {
        invoiceData: UserInvoiceData;
        planning: Planning;
        staffUser: StaffUser;
      },
      AxiosError
    >,
    'queryKey' | 'queryFn'
  > = {},
) => {
  const { data: summary, isLoading } = useQuery<
    { invoiceData: UserInvoiceData; planning: Planning; staffUser: StaffUser },
    AxiosError
  >(
    ['invoicesSummary', planningId, staffUserId],
    () => ({
      url: `/api/billing/invoices/${planningId}/forUser/${staffUserId}`,
      method: 'get',
    }),
    {
      enabled: !!options.enabled && !!planningId && !!staffUserId,
    },
  );

  return { summary, isLoadingSummary: isLoading };
};
