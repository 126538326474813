export enum FEED_TYPE {
  BREAST = 'BREAST',
  BOTTLE = 'BOTTLE',
}

export enum SLEEP_TYPE {
  NAP = 'NAP',
  NIGHT = 'NIGHT',
}

export class ChildFeedingDTO {
  id: number;
  type: FEED_TYPE;
  leftBreastDurationSecond: number;
  rightBreastDurationSecond: number;
  bottleMilliliter: number;
  date: Date;
  comment?: string;
}

export class ChildSleepDTO {
  id: number;
  type: SLEEP_TYPE;
  start: Date;
  end: Date;
  comment?: string;
}
