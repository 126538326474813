import React, { useCallback } from 'react';
import ReactMarkdown from 'react-markdown';

import { DiscussionEventType } from '@boTypes/discussionEvent';
import { WeeklyTip } from '@boTypes/weeklyTip';
import {
  CMSCard,
  CMSCardActionArea,
  CMSCardContent,
  CMSCardSentOverlay,
  CMSCardTitle,
} from '@components/cmsCard';
import { useHandleLongPress } from '@hooks/useHandleLongPress';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import { CardActions, Dialog, DialogContent } from '@mui/material';

import { logContentView } from '../../analytics/events';
import { COLORS } from '../../themes';
import { SendIcon } from '../discussions/SendIcon';
import { DialogTitle } from '../generic/Dialog';

export const WIDTH = 148;
export const HEIGHT = 120;
const TITLE_LINE_NB = 5;

export const WeeklyTipItemDialog = ({
  item,
  open,
  close,
}: {
  item: WeeklyTip;
  open: boolean;
  close: () => void;
}) => {
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby={item.title}
      aria-describedby={item.title}
      maxWidth="md"
    >
      <DialogTitle onClose={close}>{item.title}</DialogTitle>
      <DialogContent>
        <ReactMarkdown>{item.content}</ReactMarkdown>
      </DialogContent>
    </Dialog>
  );
};

export const WeeklyTipItem = ({
  item,
  onSend,
  onLongPress,
}: {
  item: WeeklyTip;
  onSend?: (
    {
      type,
      content,
    }: {
      type: DiscussionEventType;
      content: string;
    },
    contentType: string,
    contentId: number,
  ) => void;
  onLongPress?: () => void;
}) => {
  const { title } = item;
  const [open, setOpen] = React.useState(false);
  const onClick = useCallback(() => {
    logContentView('weeklyTip', item.id);
    setOpen(true);
  }, [setOpen, item.id]);
  const close = useCallback(() => setOpen(false), [setOpen]);
  const weeklyTipInfo = {
    type: DiscussionEventType.WEEKLY_TIP,
    content: String(item.entryId || item.id),
  };
  const [handlePress, startClick] = useHandleLongPress(onClick, onLongPress);
  return (
    <CMSCard
      width={WIDTH}
      height={HEIGHT}
      sx={{ backgroundColor: COLORS.PINK['100'] }}
      elevation={0}
    >
      {open ? (
        <WeeklyTipItemDialog item={item} open={open} close={close} />
      ) : null}

      <CMSCardActionArea
        onClick={handlePress}
        onMouseDown={startClick}
        height={HEIGHT}
        sx={{ justifyContent: 'center', alignItems: 'center' }}
      >
        {item.sent && <CMSCardSentOverlay />}
        <CMSCardContent paddingVertical={0}>
          <CMSCardTitle
            variant="body2"
            component="h2"
            clamp={TITLE_LINE_NB}
            sx={{ color: COLORS.TEXT }}
          >
            {title}
          </CMSCardTitle>
        </CMSCardContent>
        {item.sent && (
          <DownloadDoneIcon
            sx={(theme) => ({
              position: 'absolute',
              left: theme.spacing(1),
              bottom: theme.spacing(0),
            })}
          />
        )}
      </CMSCardActionArea>
      {onSend ? (
        <CardActions sx={{ padding: 0 }}>
          <SendIcon
            onClick={() =>
              onSend(weeklyTipInfo, DiscussionEventType.WEEKLY_TIP, item.id)
            }
          />
        </CardActions>
      ) : null}
    </CMSCard>
  );
};
