import {
  BooleanField,
  ChipField,
  Datagrid,
  ListProps,
  NumberField,
  TextField,
  useTranslate,
} from 'react-admin';

import { List } from '../../components/generic/List';

export const CategoriesList = (props: ListProps) => {
  const translate = useTranslate();
  return (
    <List {...props} title={translate('categories.title')}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name" label={translate('categories.name')} />
        <TextField source="slug" label={translate('categories.slug')} />
        <BooleanField
          source="pregnancy"
          label={translate('categories.pregnancy')}
        />
        <BooleanField source="active" label={translate('categories.active')} />
        <ChipField source="metaCategory" label={translate('categories.meta')} />
        <NumberField
          source="priorityOrder"
          label={translate('categories.priority')}
        />
      </Datagrid>
    </List>
  );
};
