import dayjs from 'dayjs';

import {
  dayjsTz,
  floorToNearestValue,
  formatTZ,
  getSpannedAcrossHours,
  roundToNearestValue,
} from '@utils/date';

import { BOTTLE_COLOR, BREAST_COLOR } from './constants';
import { ChildFeedingDTO, ChildSleepDTO } from './dto';
import { CalendarDayMap, DayData } from '../WeekCalendar/types';

const BREASTFEED_ROUNDING_MS = 5 * 60 * 1000; // 5 minutes

export const computeBreastfeedDuration = (measure: ChildFeedingDTO) => {
  return (
    ((measure.leftBreastDurationSecond ?? 0) +
      (measure.rightBreastDurationSecond ?? 0)) /
    60
  );
};

export const transformBreastfeed = (
  acc: CalendarDayMap,
  measure: ChildFeedingDTO,
): CalendarDayMap => {
  const start = roundToNearestValue(
    new Date(measure.date),
    BREASTFEED_ROUNDING_MS,
  );

  const totalDurationSeconds =
    measure.leftBreastDurationSecond + measure.rightBreastDurationSecond;
  const roundedDurationMilliseconds = Math.max(
    Math.round((totalDurationSeconds * 1000) / BREASTFEED_ROUNDING_MS) *
      BREASTFEED_ROUNDING_MS,
    BREASTFEED_ROUNDING_MS,
  );

  const end = new Date(start.getTime() + roundedDurationMilliseconds);

  const topPercentageOffset = parseFloat((start.getMinutes() / 60).toFixed(2));

  const endMinute = end.getMinutes() === 0 ? 60 : end.getMinutes();
  const bottomPercentageOffset = parseFloat((1 - endMinute / 60).toFixed(2));

  const tilesToFill = getSpannedAcrossHours({ start, end });
  let hourCount = start;
  Array.from({ length: tilesToFill }).forEach((_, i) => {
    const dayKey = formatTZ(hourCount, 'YYYY-MM-DD');
    const hourKey = hourCount.getHours().toString();

    acc[dayKey] ??= {} as DayData;
    acc[dayKey][hourKey] ??= [];

    const firstTile = i === 0;
    const lastTile = i === tilesToFill - 1;
    acc[dayKey][hourKey].push({
      topPercentage: firstTile ? topPercentageOffset : 0,
      bottomPercentage: lastTile ? bottomPercentageOffset : 0,
      color: BREAST_COLOR,
      type: 'BREAST',
      duration: Math.floor(roundedDurationMilliseconds / (60 * 1000)),
      comment: measure.comment ?? '',
    });

    hourCount = new Date(hourCount.getTime() + 60 * 60 * 1000); // Increment hour
  });

  return acc;
};

const BOTTLE_ROUNDING_MS = 30 * 60 * 1000; // 30 minutes

export const computeBottleQuantity = (measure: ChildFeedingDTO) => {
  return measure.bottleMilliliter;
};

export const transformBottle = (
  acc: CalendarDayMap,
  measure: ChildFeedingDTO,
): CalendarDayMap => {
  const start = floorToNearestValue(new Date(measure.date), BOTTLE_ROUNDING_MS);

  const offsets =
    start.getMinutes() === 0
      ? { top: 0, bottom: 0.5 }
      : { top: 0.5, bottom: 0 };

  const dayKey = formatTZ(start, 'YYYY-MM-DD');
  const hourKey = start.getHours().toString();

  acc[dayKey] ??= {} as DayData;
  acc[dayKey][hourKey] ??= [];
  acc[dayKey][hourKey].push({
    color: BOTTLE_COLOR,
    topPercentage: offsets.top,
    bottomPercentage: offsets.bottom,
    type: 'BOTTLE',
    quantity: measure.bottleMilliliter,
    comment: measure.comment ?? '',
  });

  return acc;
};

const ROUNDING_MS = 5 * 60 * 1000; // 5 minutes

export const computeSleepDuration = (period: ChildSleepDTO) => {
  const start = dayjsTz(period.start);
  const end = dayjsTz(period.end || undefined);
  const endOfDay = start.endOf('day');
  const sleepDays = {};
  if (start.format('YYYY-MM-DD') !== end.format('YYYY-MM-DD')) {
    const duration = endOfDay.diff(start, 'minute');
    sleepDays[start.format('YYYY-MM-DD')] = duration;
    sleepDays[end.format('YYYY-MM-DD')] = end.diff(endOfDay, 'minute');
  } else {
    const duration = end.diff(start, 'minute');
    sleepDays[start.format('YYYY-MM-DD')] = duration;
  }
  return sleepDays;
};

export const transformSleep = (acc: CalendarDayMap, period: ChildSleepDTO) => {
  const roundedStart = roundToNearestValue(new Date(period.start), ROUNDING_MS);
  const roundedEnd = roundToNearestValue(
    new Date(period.end || undefined),
    ROUNDING_MS,
  );
  const start = dayjs(roundedStart);
  const end = dayjs(roundedEnd);

  const topPercentageOffset = parseFloat((start.minute() / 60).toFixed(2));
  const endMinute = end.minute() === 0 ? 60 : end.minute();
  const bottomPercentageOffset = parseFloat((1 - endMinute / 60).toFixed(2));

  const tilesToFill = getSpannedAcrossHours({
    start: roundedStart,
    end: roundedEnd,
  });
  let hourCount = start;
  Array.from({ length: tilesToFill }).forEach((_, i) => {
    const dayKey = formatTZ(hourCount, 'YYYY-MM-DD');
    const hourKey = hourCount.hour().toString();

    acc[dayKey] ??= {} as DayData;
    acc[dayKey][hourKey] ??= [];

    acc[dayKey][hourKey].push({
      topPercentage: i === 0 ? topPercentageOffset : 0,
      bottomPercentage: i === tilesToFill - 1 ? bottomPercentageOffset : 0,
      // @ts-ignore
      type: period.type,
      comment: period.comment ?? '',
    });

    hourCount = hourCount.add(1, 'h');
  });
  return acc;
};
