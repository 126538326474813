import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DiscussionState {
  targetEventId: string | null;
  highlightedEventIds: string[];
}
const initialState: DiscussionState = {
  targetEventId: null,
  highlightedEventIds: [],
};

const discussionSlice = createSlice({
  name: 'discussion',
  initialState,
  reducers: {
    setTargetEventId(
      state,
      action: PayloadAction<{
        targetEventId: string | null;
        highlightedEventIds?: string[];
      }>,
    ) {
      state.targetEventId = action.payload.targetEventId;
      if (action.payload.highlightedEventIds) {
        state.highlightedEventIds = action.payload.highlightedEventIds;
      }
    },
    setHighlightedEventIds(state, action: PayloadAction<string[]>) {
      state.highlightedEventIds = action.payload;
    },
  },
});

export const { setTargetEventId, setHighlightedEventIds } =
  discussionSlice.actions;

export default discussionSlice.reducer;
