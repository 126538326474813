import {
  Datagrid,
  TextField,
  TextInput,
  TopToolbar,
  BooleanInput,
} from 'react-admin';

import { AvatarField } from '@components/fields';
import { CreateButton } from '@components/generic/CreateButton';
import { List } from '@components/generic/List';

const UsersFilters = [
  <TextInput label="Prénom" source="firstName" alwaysOn variant="standard" />,
  <TextInput label="Nom" source="lastName" alwaysOn variant="standard" />,
  <TextInput source="email" alwaysOn variant="standard" />,
  <BooleanInput label="Actif" source="active" alwaysOn variant="standard" />,
];

const UsersToolbar = () => (
  <TopToolbar>
    <CreateButton redirect="/code-affiliations" />
  </TopToolbar>
);

export const UsersList = () => (
  <List
    filters={UsersFilters}
    filterDefaultValues={{ active: true }}
    perPage={10}
    actions={<UsersToolbar />}
    sx={{ padding: '0 1rem' }}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <AvatarField label="Avatar" />
      <TextField source="firstName" label="Prénom" />
      <TextField source="lastName" label="Nom" />
      <TextField source="email" label="Email" />
      <TextField source="phone" label="📱" />
    </Datagrid>
  </List>
);
