import { MouseEvent, useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { useDispatch } from 'react-redux';

import { useSessionsData } from '@hooks/useActiveSessions';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import {
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';

import {
  logStartedSession,
  logStoppedSession,
} from '../../../analytics/events';
import { i18nProvider } from '../../../App';
import { setDisplayReviveSummary } from '../../../store/reviveSummary';

const StartSessionButtonUI = ({
  onClick,
  isLoading,
  variant = 'outlined',
  text = i18nProvider.translate('attributions.start'),
}: {
  onClick: (event: MouseEvent<HTMLElement>) => void;
  isLoading: boolean;
  variant?: 'outlined' | 'contained';
  text?: string;
}) => {
  return (
    <Button
      variant={variant}
      size="small"
      color="primary"
      onClick={onClick}
      startIcon={
        isLoading ? (
          <CircularProgress size="small" />
        ) : (
          <PlayArrowIcon
            sx={{ color: variant === 'outlined' ? 'primary' : 'white' }}
          />
        )
      }
      disabled={isLoading}
    >
      <Typography
        component={'span'}
        display={{ xs: 'none', sm: 'block' }}
        color={variant === 'outlined' ? 'primary' : 'white'}
        sx={{ fontSize: '0.85rem', fontWeight: 500 }}
      >
        {text}
      </Typography>
    </Button>
  );
};

export const StartSessionButton = ({
  variant = 'outlined',
  noAttribution = false,
  onClick,
  from = 'AppBar',
}: {
  variant?: 'outlined' | 'contained';
  noAttribution?: boolean;
  onClick?: () => void;
  from?: 'AppBar' | 'ChatInput';
}) => {
  const notify = useNotify();
  const { active, isLoading, start, isLoadingSessions } = useSessionsData();
  const dispatch = useDispatch();

  if (active || isLoadingSessions) {
    return null;
  }

  return (
    <StartSessionButtonUI
      variant={variant}
      onClick={() => {
        onClick?.();
        dispatch(setDisplayReviveSummary(true));
        logStartedSession({ from });
        return start(
          { noAttribution },
          {
            onError: (e) => {
              notify('common.error.withArgs', {
                type: 'error',
                messageArgs: { error: e },
              });
            },
          },
        );
      }}
      text={
        noAttribution
          ? i18nProvider.translate('attributions.startNoAttrib')
          : undefined
      }
      isLoading={isLoading}
    />
  );
};

export const EndSessionButton = ({
  onStopSession,
  isLoading,
  stop,
}: {
  onStopSession: () => void;
  isLoading: boolean;
  stop: (a: any, b: any) => void;
}) => {
  const notify = useNotify();
  const translate = useTranslate();

  return (
    <Button
      variant="outlined"
      size="small"
      color="warning"
      onClick={() => {
        logStoppedSession();
        stop(
          {},
          {
            onSuccess: () => {
              onStopSession();
            },
            onError: (e) => {
              notify('common.error.withArgs', {
                type: 'error',
                messageArgs: { error: e },
              });
            },
          },
        );
      }}
      startIcon={
        isLoading ? <CircularProgress size="small" /> : <StopCircleIcon />
      }
      disabled={isLoading}
      sx={{ fontSize: '0.85rem', fontWeight: 500 }}
    >
      <Typography
        component={'span'}
        color="warning"
        display={{ xs: 'none', sm: 'block' }}
        sx={{ fontSize: '0.85rem', fontWeight: 500 }}
      >
        {translate('attributions.stop')}
      </Typography>
    </Button>
  );
};

export const StartSessionPunctualHelperButton = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  return (
    <>
      <StartSessionButtonUI
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          logStartedSession({ from: 'AppBar' });
        }}
        isLoading={false}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableAutoFocus={true}
        sx={{ [`& ul`]: { paddingTop: 0, paddingBottom: 0 } }}
      >
        <MenuItem sx={{ p: 1 }}>
          <StartSessionButton onClick={() => setAnchorEl(null)} />
        </MenuItem>
        <MenuItem sx={{ p: 1 }}>
          <StartSessionButton onClick={() => setAnchorEl(null)} noAttribution />
        </MenuItem>
      </Menu>
    </>
  );
};
