import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { LookupDTO } from '@boTypes/discussionEvent';
import { useQuery } from '@hooks/queryWrappers';
import { useDebouncedCallback } from '@hooks/useDebouncedCallback';
import { SelectChangeEvent } from '@mui/material';

import { ContentType } from './contentTextList';
import { libraryMode } from './layout';
import {
  logLibraryContentTypeSelected,
  logLibraryFilter,
  logLibraryModeSelect,
  logLibrarySearch,
} from '../../analytics/events';
import { LibraryContext } from '../../common/context';
import {
  useAgeLevelsList,
  useCategoriesList,
  useOneKindList,
  useMultiSearch,
} from '../../hooks/cms';
import { useSelector } from '../../store';
import { setReduxSearch } from '../../store/librarySearch';
import { useSearchKey } from '../../utils/search';

export const useSearchText = () => {
  const initialSearch = useSelector(
    (state) => state.librarySearch,
    () => true, // only run on first render
  );
  const [displayedText, setDisplayedText] = useState(initialSearch ?? '');
  const [searchedText, setSearchedText] = useState(initialSearch ?? '');
  const dispatch = useDispatch();

  const debouncedSetSearchText = useDebouncedCallback(setSearchedText, 300);
  const setSearchText = useCallback(
    (text: string) => {
      setDisplayedText(text);
      dispatch(setReduxSearch({ search: '' }));
      if (text) {
        debouncedSetSearchText(text);
      } else {
        debouncedSetSearchText.cancel();
        setSearchedText('');
      }
    },
    [debouncedSetSearchText, dispatch],
  );

  const resetSearch = useCallback(() => {
    debouncedSetSearchText.cancel();
    setDisplayedText('');
    setSearchedText('');
    dispatch(setReduxSearch({ search: '' }));
  }, [debouncedSetSearchText, dispatch]);

  return {
    displayedText,
    searchedText,
    setSearchText,
    resetSearch,
  };
};

/**
 * Returns the pregnancy filter state from the redux store. It is a 3 state value :
 *  - null if no filter is applied
 *  - true if the filter is applied on pregnancy content
 *  - false if the filter is applied on children content
 * @returns The pregnancy filter state.
 */
export const usePregnancyFilter = () => {
  const pregnancy = useSelector((state) => state.libraryFilters);
  return [
    pregnancy,
    pregnancy === null ? null : pregnancy === LibraryContext.PREGNANCY,
  ] as [LibraryContext | null, boolean | null];
};

export const useLibrary = (
  selectedContent: ContentType | null,
  mode: libraryMode,
) => {
  const [selectedAgeLevel, _setAgeLevel] = useState<number>();
  const [selectedCategory, _setCategory] = useState<number>();
  const [displayFilters, setDisplayFilters] = useState(false);

  const [_pregnancy, pregnancy] = usePregnancyFilter();

  const ageLevelRef = useRef(selectedAgeLevel);
  const categoryRef = useRef(selectedCategory);
  ageLevelRef.current = selectedAgeLevel;
  categoryRef.current = selectedCategory;
  const _pregnancyRef = useRef(_pregnancy);

  const { displayedText, searchedText, setSearchText, resetSearch } =
    useSearchText();

  const setAgeLevel = (ageLevel: number) => {
    logLibraryFilter({ ageLevel: ageLevel, category: selectedCategory });
    _setAgeLevel(ageLevel);
  };

  const setCategory = (category: number) => {
    logLibraryFilter({ ageLevel: selectedAgeLevel, category: category });
    _setCategory(category);
  };

  useEffect(() => {
    if (searchedText) {
      logLibrarySearch(
        searchedText,
        ageLevelRef.current,
        categoryRef.current,
        _pregnancyRef.current,
      );
    }
  }, [searchedText]);

  const searchKey = useSearchKey();
  const {
    data: items,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isPending: isOneKindListPending,
  } = useOneKindList(selectedContent, {
    ageLevel: selectedAgeLevel,
    category: selectedCategory,
    pregnancy,
    search: searchedText,
    searchKey,
    enabled: mode === libraryMode.EXPLORER,
  });

  const { data: multiSearch, isPending: isMultiSearchPending } = useMultiSearch(
    {
      search: searchedText,
      searchKey,
      ageLevel: selectedAgeLevel,
      category: selectedCategory,
      pregnancy,
      enabled: mode === libraryMode.SEARCH,
    },
  );

  // search agnostic data
  const { data: ageLevelsList, isLoading: isAgeLevelsLoading } =
    useAgeLevelsList();
  const { data: categoriesList, isLoading: isCategoriesLoading } =
    useCategoriesList(pregnancy);

  const isLoading =
    (mode === libraryMode.SEARCH && isMultiSearchPending) ||
    (mode === libraryMode.EXPLORER && isOneKindListPending) ||
    displayedText !== searchedText;

  const isLoadingFilters = isAgeLevelsLoading || isCategoriesLoading;

  return {
    displayFilters,
    setDisplayFilters,
    ageLevelsList,
    categoriesList,
    isLoading,
    isLoadingFilters,
    pregnancy,
    resetSearch,
    setSearchText,
    searchedText,
    displayedText,
    selectedAgeLevel,
    selectedCategory,
    setAgeLevel,
    setCategory,
    explorer: {
      items,
      hasNextPage,
      fetchNextPage,
      isFetchingNextPage,
    },
    searchResults: {
      ...multiSearch,
    },
  };
};

export const useSentContent = (discussionId: number, open?: boolean) => {
  const { data = [], refetch } = useQuery<LookupDTO[]>(
    ['lookups', discussionId],
    () => ({
      url: `/api/discussion_events/lookups/${discussionId}`,
      method: 'get',
    }),
    {
      enabled: Boolean(discussionId),
    },
  );

  // Used to refetch data when the modal is opened with the suggested content usecase
  useEffect(() => {
    open && refetch();
  }, [open, refetch]);

  return data;
};

export const useLibraryMode = (
  setContentType: React.Dispatch<React.SetStateAction<ContentType | null>>,
) => {
  const [mode, setMode] = useState<libraryMode>(libraryMode.SEARCH);
  const onModeChange = useCallback(
    (event: SelectChangeEvent) => {
      const _mode = event.target.value as libraryMode;
      logLibraryModeSelect(_mode);
      setMode(_mode);
      setContentType(_mode === libraryMode.SEARCH ? null : ContentType.MACRO);
    },
    [setMode, setContentType],
  );

  return { mode, onModeChange };
};

export const useContentType = () => {
  const [selectedContentType, setContentType] = useState<ContentType | null>();
  const onTypeChange = (event: SelectChangeEvent) => {
    const contentType = event.target.value as ContentType;
    logLibraryContentTypeSelected(contentType);
    setContentType(contentType);
  };

  return { selectedContentType, onTypeChange, setContentType };
};
