import { useCallback, useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

export function usePrompt(message: string, when = true) {
  const blocker = useBlocker(when);

  const confirm = useCallback(() => {
    if (!blocker || blocker.state !== 'blocked') {
      return null;
    }
    // eslint-disable-next-line no-alert
    const response = window.confirm(message);
    response ? blocker.proceed() : blocker.reset();
  }, [message, blocker]);

  useEffect(() => {
    confirm();
  }, [confirm]);
}
