import { useTranslate } from 'react-admin';

import { Typography } from '@mui/material';

import { MacroItem, WIDTH, HEIGHT } from './MacroItem';
import { Macro } from '../../types';
import { FullWidthList, useWrappedItemWithCopy } from '../fullWidthList';

export const MacroList = ({
  list = [],
  onCopy,
}: {
  list: Macro[];
  onCopy?: (val: string, macroId: number, suggestionId: number) => void;
}) => {
  const translate = useTranslate();

  const Item = useWrappedItemWithCopy(MacroItem, onCopy);

  if (!list || list.length === 0) {
    return null;
  }

  return (
    <>
      <Typography variant="subtitle2" color="primary">
        {translate('library.macros')}
      </Typography>
      <FullWidthList
        itemCount={list.length}
        itemSize={WIDTH + 16}
        height={HEIGHT + 20 + (onCopy ? 18 : 0)}
        layout="horizontal"
        itemData={list}
      >
        {Item}
      </FullWidthList>
    </>
  );
};
