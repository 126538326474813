import { AxiosError } from 'axios';
import { useEffect, useRef } from 'react';

import {
  Handover,
  HandoverCreate,
  HandoverRevive,
  HandoverReviveCreate,
  HandoverUpdate,
} from '@boTypes/handover';
import { SocketEvents, SocketHandlerGenerator } from '@boTypes/socket';
import { useGateway } from '@utils/gateway';

import { useDiscussionDetail } from './discussion';
import { historyQueryReviveUpdate } from './history';
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from './queryWrappers';
import { useSocketHandlers } from './useSocketHandlers';

export const useCreateHandover = () => {
  const discussionDetail = useDiscussionDetail();
  return useMutation<Handover, AxiosError, HandoverCreate>(
    ['createHandover'],
    (data: HandoverUpdate) => ({
      method: 'post',
      url: `/api/handovers`,
      data,
    }),
    {
      onSuccess(data) {
        discussionDetail.createHandover({
          handover: data,
          subjectId: data.subjectId,
        });
      },
    },
  );
};

export const useUpdateHandover = ({ handover }: { handover: Handover }) => {
  const discussionDetail = useDiscussionDetail();
  return useMutation<Handover, AxiosError, HandoverUpdate>(
    ['updateHandover', handover.id],
    (updates: HandoverUpdate) => ({
      method: 'put',
      url: `/api/handovers/${handover.id}`,
      data: updates,
    }),
    {
      onSuccess(data) {
        discussionDetail?.updateHandover(data);
      },
    },
  );
};

export const useDeleteHandover = ({ handover }: { handover: Handover }) => {
  const discussionDetail = useDiscussionDetail();
  return useMutation<Handover, AxiosError, Handover>(
    ['closeHandover', handover.id],
    () => ({
      method: 'delete',
      url: `/api/handovers/${handover.id}`,
    }),
    {
      onSuccess(data) {
        discussionDetail?.deleteHandover(data);
      },
    },
  );
};

export const useCreateHandoverRevive = () => {
  const queryClient = useQueryClient();
  const discussionDetail = useDiscussionDetail();
  return useMutation<HandoverRevive, AxiosError, HandoverReviveCreate>(
    ['createRevive'],
    (data) => ({
      method: 'POST',
      url: `/api/handoverRevives/`,
      data,
    }),
    {
      onSuccess(data) {
        historyQueryReviveUpdate(queryClient, data);
        discussionDetail?.updateHandoverRevive({ revive: data });
      },
    },
  );
};

export const useUpdateHandoverRevive = (reviveId: HandoverRevive['id']) => {
  const queryClient = useQueryClient();
  const discussionDetail = useDiscussionDetail();
  return useMutation<HandoverRevive, AxiosError, HandoverReviveCreate>(
    ['updateHandoverRevive', reviveId],
    (updates) => ({
      method: 'put',
      url: `/api/handoverRevives/${reviveId}`,
      data: updates,
    }),
    {
      onSuccess(data) {
        historyQueryReviveUpdate(queryClient, data);
        discussionDetail?.updateHandoverRevive({ revive: data });
      },
    },
  );
};

export const useDeleteHandoverRevive = () => {
  return useMutation<HandoverRevive, AxiosError, string>(
    ['deleteRevive'],
    (id) => ({
      method: 'DELETE',
      url: `/api/handoverRevives/${id}`,
    }),
    {},
  );
};

export const useCloseHandoverRevive = () => {
  const queryClient = useQueryClient();
  const discussionDetail = useDiscussionDetail();
  return useMutation<HandoverRevive, AxiosError, string>(
    ['closeRevive'],
    (id) => ({
      method: 'DELETE',
      url: `/api/handoverRevives/close/${id}`,
    }),
    {
      onSuccess(data) {
        historyQueryReviveUpdate(queryClient, data);
        discussionDetail?.updateHandoverRevive({ revive: data });
        queryClient.invalidateQueries({ queryKey: ['nextRevive'] });
      },
    },
  );
};

export const useGetMyHandoverRevive = <T = HandoverRevive[]>(
  {
    start,
    end,
  }: {
    start: string | Date;
    end: string | Date;
  },
  options: Parameters<typeof useQuery<HandoverRevive[], any, T>>[2],
) => {
  return useQuery<HandoverRevive[], any, T>(
    ['reviveList', start, end],
    () => ({
      method: 'GET',
      url: `/api/handoverRevives/`,
      params: {
        filter: JSON.stringify({
          start: new Date(start).toISOString(),
          end: new Date(end).toISOString(),
        }),
      },
    }),
    options,
  );
};

const socketMessageHandlerGenerator: SocketHandlerGenerator<[QueryClient]> = {
  [SocketEvents.CLOSE_HANDOVER_REVIVE]: (queryClient: QueryClient) => () =>
    queryClient.invalidateQueries({ queryKey: ['nextRevive'] }),
  [SocketEvents.DELETE_HANDOVER_REVIVE]: (queryClient: QueryClient) => () =>
    queryClient.invalidateQueries({ queryKey: ['nextRevive'] }),
};

export const useNextHandoverReviveDate = (type: 'midwife' | 'nurse') => {
  const socket = useGateway();
  const queryClient = useQueryClient();
  let firstConnectionFiltered = useRef(false);
  useSocketHandlers(socket, socketMessageHandlerGenerator, queryClient);

  useEffect(() => {
    if (socket && firstConnectionFiltered.current) {
      queryClient.invalidateQueries({ queryKey: ['nextRevive'] });
    } else if (socket) {
      firstConnectionFiltered.current = true;
    }
  }, [queryClient, socket]);

  return useQuery<{ reviveAt: Date }>(
    ['nextRevive', type],
    {
      method: 'GET',
      url: `/api/handoverRevives/next`,
      params: { type },
    },
    {
      refetchInterval: 5 * 60000,
    },
  );
};
