import { Datagrid, DateField, TextField } from 'react-admin';

import { List } from '../../components/generic/List';

export const ScenariosList = (props) => (
  <List {...props}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="name" label="Nom" />
      <DateField locales="fr-FR" source="created" label="Créé le" />
    </Datagrid>
  </List>
);
