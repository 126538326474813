import { memoize } from 'lodash';
import { useEffect, useState } from 'react';
import { useMemo } from 'react';

import { RawDiscussionEvent } from '@boTypes/discussionEvent';
import { ChatData } from '@boTypes/messages';
import { SubjectExplorer } from '@boTypes/subject';
import { useInfiniteQuery, useQuery } from '@hooks/queryWrappers';
import { useDebouncedCallback } from '@hooks/useDebouncedCallback';
import { formatMessages } from '@utils/messagesFormatting';

import { SearchExplorerInputs } from './subjectExplorer';

export const useSubjects = ({ filter }) => {
  const [debouncedFilters, setDebouncedFilters] =
    useState<SearchExplorerInputs>(filter);

  const { data, ...rest } = useInfiniteQuery<SubjectExplorer[]>({
    queryKey: ['subjects-explorer-list', debouncedFilters],
    queryFn: ({ pageParam = 0 }) => ({
      method: 'GET',
      url: '/api/subjects-explorer/search',
      params: { ...debouncedFilters, pageSize: 20, pageStart: pageParam },
    }),
    getNextPageParam: (lastPage, pages) => {
      const nextPage = lastPage?.data?.length;
      if (nextPage === 0) {
        return undefined;
      }
      return pages.length * 20;
    },
    initialPageParam: 0,
  });

  const debounceSetFilters = useDebouncedCallback(setDebouncedFilters, 300);

  useEffect(() => {
    debounceSetFilters(filter);
  }, [debounceSetFilters, filter]);

  return { data: data?.pages?.flatMap((page) => page.data) ?? [], ...rest };
};

export const useSubjectMessages = (subject?: SubjectExplorer) => {
  const discussionId = subject?.discussionId ?? subject?.discussion?.id;
  const selectFn = useMemo(
    () =>
      memoize((data: RawDiscussionEvent[]) => {
        return data ? formatMessages(data) : { messages: [], participants: {} };
      }),
    [],
  );

  return useQuery<RawDiscussionEvent[], any, ChatData>(
    ['subjectMessages', subject?.id],
    {
      method: 'GET',
      url: `/api/discussion_events/${discussionId}/subject`,
      params: {
        subjectId: subject?.id,
      },
    },
    {
      enabled: !!subject && !!discussionId,
      select: selectFn,
    },
  );
};

export const useSubject = (subjectId?: number) => {
  return useQuery<SubjectExplorer, any, SubjectExplorer>(
    ['subject', subjectId],
    {
      method: 'GET',
      url: `/api/subjects-explorer/${subjectId}`,
    },
    {
      enabled: !!subjectId,
    },
  );
};
